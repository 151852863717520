import { createSlice } from "@reduxjs/toolkit";

const commentCouponSlice = createSlice({
    name: 'modalcommentcoupon',
    initialState: {
        isModalOpen: false,
        props: null,
    },
    reducers: {
        openModal: (state, props) => {
            state.isModalOpen = true;
            state.props = props;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = commentCouponSlice.actions;
export default commentCouponSlice.reducer;