import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/editProfileSlice';
import { openModal as openOTPModal } from '../../store/slices/modalOTPSlice';
import { useForm, Controller } from 'react-hook-form';
import { getStates, getCities, editCheckUnique, editProfile, getProfileDetail } from '../../utils/api.service';
import { validateFile, TOAST_SUCCESS, TOAST_ERROR, storeAuthData } from '../../utils/common.service';
import { loginUser } from '../../store/slices/authUserSlice';
import moment from 'moment';
import { uploadBase64Image } from '../../utils/aws.service';
import { openModal as openImageCropper, closeModal as closeImageCropper } from '../../store/slices/imageCropperSlice';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../assets/css/custom.css';
import countriesData from '../../assets/country_dial_info.json';
import Cookies from 'js-cookie';
import { bodyDecryption } from "../../utils/common.service";

const EditProfileModal = () => {

    const isModalOpen = useSelector((state) => state.editProfile.isModalOpen);
    const dispatch = useDispatch();

    const handleCloseModalEditProfile = () => {
        dispatch(closeModal());
    };

    const handleOpenModalOTP = (payloadEditProfile,otpFoR) => {
        dispatch(openOTPModal({ params: payloadEditProfile, otpFor: otpFoR, action: 'edit' }));
    };

    // Image Cropper Starts
    const cropperRef = useRef(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const handleCrop = () => {
        if (cropperRef.current) {
            const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
            const croppedImageBase64 = croppedCanvas.toDataURL();
            
            setCroppedImage(croppedImageBase64);
            handleCloseImageCropper()

            setValue("profileImage", croppedImageBase64);
        }
    };
    
    const isCropperOpen = useSelector((state) => state.imageCropper.isModalOpen);

    const handleOpenImageCropper = () => {
        dispatch(openImageCropper());
    };

    const handleCloseImageCropper = () => {
        dispatch(closeImageCropper());
    };
    // Image Cropper Ends

    const [selectedFile, setSelectedFile] = useState(process.env.REACT_APP_PUBLIC_URL + "assets/images/default.png");
    const [fileExtension, setFileExtension] = useState(null);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countryCode, setCountryCode] = useState();
    const [mobileNumber, setMobileNumber] = useState();
    const [email, setEmail] = useState();

    const stateChangeHandler = (e) => {
        clearErrors("state");
        const selectedDataId = e.target.options[e.target.selectedIndex].getAttribute('data-id');
        if (e.target.value != undefined && e.target.value != '') {
            fetchCities(selectedDataId);
        }
    }

    const fetchCities = (state_id, city = null) => {

        let payload = {
            state_id: state_id
        };
        getCities(payload).then(function (response) {
            if (response.code === '1') {
                setCities(response.data)
                setTimeout(() => {
                    if (city !== undefined && city !== null) {
                        setValue("city", city);
                    } else {
                        setValue("city", "");
                    }
                }, 500);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }

    const handleFileInput = (e) => {
        let file = e.target.files[0];
        let imageSize = file.size / 1024

        if (!validateFile(file.name, 'image')) {
            setError("profileImage", {
                type: "manual",
                message: "Image must be jpg, jpeg, png type",
            })
        }
        else if (imageSize > 3072) {
            setError("profileImage", {
                type: "manual",
                message: "Please select a profile image smaller than 3MB",
            })
        }
        else {
            if (file) {
                
                let parts = file.name.split('.');
                let extension = parts[parts.length - 1];
                setFileExtension(extension);

                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64String = e.target.result;
                    setSelectedFile(base64String);
                };
                reader.readAsDataURL(file);
                clearErrors("profileImage");
                handleOpenImageCropper();
            }
        }
    }

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {

        let file_name = '';

        if (data.profileImage !== null) {
            file_name = Math.floor(Math.random() * 999999) + '_' + moment().format('X') + '.' + fileExtension;
            let filePath = process.env.REACT_APP_AWS_FOLDER_NAME + '/user/' + file_name;

            uploadBase64Image(data.profileImage, filePath)
        }

        let payloadEditProfile = {
            first_name: data.firstName,
            last_name: data.lastName,
            user_name: data.userName,
            country_code: data.countryCode,
            mobile_number: data.mobileNumber,
            gender: data.gender,
            email: data.email,
            date_of_birth: (data.date_of_birth) ? moment(data.date_of_birth).format('DD/MM/YYYY') : '',
            state: data.state,
            city: data.city,
            profile_image: file_name,
            height: data.height
        }

        if (mobileNumber !== data.mobileNumber || countryCode !== data.countryCode || email !== data.email) {
            let payloadCheckUnique = {
                country_code: data.countryCode,
                mobile_number: data.mobileNumber,
                email: data.email
            }

            editCheckUnique(payloadCheckUnique).then(function (response) {
                if (response.code === '1') {
                    var otpFoR = (mobileNumber !== data.mobileNumber || countryCode !== data.countryCode) ? 'mobile' : 'email'
                    handleCloseModalEditProfile()
                    handleOpenModalOTP(payloadEditProfile,otpFoR)

                    TOAST_SUCCESS(response.message);

                } else if (response.code !== '0') {
                    setTimeout(() => {
                        TOAST_ERROR(response.message);
                    }, 100);
                }else{
                    setTimeout(() => {
                        TOAST_ERROR(response.message);
                    }, 100);
                }
            }).catch(function (error) {
                
            });
        }
        else {

            editProfile(payloadEditProfile).then(function (response) {
                if (response.code === '1') {
                    let authgetUserData = Cookies.get('onestens-auth-user-data');
                    authgetUserData=JSON.parse(bodyDecryption(authgetUserData));
                    delete response.data.token;
                    response.data.token=authgetUserData.token;
                    const authUserData = storeAuthData(response.data)
                    dispatch(loginUser(authUserData));

                    handleCloseModalEditProfile();
                    TOAST_SUCCESS(response.message);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        }

    };

    const fetchProfileDetail = () => {
        if(states?.[0]){
            let payload = {};
            getProfileDetail(payload).then(function (response) {
                if (response.code === '1') {
    
                    setCroppedImage(response.data.profile_image);
                    setValue("firstName", response.data.first_name);
                    setValue("lastName", response.data.last_name);
                    setValue("email", response.data.email);
                    setEmail(response.data.email);
                    setValue("date_of_birth", (response.data.date_of_birth) ? moment(response.data.date_of_birth, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');
                    setValue("mobileNumber", response.data.mobile_number);
                    setMobileNumber(response.data.mobile_number);
                    setValue("countryCode", response.data.country_code);
                    setCountryCode(response.data.country_code);
                    setValue("gender", response.data.gender);
                    setValue("userName", response.data.user_name);
                    setValue("state", response.data.state);
                    setValue("height", response.data.height);
    
                    const filteredStates = states.filter(obj => obj.name === response.data.state);
                    const state_id = filteredStates[0] != undefined ? filteredStates[0].id : 0;
                    
                    fetchCities(state_id, response.data.city);
    
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        }
    }

    useEffect(() => {
        if (isModalOpen) {
            reset();
            let payload = {};
            getStates(payload).then(function (response) {
                if (response.code === '1') {
                    setStates(response.data);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        }
    }, [isModalOpen])

    useEffect(() => {
        if (isModalOpen) {            
            fetchProfileDetail()
        }
    }, [isModalOpen,states])

    const getMaxDate = () => {
        const today = new Date();
        const maxDate = new Date(today.getFullYear() - 0, today.getMonth(), today.getDate());

        const year = maxDate.getFullYear();
        const month = (maxDate.getMonth() + 1).toString().padStart(2, '0');
        const day = maxDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    return (
        <>
            <Modal show={isModalOpen}>
                <Modal.Body>
                    <button type="button" className="custom_btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModalEditProfile}>X</button>
                    
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-12 mb-3 mt-3">
                                <div className="slider_rutorial text-center">
                                    <h1>Edit Profile</h1>
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className="file_upload">
                                    <input type="file" accept="image/*" onChange={handleFileInput} />
                                    <Controller name="profileImage" control={control} defaultValue={null} render={({ field }) => (
                                        <></>
                                    )} />
                                    <img src={croppedImage} className="uploaded_img" style={{ borderRadius: '50%' }} />
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/edit.svg"} className="edit_img " />
                                </div>
                                <label className="error">{errors.profileImage?.message}</label>
                            </div>
                            <div className="col-md-6 col-sm-6 col-12 mb-3">
                                <div className="form-group fixed_icon w-100">
                                    <input type="text" className="form-control" placeholder="First Name" {...register("firstName", { required: "Please enter first name", pattern: { value: /^[a-zA-Z]*$/, message: "Please enter only letters (A-Z, a-z) with no special characters or numbers" }, maxLength: { value: 32, message: "First name must not exceed 32 characters" }, minLength: { value: 2, message: "First name must be atleast 2 characters long" } })} autoComplete="off" />
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/name.svg"} className="front_icon logo_1" />
                                </div>
                                <label className="error">{errors.firstName?.message}</label>
                            </div>
                            <div className="col-md-6 col-sm-6 col-12 mb-3">
                                <div className="form-group fixed_icon w-100">
                                    <input type="text" className="form-control" placeholder="Last Name" {...register("lastName", { required: "Please enter last name", pattern: { value: /^[a-zA-Z]*$/, message: "Please enter only letters (A-Z, a-z) with no special characters or numbers" }, maxLength: { value: 32, message: "Last name must not exceed 32 characters" }, minLength: { value: 2, message: "Last name must be atleast 2 characters long" } })} autoComplete="off" />
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/name.svg"} className="front_icon logo_1" />
                                </div>
                                <label className="error">{errors.lastName?.message}</label>
                            </div>
                            <div className="col-md-12 mb-3 ">
                                <div className="form-group fixed_icon w-100">
                                    <input type="text" className="form-control" placeholder="Username" {...register("userName", { required: "Please enter username", pattern: { value: /^[a-zA-Z0-9]*$/, message: "Please enter only letters & numbers with no special characters" }, maxLength: { value: 20, message: "Username must not exceed 20 characters" }, minLength: { value: 2, message: "Username must be atleast 2 characters long" } })} autoComplete="off" />
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/name.svg"} className="front_icon logo_1" />
                                </div>
                                <label className="error">{errors.userName?.message}</label>
                            </div>
                            <div className="col-md-12 mb-3 d-flex">
                                <div className="form-group fixed_icon w-100">
                                    <div className="mobile_div_1">
                                        <select className="form-select" {...register("countryCode", { required: "Please select country code" })} style={{ paddingRight: '30px' }}>
                                            <option value="">Country Code</option>
                                            {countriesData.map((item, index) => (
                                                <option key={index} value={item.dial_code}>{item.dial_code +  ' ' + item.name.substring(0,15)}</option>
                                            ))}
                                        </select>
                                        <input type="tel" className="form-control" placeholder="Mobile Number" {...register("mobileNumber", {
                                            required: "Please enter mobile number", pattern: { value: /^[0-9]*$/, message: "Please enter only numbers" },
                                            minLength: { value: 8, message: "Please enter minimum 8 & maximum 10 digits for mobile number" }, maxLength: { value: 10, message: "Please enter minimum 8 & maximum 10 digits for mobile number" }
                                        })} autoComplete="off" style={{ paddingLeft: '15px' }} />
                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/mobile.svg"} className="front_icon logo_1" />
                                    </div>
                                    <label className="error">{errors.countryCode!=undefined ? errors.countryCode?.message : errors.mobileNumber?.message}</label>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3 d-flex">
                                <div className="form-group fixed_icon w-100">
                                    <div className="mobile_div_1">
                                        <select className="form-select" {...register("gender", { required: "Please select gender" })} autoComplete="off">
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Others">Others</option>
                                        </select>

                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/gender.svg"} className="front_icon logo_1" />
                                    </div>
                                    <label className="error">{errors.gender?.message}</label>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3 d-flex">
                                <div className="form-group fixed_icon w-100">
                                    <div className="mobile_div_1">
                                        <select className="form-select" {...register("state", { required: "Please select state" })} onChange={stateChangeHandler} autoComplete="off">
                                            <option value="" data-id="0">Select State</option>
                                            {states.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.name} data-id={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>

                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/location.svg"} className="front_icon logo_1" />
                                    </div>
                                    <label className="error">{errors.state?.message}</label>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3 d-flex">
                                <div className="form-group fixed_icon w-100">
                                    <div className="mobile_div_1">
                                        <select className="form-select" {...register("city", { required: "Please select city" })} autoComplete="off">
                                            <option value="" data-id="0">Select City</option>
                                            {cities.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.city_name} data-id={item.id}>{item.city_name}</option>
                                                )
                                            })}
                                        </select>

                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/location.svg"} className="front_icon logo_1" />
                                    </div>
                                    <label className="error">{errors.city?.message}</label>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3 ">
                                <div className="form-group fixed_icon w-100">
                                    <input type="text" className="form-control" placeholder="Email Address" {...register("email", { required: "Please enter email address", pattern: { value: /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/, message: "Please enter a valid email address" } })} autoComplete="off" />
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/sms.svg"} className="front_icon logo_1" />
                                </div>
                                <label className="error">{errors.email?.message}</label>
                            </div>
                            <div className="col-md-12 mb-3 ">
                                <div className="form-group fixed_icon w-100">
                                    <DatePicker customInput={<input className="form-control"
                                     {...register("date_of_birth", { required: 'Please select date of birth' })} />} wrapperClassName="custom-datepicker-wrapper" dateFormat="dd/MM/yyyy" placeholderText="Select date of birth"
                                    selected={getValues('date_of_birth')} showYearDropdown scrollableYearDropdown yearDropdownItemNumber={100}
                                    onChange={(date) => setValue('date_of_birth', date, { shouldValidate: true })} maxDate={getMaxDate()} autoComplete="off" />
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/calendar.svg"} className="front_icon logo_1" />
                                </div>
                                <label className="error">{errors.date_of_birth?.message}</label>
                            </div>
                            <div className="col-md-12 mb-3 ">
                                <div className="form-group fixed_icon w-100">
                                    <input type="text" className="form-control" placeholder="Height (in cm)" {...register("height", {
                                        required: false, pattern: { value: /^[0-9].*$/, message: "Please enter only numbers" },
                                        // min: { value: 30, message: "Please enter height between 30 cm to 300 cm" }, max: { value: 300, message: "Please enter height between 30 cm to 300 cm" }
                                    })} autoComplete="off" />
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/ruler.svg"} className="front_icon logo_1" />
                                </div>
                                <label className="error">{errors.height?.message}</label>
                            </div>

                            <div className="col-md-12 mb-5">
                                <button type="submit" className="btn btn-theme mt-auto w-100">Save</button>
                            </div>

                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal show={isCropperOpen}>
                <Modal.Body>
                    <button type="button" className="custom_btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseImageCropper}>X</button>

                    <Cropper
                        ref={cropperRef}
                        src={selectedFile}
                        style={{ height: 400, width: '100%' }}
                        aspectRatio={1 / 1}
                        guides={true}
                        dragMode="none"
                    />
                    
                    <div className="col-md-12 mb-5 mt-5">
                        <button onClick={handleCrop} className="btn btn-theme mt-auto w-100">Crop Image</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditProfileModal;
