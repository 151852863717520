import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/commentCouponSlice';
import { postCouponComment, getCouponCommentList } from '../../utils/api.service';
import { useForm } from 'react-hook-form';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';
import ListingItem from "../../containers/Coupons/ListingItem";

const CommentCouponModal = () => {
    const [page, setPage] = useState(1);
    const [loadMore, setLoadMore] = useState(true);
    const isModalOpen = useSelector((state) => state.modalCommentCoupon.isModalOpen);
    const loginUserData = useSelector((state) => state.authUser.loginUserData);
    const [commentData, setCommentData] = useState([]);
    const couponId = useSelector((state) => state.modalCommentCoupon.props != null ? state.modalCommentCoupon.props.payload.couponId : 0);
    const commentSectionRef = useRef(null);


    const fetchGetCommentList = () => {
        if (page===1 || loadMore) {
            let payload = {
                coupon_id: couponId,
                page: page
            };
            getCouponCommentList(payload).then(function (response) {
                if (response.code === '1') {
                    const newData = response.data.result;
                    if(page===1){
                        setCommentData(newData);
                    }
                    else{
                        setCommentData((prevData) => [...prevData, ...newData]);
                    }
                  
                } else if (response.code === '2') {
                    setLoadMore(false);
                    // TOAST_ERROR(response.message);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }

    }


    useEffect(() => {
        const coupon_id = couponId
        if (coupon_id !== 0) {
            fetchGetCommentList()
        }
    }, [page, couponId,isModalOpen])


   
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = commentSectionRef.current;
        if (scrollTop + clientHeight >= scrollHeight) {
            setPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        const commentSection = commentSectionRef.current;
        if (commentSection) {
            commentSection.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (commentSection) {
                commentSection.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);



    const couponData = useSelector((state) => state.modalCommentCoupon.props != null ? state.modalCommentCoupon.props.payload.couponData : null);
    const dispatch = useDispatch();

    const handleCloseCommentModal = () => {
        setCommentData([]);
        setPage(1);
        setLoadMore(true);

        dispatch(closeModal());
    };
 
    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        reset,
        control,
        setError,
        clearErrors,
        formState: { errors, isValid },
    } = useForm();

    const onSubmit = async data => {


        if(isValid && data.comment !== ''){

            let payload = {
                comment: data.comment,
                coupon_id: couponData.id,
                user_type: (loginUserData.id === couponData.user_id) ? 'P' : 'U'
            }
            
            postCouponComment(payload).then(async function (response) {
                if (response.code === '1') {
    
                    if (response.data !== null) {
                        setPage(1)
                        setLoadMore(true);
                        fetchGetCommentList()
                        reset(); // Clear form fields
                        scrollToTop();
                    }
    
                    // handleCloseCommentModal();
                    // TOAST_SUCCESS(response.message);
                }
    
                else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        }

    };


    const scrollToTop = () => {
        const commentSection = commentSectionRef.current;
        if (commentSection) {
            commentSection.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional: Add smooth scrolling effect
            });
        }
    };

    useEffect(() => {
       
        if (isModalOpen) {
            reset();
        }
    }, [isModalOpen])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" onClick={handleCloseCommentModal}>X</button>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-12 mb-3 mt-3">
                            <div className="slider_rutorial text-center">
                                <h1>Comment</h1>
                            </div>
                        </div>
                        {commentData !== null && commentData.length > 0 ? (
                            <div className="col-md-12 col-sm-12 col-12 mb-3 comment-section" ref={commentSectionRef} onScroll={handleScroll}>
                                {/* {commentData.map((item, index) => (
                                    
                                ))} */}

                                {(commentData.length > 0) ? commentData.map((item, index) => (
                                    <ListingItem key={index} item={item} is_like={item.is_like} loginUserData={loginUserData} couponData={couponData}/>
                                )) : <div className="col-12 mt-10 text-center">
                                    <strong style={{ fontSize: '20px' }}>No Data Found</strong>
                                </div>}
                            </div>
                        ) : (<p className='text-center'>No Data Found!</p>)}

                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                            {/* <a href="#">
                                    <img src={process.env.REACT_APP_PUBLIC_URL+"assets/images/attch.svg"} />
                                </a> */}
                            <div className="chat_input">

                                
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("comment", { required: "Please enter comment", 
                                    // pattern: { value: /^[a-zA-Z0-9. \n\uD83C-\uDBFF\uDC00-\uDFFF]*$/, message: "Please enter only letters, numbers, emojis & spaces with no special characters" },
                                     maxLength: { value: 1000, message: "Comment must not exceed 1000 characters" } })} autoComplete="off" placeholder="Comment" />
                                </div>

                                <button type="submit" className="btn btn-theme "><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/mic.svg"} /></button>
                            </div>
                            <label className="error">{errors.comment?.message}</label>


                        </div>

                        {/* <div className="col-md-12 mb-2">
                            <button >Post</button>
                        </div> */}
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default CommentCouponModal;
