import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/forgotPasswordSlice';
import { openModal as openOTPModal } from '../../store/slices/modalOTPSlice';
import { useForm } from 'react-hook-form';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';
import { forgotPassword } from '../../utils/api.service';
import { openModal as openLoginModal } from '../../store/slices/modalLoginSlice';
import { Link } from 'react-router-dom';

const ForgotPasswordModal = () => {

    const isModalOpen = useSelector((state) => state.modalForgotPassword.isModalOpen);
    const otpFor = useSelector((state) => state.modalForgotPassword.props != null ? state.modalForgotPassword.props.payload.otpFor : null);
    const dispatch = useDispatch();

    const handleCloseModalForgotPassword = () => {
        dispatch(closeModal());
    };

    const handleOpenModalLogin = () => {
        dispatch(openLoginModal());
        handleCloseModalForgotPassword();
    };

    const handleOpenModalOTP = (payload) => {
        dispatch(openOTPModal({ params: payload, otpFor: otpFor, action: 'forgot' }));
    };

    const [showEmailDiv, setShowEmailDiv] = useState('email');
    const [showEmail, setShowEmail] = useState(true);
    const [showMobile, setShowMobile] = useState(false);

    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        reset,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {

        let payloadForgotPassword = {}

        if(showEmailDiv === 'email'){
            payloadForgotPassword.email = data.email;
        }else{
            payloadForgotPassword.country_code = data.countryCode;
            payloadForgotPassword.mobile_number = data.mobileNumber;
        }

        forgotPassword(payloadForgotPassword).then(function (response) {
            if (response.code === '1') {

                let payload = payloadForgotPassword

                handleCloseModalForgotPassword()
                handleOpenModalOTP(payload)

                TOAST_SUCCESS(response.message);

            } else if (response.code !== '0') {
                setTimeout(() => {
                    TOAST_ERROR(response.message);
                }, 100);
            }else{
                setTimeout(() => {
                    TOAST_ERROR(response.message);
                }, 100);
            }
        }).catch(function (error) {
            
        });
    };

    useEffect(() => {
        if (isModalOpen) {
            reset();
            setShowEmailDiv(otpFor==='email' ? 'email' : 'mobile');
            setShowEmail(otpFor==='email' ? true : false);
            setShowMobile(otpFor==='mobile' ? true : false);
        }
    }, [isModalOpen])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModalForgotPassword}>X</button>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="slider_rutorial text-center">
                                <a href="#" className="mb-3">
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/logo.png"} alt="" className="logo w-200-login logo_1" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4 text-center">
                            <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/forgot.svg"} alt="" />
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="slider_rutorial text-center">
                                <h1>Forgot Password</h1>
                                {showEmailDiv === 'email'
                                    ? <h6>Enter your email associated with your account, and we will send a verification code</h6>
                                    : <h6>Enter your mobile number associated with your account, and we will send a verification code</h6>
                                }
                            </div>
                        </div>

                        <div className={`col-md-12 mb-3 ${(showEmailDiv === 'email' ? '' : 'd-none')}`}>
                            <div className="form-group fixed_icon w-100">
                                {showEmail && <input type="text" className="form-control" {...register("email", { required: "Please enter email address", pattern: { value: /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/, message: "Please enter a valid email address" } })} autoComplete="off" placeholder="Email Address" />}
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/sms.svg"} className="front_icon logo_1" />
                            </div>
                        </div>
                        <label className={`error mb-3 ${(showEmailDiv === 'email' ? '' : 'd-none')}`}>{errors.email?.message}</label>

                        <div className={`col-md-12 mb-3 ${(showEmailDiv === 'mobile' ? '' : 'd-none')}`}>
                            <div className="form-group fixed_icon w-100">
                                <div className="mobile_div_1">
                                    {showMobile && <select className="form-select" {...register("countryCode", { required: "Please select country code" })}>
                                        <option value={'+91'}>+91</option>
                                    </select>}
                                    
                                    {showMobile && <input type="text" className="form-control" placeholder="Mobile No" {...register("mobileNumber", { required: "Please enter mobile number", pattern: { value: /^[0-9]*$/, message: "Please enter only numbers" }, 
                                    minLength: { value: 8, message: "Please enter minimum 8 & maximum 10 digits for mobile number" }, maxLength: { value: 10, message: "Please enter minimum 8 & maximum 10 digits for mobile number" } })} autoComplete="off" />}
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/mobile.svg"} className="front_icon logo_1" alt="" />
                                </div>
                            </div>
                        </div>
                        <label className={`error mb-3 ${(showEmailDiv === 'mobile' ? '' : 'd-none')}`}>{errors.mobileNumber?.message}</label>


                        <div className="col-md-12 mb-5">
                            <button type="submit" className="btn btn-theme mt-auto w-100">Send OTP</button>
                        </div>
                        <div className="col-md-12 text-center mt-5 mb-3">
                            <span className="text-theme font-16">Back To <Link onClick={handleOpenModalLogin} className="fw-bold">Login</Link></span>
                        </div>

                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ForgotPasswordModal;
