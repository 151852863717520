import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/modalOTPSlice';
import { signUp, verifyOTP, editProfile, sendOTP } from '../../utils/api.service';
import { TOAST_ERROR, TOAST_SUCCESS, storeAuthData } from '../../utils/common.service';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from '../../store/slices/authUserSlice';
import { openModal as openResetModal } from '../../store/slices/resetPasswordSlice';

const OTPModal = () => {

    const isModalOpen = useSelector((state) => state.modalOTP.isModalOpen);
    const params = useSelector((state) => state.modalOTP.props != null ? state.modalOTP.props.payload.params : null);
    const otpFor = useSelector((state) => state.modalOTP.props != null ? state.modalOTP.props.payload.otpFor : null);
    const action = useSelector((state) => state.modalOTP.props != null ? state.modalOTP.props.payload.action : null);
    const dispatch = useDispatch();

    const handleCloseModalOTP = () => {
        dispatch(closeModal());
    }; 

    const handleOpenResetModal = (payload) => {
        dispatch(openResetModal({ params: payload, otpFor: otpFor }));
    };

    const navigate = useNavigate();

    const [otp, setOtp] = useState(['', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
    const [isLinkDisabled, setLinkDisabled] = useState(true);
    const [timer, setTimer] = useState(60);

    const handleInputChange = (index, event) => {
        let value = event.target.value
        if (/^[0-9]$/.test(value) || value == '') {
            const newOtp = [...otp];
            newOtp[index] = value;

            if (index < inputRefs.length - 1 && value !== '') {
                inputRefs[index + 1].current.focus();
            }

            setOtp(newOtp);
        }
    };

    const handleBackspace = (index, event) => {
        if (index > 0 && event.key === 'Backspace' && otp[index] === '') {
            inputRefs[index - 1].current.focus();
        }
        if (index === inputRefs.length - 1 && otp[index] !== '' && event.key === 'Enter') {

            handleVerifyOTP();
        }
    };

    const handleVerifyOTP = () => {

        let finalOtp = otp.join('');
        if (finalOtp != '') {
            if (finalOtp.length == 4) {

                let payloadVerifyOTP = { otp: finalOtp };

                if (action !== null && otpFor === 'email') {
                    payloadVerifyOTP.email = params.email;
                }
                else if (action !== null && otpFor === 'mobile') {
                    payloadVerifyOTP.country_code = params.country_code;
                    payloadVerifyOTP.mobile_number = params.mobile_number;
                }
                else {
                    payloadVerifyOTP.country_code = params.country_code;
                    payloadVerifyOTP.mobile_number = params.mobile_number;
                }

                verifyOTP(payloadVerifyOTP).then(function (response) {
                    if (response.code === '1') {
   
                        setTimeout(() => {
                            TOAST_SUCCESS(response.message);
                        }, 100);

                        if (action !== null && action === 'edit') {
                            editProfile(params).then(function (response) {
                                if (response.code === '1') {

                                    const authUserData = storeAuthData(response.data)
                                    dispatch(loginUser(authUserData));

                                    handleCloseModalOTP();

                                    setTimeout(() => {
                                        TOAST_SUCCESS(response.message);
                                    }, 100);

                                    navigate('/dashboard')
                                } else if (response.code !== '0') {
                                    setTimeout(() => {
                                        TOAST_ERROR(response.message);
                                    }, 100);
                                }else{
                                    setTimeout(() => {
                                        TOAST_ERROR(response.message);
                                    }, 100);
                                }
                            }).catch(function (error) {
                                
                            });
                            
                        }
                        else if (action !== null && action === 'signup') {

                            signUp(params).then(function (response) {
                              
                                
                                if (response.code === '1') {

                                    const authUserData = storeAuthData(response.data)
                                    dispatch(loginUser(authUserData));

                                    handleCloseModalOTP();
                                    navigate('/dashboard');
                                    
                                    setTimeout(() => {
                                        TOAST_SUCCESS(response.message);
                                    }, 100);
                                } else if (response.code !== '0') {
                                    TOAST_ERROR(response.message);
                                }
                            }).catch(function (error) {
                                
                            });
                        }
                        else if (action !== null && action === 'forgot') {
                            handleCloseModalOTP();
                            handleOpenResetModal(params);
                        }


                    } else if (response.code !== '0') {
                        setTimeout(() => {
                            TOAST_ERROR(response.message);
                        }, 100);
                    }else{
                        setTimeout(() => {
                            TOAST_ERROR(response.message);
                        }, 100);                    }
                }).catch(function (error) {
                    
                });
            }
            else {
                if (inputRefs[0].current.value == '') {
                    inputRefs[0].current.focus();
                } else if (inputRefs[1].current.value == '') {
                    inputRefs[1].current.focus();
                } else if (inputRefs[2].current.value == '') {
                    inputRefs[2].current.focus();
                } else if (inputRefs[3].current.value == '') {
                    inputRefs[3].current.focus();
                } else {
                    inputRefs[0].current.focus();
                }
                TOAST_ERROR('Please enter complete OTP');
            }
        }
        else {
            inputRefs[0].current.focus();
            TOAST_ERROR('Please enter OTP');
        }

    };

    const handleResendOTP = () => {

        let payloadSendOTP = {
            country_code: params.country_code,
            mobile_number: params.mobile_number
        }

        sendOTP(payloadSendOTP).then(function (response) {
            if (response.code === '1') {
                setTimer(60);
                // setLinkDisabled(true);
                setTimeout(() => {
            TOAST_SUCCESS(response.message);
          }, 100);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });

    };

    useEffect(() => {
        if (isModalOpen) {
            if (timer > 0) {
                const countdown = setTimeout(() => {
                    setTimer(timer - 1);
                }, 1000);

                return () => clearTimeout(countdown);
            }
            if (timer === 0) {
                setLinkDisabled(false);
            }
        }
    }, [isModalOpen, timer])

    useEffect(() => {
        if (isModalOpen) {
            setOtp(['', '', '', '']);
            setTimer(60);
            inputRefs[0].current.focus();
        }
    }, [isModalOpen])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModalOTP}>X</button>

                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="slider_rutorial text-center">
                            <a className="mb-3 "><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/logo.png"} alt="" className="logo w-200-login logo_1" /></a>
                        </div>
                    </div>
                    <div className="col-md-12 mb-4 text-center">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/otp.svg"} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="slider_rutorial text-center">

                            <h1>OTP Verification</h1>
                            <h6 style={{ textTransform: 'none' }}>Enter the 4 digit OTP that has been sent to your provided
                                {(otpFor !== null && otpFor === 'email') ? ' email' : ' mobile number'}
                                {(otpFor !== null && otpFor === 'email') && <b> {(params?.email)}</b>}
                                {(otpFor !== null && otpFor === 'mobile') && <b> {(params?.country_code) + ' ' + (params?.mobile_number)}</b>}
                            </h6>

                        </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-12 mb-3">

                        <div className="form-group fixed_icon w-100 opt_text">
                            {otp.map((digit, index) => (
                                <input
                                    className="form-control text-center"
                                    key={index}
                                    type="tel"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleInputChange(index, e)}
                                    onKeyDown={(e) => handleBackspace(index, e)}
                                    ref={inputRefs[index]}
                                    placeholder=''
                                />
                            ))}
                        </div>

                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="slider_rutorial text-center">
                            <h6>Resend OTP in <b>{timer}</b> seconds</h6>
                        </div>
                    </div>

                    <div className="col-md-12 mb-5">
                        <button className="btn btn-theme mt-auto w-100" onClick={handleVerifyOTP}>Verify</button>
                    </div>
                    <div className="col-md-12 text-center mt-5 mb-3">
                        <span className="text-theme font-16">Didn’t receive OTP ?    <Link className="fw-bold" onClick={handleResendOTP} style={{ pointerEvents: isLinkDisabled ? 'none' : 'auto' }}>Resend OTP</Link></span>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );
};

export default OTPModal;
