import React, { useState } from 'react';
import { likeUnlikeComment } from '../../utils/api.service';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';
import { Link } from 'react-router-dom';

const ListingItem = ({ item }) => {
    const [isLiked, setIsLiked] = useState(item?.is_like ? true : false);

    const handleLikeUnlikeComment = (comment_id) => {

        let payload = {
            comment_id: comment_id,
            is_like: isLiked ? 0 : 1
        };
        likeUnlikeComment(payload).then(function (response) {

            if (response.code === '1') {
                TOAST_SUCCESS(response.message);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }

        }).catch(function (error) {
            
        });
    }

    const handleLike = (comment_id) => {
        setIsLiked(!isLiked);
        handleLikeUnlikeComment(comment_id);
    };
 
    return (
        <div className="col-md-12 col-sm-12 col-12 mb-3">
            <div className="wiining_secreen">
                <div className="winning_main">
                    <div className="winner_img">
                        <img src={item?.profile_image_url} alt='' />
                    </div>
                    <div className="win_detail">
                        <h5>{item?.user_name}</h5>
                        <p dangerouslySetInnerHTML={{ __html: item?.comment }}></p>
                        {item.image && <img src={item?.image} className="w-100_px" alt="" />}
                    </div>
                    <div className="heart">
                        {(item?.user_type === 'U') && <Link onClick={() => handleLike(item?.id)}>
                            {isLiked ? <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/heart-red.svg"} alt="" className="" /> : <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/heart.svg"} alt="" className="logo_1" />}
                        </Link>}
                    </div>
                </div>
                {(item?.replies?.length > 0) && <div className="inner_comment">
                    <ul>
                        {item?.replies.map((obj, index) => (
                            <li key={index}>
                                <div className="winning_main">
                                    <div className="winner_img">
                                        <img src={obj?.profile_image_url} alt='' />
                                    </div>
                                    <div className="win_detail">
                                        <h5>{obj?.user_name}</h5>
                                        <p dangerouslySetInnerHTML={{ __html: obj?.comment }}></p>
                                        {obj.image && <img src={obj?.image} className="w-100_px" alt="" />}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>}

            </div>
        </div>
    );
};

export default ListingItem;