import { useContext, createContext, useMemo } from "react";
import Cookies from "js-cookie";
import { bodyDecryption } from "../utils/common.service";
import { useSelector } from "react-redux";

const AuthContext = createContext();

const AuthProvider = (props) => {

  const isLoggedin = useSelector((state) => state.authUser.isLoggedin);

  const encUserData = Cookies.get('onestens-auth-user-data');
  const authContextValue = useMemo(() => { 
    if(encUserData!==undefined && encUserData!==null){
      return JSON.parse(bodyDecryption(encUserData));
    }else{
      return null;
    }
  }, [encUserData, isLoggedin]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};