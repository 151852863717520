import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal as openClaimModal } from "../../store/slices/claimSlice";
import { openModal } from "../../store/slices/commentSlice";
import Winner from "../Winner/Winner";
import { TOAST_SUCCESS ,TOAST_INFO } from "../../utils/common.service";

const ListingItem = ({ item }) => {
  
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenClaimModal = (e, gift_type, campaign_id, gift_id) => {
    
    
    

    dispatch(
      openClaimModal({
        gift_type: gift_type,
        campaign_id: campaign_id,
        gift_id: gift_id,
        isWinningPageRedirect:true,
        handleIsClaimedLabel: handleIsClaimedLabel,
      })
    );
  };

  const handleOpenCommentModal = () => {
    dispatch(
      openModal({
        campaignId: item.campaign_id,
        GiftType: item.gift_type,
        GiftId: item.gift_id,
        addComment: handleAddNewComment,
      })
    );
  };

  const [isClaimed, setIsClaimed] = useState(item?.is_claim);

  const handleIsClaimedLabel = () => {
    setIsClaimed(1);
  };

  const handleAddNewComment = (comment) => {
    setData((prevData) => [comment, ...prevData]);
  };

  const handleNavigate = (e) => {
    TOAST_INFO('You need to post a comment to claim !');
    const isWinningPageRedirect = true
    setTimeout(() => {
    navigate("/winner", { state: { isWinningPageRedirect :isWinningPageRedirect } }); 
    localStorage.setItem('isWinningPageRedirect',isWinningPageRedirect)
    }, 2000);
  };

  
  return (
    <div className="col-md-6 col-sm-12 col-12 mb-3">
      <div className="winning_main">
        <div className="wini_img">
          <img src="assets/images/icon/winning-1.svg" />
        </div>
        <div className="win_detail">
          <h5>{item.name}</h5>
          <p>{item.description}</p>

          <div className="win_detail-btn-div">
            <label>
              {item.amount && item.amount > 0 ? "$ " + item.amount : ""}
            </label>

            {/* {isClaimed === 0 && <Link className="win_detail-rank-btn" onClick={() => handleOpenClaimModal(item.gift_type, item.campaign_id, item.gift_id)}>Claim</Link>} */}
            {/* {isClaimed === 0 && (
              <Link
                className="win_detail-rank-btn"
                // onClick={() => {
                //   item.is_post_comment === 1
                //     ? handleOpenClaimModal(
                //         item.gift_type,
                //         item.campaign_id,
                //         item.gift_id
                //       )
                //     : // handleOpenCommentModal(
                //       //     item.gift_type,
                //       //     item.campaign_id,
                //       //     item.gift_id
                //       //   );
                //       handleNavigate();
                // }}
                onClick={
                    item.is_post_comment === 1 ?  handleOpenClaimModal(
                                item.gift_type,
                                item.campaign_id,
                                item.gift_id
                              ):
                              handleNavigate()
                }
              >
                claim
              </Link>
            )} */}

            {/* {isClaimed === 1 && (
              <Link className="win_detail-rank-btn">Claimed</Link>
            )}
            {isClaimed === 2 && (
              <Link className="win_detail-rank-btn">Received</Link>
            )}
            {isClaimed === 3 && (
              <Link className="win_detail-rank-btn" >Expired</Link>
            )} */}

            {isClaimed === 0 ? (
              <button
                type="button"
                className="win_detail-rank-btn"
                onClick={(e) => {
                  item.is_post_comment === 1
                    ? handleOpenClaimModal(
                        e,
                        item.gift_type,
                        item.campaign_id,
                        item.gift_id
                      )
                    : handleNavigate(e);
                }}
              >
                Claim
              </button>
            ) : isClaimed === 1 ? (
              <button type="button" className="win_detail-rank-btn">
                Claimed
              </button>
            ) : isClaimed === 2 ? (
              <button type="button" className="win_detail-rank-btn">
                Received
              </button>
            ) : isClaimed === 3 ? (
              <button type="button" className="win_detail-rank-btn">
                Expired
              </button>
            ) : (
              <button type="button" className="win_detail-rank-btn"></button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingItem;
