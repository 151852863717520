import React, { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  getWinList,
  couponCategoryList,
  couponList,
  couponPoints,
  SubCategoryListdeatils,
  SubCategoryList,
  BrandList,
  CategoryList,
} from "../../utils/api.service";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";
import Layout from "../../layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useActiveTab } from "../../components/common/ActiveTabContext";
import { useDispatch, useSelector } from "react-redux";
import "./Win.css";
import moment from "moment";
import Category from "./Category";
import SubCategory from "./SubCategory";
import Brand from "./Brand";
import { useForm } from "react-hook-form";
import {
  setIsHeaderSearchShow,
  setIsSearchShow,
} from "../../store/slices/couponSearchSlice";

const Win = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  
  const [loadMore, setLoadMore] = useState(true);
  const [category, setCategory] = useState([]);

  const [Subcategory, setSubCategory] = useState([]);
  const [BrandListt, setBrandListt] = useState([]);

  const [SubcategoryList, setSubCategoryList] = useState([]);

  const [pagecoupon, setPageCoupon] = useState(1);
  const [selectedcategory, setSelectedCategory] = useState(0);
  

  const [selectedSubcategory, setSelectedSubCategory] = useState();
  const [selectedSubcategoryData, setSelectedSubCategoryData] = useState(false);

  const [selectedBrand, setSelectedBrand] = useState();

  const [couponData, setcouponData] = useState([]);
  const [loadMoreCoupon, setLoadMoreCoupon] = useState(true);
  const [search, setSearch] = useState("");
  const { activeTab, setActiveTab } = useActiveTab();
  const [points, setPoints] = useState(0);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [SubCategoryshowModal, SubcategorysetShowModal] = useState(false);
  const [BrandShow, setBrandShow] = useState(false);

  const dispatch = useDispatch();

  const handleShow = () => setShowModal(true);

  const handleSubCategoryShow = () => SubcategorysetShowModal(true);

  const handlebrandShow = () => setBrandShow(true);

  const handleTabChange = (tab) => {
    dispatch(setIsHeaderSearchShow(true));
    setActiveTab(tab);

    setSelectedBrand();
    setSelectedCategory();
    setSelectedSubCategory();
    setSelectedSubCategoryData(false);
  };

  const fetchGetWinList = () => {
    // if (loadMore) {
    let payload = {
      page: page,
      search_text: search,
    };

    if (selectedSubcategory) {
      payload.sub_category_id = selectedSubcategory;
    } else if (selectedSubcategoryData === true) {
      payload.category_id = selectedcategory;
      payload.sub_category_id = "";
    } else if (selectedBrand) {
      payload.brand_id = selectedBrand;
    } else if (selectedcategory) {
      
      payload.category_id = selectedcategory;
      payload.sub_category_id = "";
    }

    getWinList(payload)
      .then(function (response) {
        
        if (response.code === "1") {
          const newData = response.data.result;

          setData(newData);

          
        } else if (response.code === "2") {
          if (page === 1) {
            setData([]);
          }
        } else if (response.code !== "0") {
          setLoadMore(false);
        }
      })
      .catch(function (error) {
        
      });
    // }
  };

  const fetchCouponCategoryList = () => {
    let payload = {};
    CategoryList({ category_id: selectedcategory })
      .then(function (response) {
        if (response.code === "1") {
          setCategory(response.data);
        } else if (response.code !== "0") {
          // TOAST_ERROR(response.message);
        }
      })
      .catch(function (error) {
        
      });
  };

  const fetchCouponList = () => {
    if (loadMoreCoupon) {
      let payload = {
        page: pagecoupon,
        search_text: search,
      };

      if (selectedSubcategory) {
        payload.sub_category_id = selectedSubcategory;
      } else if (selectedSubcategoryData === true) {
        payload.category_id = selectedcategory;
        payload.sub_category_id = "";
      } else if (selectedBrand) {
        payload.brand_id = selectedBrand;
      } else if (selectedcategory) {
        payload.category_id = selectedcategory;
        payload.sub_category_id = "";
      }

      couponList(payload)
        .then(function (response) {
          if (response.code === "1") {
            const newData = response.data.result;
            if (pagecoupon === 1) {
              setcouponData(newData);
            } else {
              setcouponData((prevData) => [...prevData, ...newData]);
            }
            setLoadMoreCoupon(newData.length > 0);
          } else if (response.code === "2") {
            if (pagecoupon === 1) {
              setcouponData([]);
            } else {
              setLoadMoreCoupon(false);
            }
            // TOAST_ERROR(response.message);
          } else if (response.code !== "0") {
            // TOAST_ERROR(response.message);
          }
        })
        .catch(function (error) {
          
        });
    }
  };

  const getCouponPoints = () => {
    let payload = {};
    // let payload = {
    //   category_id:selectedcategory
    // };

    couponPoints(payload)
      .then(function (response) {
        if (response?.code === "1") {
          setPoints(response?.data.total_points);
        } else if (response?.code !== "0") {
          TOAST_ERROR(response.message);
        }
      })
      .catch(function (error) {
        
      });
  };

  useEffect(() => {
    fetchGetWinList();
  }, [
    page,
    activeTab,
    selectedBrand,
    selectedcategory,
    selectedSubcategory,
    selectedSubcategoryData,
    search,
  ]);

  useEffect(() => {
    fetchCouponCategoryList();
    getCouponPoints();
  }, []);

  useEffect(() => {
    fetchCouponList();
  }, [
    pagecoupon,
    activeTab,
    selectedBrand,
    selectedcategory,
    selectedSubcategory,
    selectedSubcategoryData,
    search,
  ]);

  const handleScroll = () => {
    if (
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const handleCouponScroll = () => {
    if (
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight
    ) {
      setPageCoupon((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleCouponScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleCouponScroll);
    };
  }, []);

  const changeSelectedBrand = (event, brand_id, categoryId) => {
    event.preventDefault();

    setSelectedBrand(brand_id);
    setSelectedCategory(categoryId);

    setSelectedSubCategoryData(false);
    const listItems = document.querySelectorAll(".tags_selection li");
    listItems.forEach((item) => item.classList.remove("active"));

    event.currentTarget.parentNode.classList.add("active");

    CategoryList({ category_id: categoryId })
      .then(function (response) {
        setSelectedSubCategory();
        if (response.code === "1") {
          setCategory(response.data);
        } else if (response.code !== "0") {
          // TOAST_ERROR(response.message);
        }
      })
      .catch(function (error) {
        
      });
    setPage(1);
    setPageCoupon(1);
    setLoadMoreCoupon(true);
  };

  const changeSelectedCategory = (event, category_id) => {
    
    event.preventDefault();

    const listItems = document.querySelectorAll(".tags_selection li");
    // listItems.forEach((item) => item.classList.remove("active"));

    event.currentTarget.parentNode.classList.add("active");

    setSelectedSubCategoryData(true);
    setSelectedSubCategory();

    SubCategoryList({ category_id: category_id }).then((response) => {
      if (response.code === "1") {
        setSubCategory(response.data);
      }
    });
    setPage(1);
    setPageCoupon(1);
    setLoadMoreCoupon(true);
  };

  // const changeSelectedSubCategory = (event, sub_category_id) => {
  //   event.preventDefault();

  //   const listItems = document.querySelectorAll(".tags_selection li");

  //   const parentUl = event.currentTarget.closest(".tags_selection");
  //   const listItemss = parentUl.querySelectorAll("li");

  //   listItemss.forEach((item) => item.classList.remove("active"));

  //   event.currentTarget.parentNode.classList.add("active");
  //   setSelectedSubCategory(sub_category_id);
  //   setPage(1)
  //   setPageCoupon(1);
  //   setLoadMoreCoupon(true);
  // };

  const changeSelectedSubCategory = (event, sub_category_id) => {
    event.preventDefault();

    const parentUl = event.currentTarget.closest(
      ".tags_selection, .tags_selection1"
    );

    const listItems = parentUl.querySelectorAll("li");

    listItems.forEach((item) => item.classList.remove("active"));

    event.currentTarget.parentNode.classList.add("active");

    setSelectedSubCategory(sub_category_id);
    setPage(1);
    setPageCoupon(1);
    setLoadMoreCoupon(true);
  };

  const changeSearchValue = (search) => {
    setSearch(search);
    setPageCoupon(1);
    setLoadMoreCoupon(true);
  };

  const handleChangeEvent = useCallback((value) => {
    setCategory([]);
    setSubCategory([]);

    BrandList({ search: value }).then((response) => {
      setSelectedBrand();
      setSelectedCategory();
      setSelectedSubCategoryData(false);
      if (
        response.code === "1" &&
        response?.data &&
        response?.data.length > 0
      ) {
        setBrandListt(response.data);
      } else {
        setBrandListt([]);
      }
    });
  }, []);

  const handleChangeCategoryEvent = (value) => {
    CategoryList({ category_id: selectedcategory, search: value }).then(
      function (response) {
        setSelectedSubCategoryData(false);
        setSelectedSubCategory();

        if (response.code === "1") {
          setCategory(response.data);
        } else {
          setCategory([]);
        }
      }
    );
  };

  const handleChangeSubCategoryEvent = (value) => {
    SubCategoryList({ category_id: selectedcategory, search: value }).then(
      (response) => {
        if (response.code === "1") {
          setSubCategory(response.data);
        } else {
          setSubCategory([]);
        }
      }
    );
  };

  const handleRedirect = (item) => {
    

    if (points > 0 && item.user_id !== loginUserData.id) {
      
      const url = `/coupon-details/${item.id}`;
      navigate(url);
    } else if (item.user_id === loginUserData.id) {
      dispatch(setIsHeaderSearchShow(false));
      const url = `/my-coupon-details/${item.id}`;
      navigate(url);
    } else {
      TOAST_ERROR("You need Counts to access coupons");
    }
  };

  useEffect(() => {
    return () => {
      setActiveTab("");
    };
  }, []);

  useEffect(() => {
    // if (BrandListt != null || BrandListt != undefined) {
    BrandList({}).then((response) => {
      
      if (response.code === "1") {
        setBrandListt(response.data);
      }
    });
    // }
  }, []);

  const loginUserData = useSelector((state) => state.authUser.loginUserData);
  const searchToggle = useSelector(
    (state) => state.couponsearchtoggle.isSearchShow
  );
 
  const darkMode = useSelector((state) => state.theme.themeValue);

  

  return (
    <Layout>
      <HelmetProvider>
        <Helmet>
          <title>{`${process.env.REACT_APP_APP_NAME} | Win`}</title>
          <body className="d-flex flex-column main-bg" />
          <script
            src={`${process.env.REACT_APP_PUBLIC_URL}assets/js/sticky.js`}
          ></script>
        </Helmet>
      </HelmetProvider>

      <section className="winnig_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul
                className="nav nav-pills mb-3 nav-underline nav-justified"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    onClick={() => handleTabChange("gifts")}
                    aria-selected="true"
                  >
                    Kudos
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    onClick={() => handleTabChange("coupons")}
                    aria-selected="false"
                  >
                    Coupons
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-12 text-center mb-5">
                      <b>
                        "Disclaimer : Kudos are not for everyone, So get one REP
                        a day."
                      </b>
                    </div>

                    <div className="col-12 ">
                      <ul
                        className={
                          darkMode === "theme-dark"
                            ? "tags_selection1"
                            : "tags_selection"
                        }
                      >
                        <li className="position-relative">
                          <input
                            type="search"
                            // className="serch-input "
                            className={
                              darkMode === "theme-dark"
                                ? "serch-input1"
                                : "serch-input"
                            }
                            placeholder="Brands"
                            onChange={(e) => {
                              handleChangeEvent(e.target.value);
                            }}
                          />
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/brand_seach.svg`}
                            alt="search"
                            className="image_icon_search"
                            style={{ color: "#FB9515" }}
                          />
                        </li>

                        {BrandListt.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        ).map((item, index) => (
                          <li
                            key={item}
                            className={
                              selectedBrand === item.id ? "active" : ""
                            }
                          >
                            <Link
                              to="#"
                              onClick={(e) =>
                                changeSelectedBrand(
                                  e,
                                  item.id,
                                  item.category_id
                                )
                              }
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {selectedBrand && (
                      <div className="col-12 ">
                        <ul
                          className={
                            darkMode === "theme-dark"
                              ? "tags_selection1"
                              : "tags_selection"
                          }
                        >
                          <li className="position-relative">
                            <input
                              type="search"
                              className={
                                darkMode === "theme-dark"
                                  ? "serch-input1"
                                  : "serch-input"
                              }
                              placeholder="Categories"
                              onChange={(e) => {
                                handleChangeCategoryEvent(e.target.value);
                              }}
                            />
                            <img
                              src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/brand_seach.svg`}
                              alt="search"
                              className="image_icon_search"
                              style={{ color: "#FB9515" }}
                            />
                          </li>

                          {category.length > 0 &&
                            category
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((item, index) => (
                                <li key={index}>
                                  <Link
                                    to="#"
                                    onClick={(e) =>
                                      changeSelectedCategory(e, item.id)
                                    }
                                  >
                                    {item.name}
                                  </Link>
                                </li>
                              ))}
                        </ul>
                      </div>
                    )}

                    {selectedSubcategoryData && (
                      <div className="col-12">
                        <ul
                          className={
                            darkMode === "theme-dark"
                              ? "tags_selection1"
                              : "tags_selection"
                          }
                        >
                          <li className="position-relative">
                            <input
                              type="search"
                              className={
                                darkMode === "theme-dark"
                                  ? "serch-input1"
                                  : "serch-input"
                              }
                              placeholder="Sub Categories"
                              onChange={(e) => {
                                handleChangeSubCategoryEvent(e.target.value);
                              }}
                            />
                            <img
                              src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/brand_seach.svg`}
                              alt="search"
                              className="image_icon_search"
                              style={{ color: "#FB9515" }}
                            />
                          </li>

                          {Subcategory.sort((a, b) =>
                            a.name.localeCompare(b.name)
                          ).map((item, index) => (
                            <li key={index}>
                              <Link
                                to="#"
                                onClick={(e) =>
                                  changeSelectedSubCategory(e, item.id)
                                }
                              >
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {data && data.length > 0 ? (
                      data.map((item, index) => (
                        <div
                          key={index}
                          className="col-md-6 col-sm-12 col-12 mb-3 mt-3"
                        >
                          <div className="winning_main flex-column">
                            <div className="win_main">
                              <div className="wini_img">
                                <img src={item.image} alt="" />
                              </div>
                              <div className="win_detail">
                                <div className="win_detail-btn-div">
                                  <h5>{item.tagline} </h5>
                                  <Link to="#" className="win_detail-rank-btn">
                                    &#8377; {item.rank}
                                  </Link>
                                </div>
                                {/* <label>Brand Name:</label>
                                <p>{item.brand_name}</p>
                           
                                <label>Category:</label>
                                <p>{item.category_name}</p>
                                <label>Sub Category:</label>
                                <p>{item.sub_category_name}</p>
                                <label>Product Name:</label>
                                
                                <p>{item.prize_details}</p> */}

                                <div>
                                  <label
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Brand:
                                  </label>
                                  <p
                                    style={{
                                      display: "inline-block",
                                      margin: 0,
                                    }}
                                  >
                                    {item.brand_name}
                                  </p>
                                </div>
                                <div>
                                  <label
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Category:
                                  </label>
                                  <p
                                    style={{
                                      display: "inline-block",
                                      margin: 0,
                                    }}
                                  >
                                    {item.category_name}
                                  </p>
                                </div>
                                <div>
                                  <label
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Sub Category:
                                  </label>
                                  <p
                                    style={{
                                      display: "inline-block",
                                      margin: 0,
                                    }}
                                  >
                                    {item.sub_category_name}
                                  </p>
                                </div>
                                <div>
                                  <label
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Product Name:
                                  </label>
                                  <p
                                    style={{
                                      display: "inline-block",
                                      margin: 0,
                                    }}
                                  >
                                    {item.prize_details}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w_detail">
                              <p>{item.description}</p>
                              <div className="w_detail_date">
                                <div className="w_detail_date_left">
                                  <span className="date">
                                    {moment(
                                      item.start_date,
                                      "DD/MM/YYYY"
                                    ).format("DD MMM YYYY")}
                                  </span>
                                  <span className="start_date">
                                    Start Date{" "}
                                  </span>
                                </div>
                                <div className="w_detail_date_right">
                                  <span className="date">
                                    {moment(item.end_date, "DD/MM/YYYY").format(
                                      "DD MMM YYYY"
                                    )}
                                  </span>
                                  <span className="start_date">End Date </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-12 mt-10 text-center">
                        <strong style={{ fontSize: "20px" }}>
                          No Data Found
                        </strong>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane fade "
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex="0"
                >
                  <div className="row ">
                    <div className="col-12 add_stick ">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 ms-auto">
                          {searchToggle && (
                            <div className="search_top me-2">
                              <input
                                type="text"
                                onChange={(e) =>
                                  changeSearchValue(e.target.value)
                                }
                                placeholder="Search Keywords"
                              />
                              <button>
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/u_search.svg`}
                                  alt="search"
                                  style={{ filter: "var(--filter)" }}
                                />
                              </button>
                            </div>
                          )}
                        </div>

                        {/* <div className="col-12 me-2">
                          <button
                            className="btn btn-primary me-4"
                            onClick={handleShow}
                          >
                            Add Category
                          </button>

                          <Category
                            show={showModal}
                            handleClose={handleClose}
                          />

                          <button
                            className="btn btn-secondary me-4"
                            onClick={handleSubCategoryShow}
                          >
                            Add SubCategory
                          </button>

                          <SubCategory
                            show={SubCategoryshowModal}
                            handleClose={handleSubCategoryCancel}
                          />

                          <button
                            className="btn btn-dark"
                            onClick={handlebrandShow}
                          >
                            Add Brand
                          </button>

                          <Brand
                            show={BrandShow}
                            handleClose={handlebrandCancel}
                          />
                        </div> */}
                        <div className="row">
                          <div className="col-12 mb-3">
                            <button
                              className={`btn btn-lg me-2 mb-2 mb-md-0 ${
                                darkMode === "theme-dark"
                                  ? "btn-dark text-white"
                                  : "btn-warning"
                              }`}
                              onClick={handleShow}
                            >
                              Add Category
                            </button>

                            <button
                              // className="btn btn-secondary btn-lg me-2 mb-2 mb-md-0"
                              className={`btn btn-secondary btn-lg me-2 mb-2 mb-md-0 ${
                                darkMode === "theme-dark"
                                  ? "btn-dark text-white"
                                  : "btn-secondary"
                              }`}
                              onClick={handleSubCategoryShow}
                            >
                              Add SubCategory
                            </button>
                            <button
                              // className="btn btn-dark btn-lg me-2 mb-2 mb-md-0"
                              className={`btn btn-dark btn-lg me-2 mb-2 mb-md-0 ${
                                darkMode === "theme-dark"
                                  ? "btn-dark text-white"
                                  : "btn-dark"
                              }`}
                              onClick={handlebrandShow}
                            >
                              Add Brand
                            </button>
                          </div>

                          {/* Modal components */}
                          <Category
                            show={showModal}
                            setShowModal={setShowModal}
                          />
                          <SubCategory
                            show={SubCategoryshowModal}
                            SubcategorysetShowModal={SubcategorysetShowModal}
                          />
                          <Brand show={BrandShow} setBrandShow={setBrandShow} />
                        </div>

                        <div className="col-12">
                          <ul
                            className={
                              darkMode === "theme-dark"
                                ? "tags_selection1"
                                : "tags_selection"
                            }
                          >
                            <li className="position-relative">
                              <input
                                type="search"
                                className={
                                  darkMode === "theme-dark"
                                    ? "serch-input1"
                                    : "serch-input"
                                }
                                placeholder="Brands"
                                onChange={(e) => {
                                  handleChangeEvent(e.target.value);
                                }}
                              />
                              <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/brand_seach.svg`}
                                alt="search"
                                className="image_icon_search"
                                style={{ color: "#FB9515" }}
                              />
                            </li>

                            {BrandListt.sort((a, b) =>
                              a.name.localeCompare(b.name)
                            ).map((item, index) => (
                              <li
                                key={item}
                                className={
                                  selectedBrand === item.id ? "active" : ""
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={(e) =>
                                    changeSelectedBrand(
                                      e,
                                      item.id,
                                      item.category_id
                                    )
                                  }
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>

                        {selectedBrand && (
                          <div className="col-12">
                            <ul
                              className={
                                darkMode === "theme-dark"
                                  ? "tags_selection1"
                                  : "tags_selection"
                              }
                            >
                              <li className="position-relative">
                                <input
                                  type="search"
                                  className={
                                    darkMode === "theme-dark"
                                      ? "serch-input1"
                                      : "serch-input"
                                  }
                                  placeholder="Categories"
                                  onChange={(e) => {
                                    handleChangeCategoryEvent(e.target.value);
                                  }}
                                />
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/brand_seach.svg`}
                                  alt="search"
                                  className="image_icon_search"
                                  style={{ color: "#FB9515" }}
                                />
                              </li>

                              {category.length > 0 &&
                                category
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((item, index) => (
                                    <li key={index}>
                                      <Link
                                        to="#"
                                        onClick={(e) =>
                                          changeSelectedCategory(e, item.id)
                                        }
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                            </ul>
                          </div>
                        )}

                        {selectedSubcategoryData && (
                          <div className="col-12">
                            <ul
                              className={
                                darkMode === "theme-dark"
                                  ? "tags_selection1"
                                  : "tags_selection"
                              }
                            >
                              <li className="position-relative">
                                <input
                                  type="search"
                                  className={
                                    darkMode === "theme-dark"
                                      ? "serch-input1"
                                      : "serch-input"
                                  }
                                  placeholder="Sub Categories"
                                  onChange={(e) => {
                                    handleChangeSubCategoryEvent(
                                      e.target.value
                                    );
                                  }}
                                />
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/brand_seach.svg`}
                                  alt="search"
                                  className="image_icon_search"
                                  style={{ color: "#FB9515" }}
                                />
                              </li>

                              {Subcategory.sort((a, b) =>
                                a.name.localeCompare(b.name)
                              ).map((item, index) => (
                                <li key={index}>
                                  <Link
                                    to="#"
                                    onClick={(e) =>
                                      changeSelectedSubCategory(e, item.id)
                                    }
                                  >
                                    {item.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 extra">
                    {couponData.length > 0 ? (
                      couponData.map((item, index) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3"
                        >
                          <div
                            className="package_list"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRedirect(item)}
                          >
                            <div className="images_main">
                              <img
                                src={item.brand_image}
                                alt={item.brand_name}
                              />
                              {loginUserData != null &&
                                loginUserData.id === item.user_id && (
                                  <label
                                    className="status_btn"
                                    style={{
                                      backgroundColor:
                                        item.is_complete === 1
                                          ? "#D7DBFF"
                                          : item.is_approve === 0
                                          ? "#5cf8fb"
                                          : item.is_approve === 2
                                          ? "#FFA7A7"
                                          : item.is_active === 1
                                          ? "#EEFFE9"
                                          : item.is_active === 2
                                          ? "#FFA7A7"
                                          : "",
                                    }}
                                  >
                                    {item.is_complete === 1
                                      ? "Complete"
                                      : item.is_approve === 0
                                      ? "Validating"
                                      : item.is_approve === 2
                                      ? "Rejected"
                                      : item.is_active === 1
                                      ? "Active"
                                      : item.is_active === 2
                                      ? "Hold"
                                      : ""}
                                  </label>
                                )}
                            </div>
                            <div
                              className={`package_desc ${
                                index % 2 === 0 ? "bg-1" : "bg-2"
                              }`}
                            >
                              <h4>
                                {item.brand_name.length > 20
                                  ? `${item.brand_name.slice(0, 20)}...`
                                  : item.brand_name}
                              </h4>
                              <div className="offer_div">
                                <span>
                                  {item.discount.length > 10
                                    ? `${item.discount.slice(0, 10)}...`
                                    : item.discount}
                                </span>
                              </div>
                              <p>
                                {item.onelinedescription.length > 50
                                  ? `${item.onelinedescription.slice(0, 50)}...`
                                  : item.onelinedescription}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-12 mt-10 text-center">
                        <strong style={{ fontSize: "20px" }}>
                          No Data Found
                        </strong>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Win;
