import React, { useState, useEffect } from 'react';
import { likeUnlikeCouponComment } from '../../utils/api.service';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';
import { Link } from 'react-router-dom';
const ListingItem = ({ item, loginUserData, couponData }) => {
    const [isLiked, setIsLiked] = useState(item.is_like === 1);

    useEffect(() => {
        setIsLiked(item.is_like === 1);
    }, [item]);
    const handleLikeUnlikeComment = () => {
        const newIsLiked = !isLiked;
        setIsLiked(newIsLiked);

        let payload = {
            comment_id: item.id,
            coupon_id: couponData.id,
            is_like: newIsLiked ? 1 : 0
        };

        likeUnlikeCouponComment(payload)
            .then(function (response) {
                if (response.code === '1') {
                    TOAST_SUCCESS(response.message);
                } else if (response.code !== '0') {
                    setIsLiked(!newIsLiked);
                    TOAST_ERROR(response.message);
                }
            })
            .catch(function (error) {
                
                setIsLiked(!newIsLiked);
            });
    };

    const TimeAgo = ({ timestamp }) => {
        const getPrettyTime = (datetime) => {
            const current = new Date();
            const difference = current - new Date(datetime * 1000);

            const SECOND = 1000;
            const MINUTE = SECOND * 60;
            const HOUR = MINUTE * 60;
            const DAY = HOUR * 24;
            const WEEK = DAY * 7;
            const MONTH = DAY * 30;
            const YEAR = DAY * 365;

            if (difference > YEAR) {
                const year = Math.floor(difference / YEAR);
                return `${year} ${year > 1 ? 'years' : 'year'} ago`;
            } else if (difference > MONTH) {
                const month = Math.floor(difference / MONTH);
                return `${month} ${month > 1 ? 'months' : 'month'} ago`;
            } else if (difference > WEEK) {
                const week = Math.floor(difference / WEEK);
                return `${week} ${week > 1 ? 'weeks' : 'week'} ago`;
            } else if (difference > DAY) {
                const day = Math.floor(difference / DAY);
                return `${day} ${day > 1 ? 'days' : 'day'} ago`;
            } else if (difference > HOUR) {
                const hour = Math.floor(difference / HOUR);
                return `${hour} ${hour > 1 ? 'hours' : 'hour'} ago`;
            } else if (difference > MINUTE) {
                const minute = Math.floor(difference / MINUTE);
                return `${minute} ${minute > 1 ? 'minutes' : 'minute'} ago`;
            } else if (difference > SECOND) {
                const second = Math.floor(difference / SECOND);
                return `${second} ${second > 1 ? 'seconds' : 'second'} ago`;
            } else {
                return 'just now';
            }
        };

        return <span>{getPrettyTime(timestamp)}</span>;
    };
    // Rest of your component code...

    return (
        <div className="comment_section" key={item.id}>
            <div className="winning_main">
                <div className="winner_img">
                    <img src={item.profile_image_url} />
                </div>
                <div className="win_detail">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5>{item.user_name}</h5>
                        <span >(<TimeAgo timestamp={item.created_at} />)</span>
                    </div>
                    <p>{item.comment}</p>

                    {/* {(loginUserData.id === couponData.user_id &&
                        <a href="#reply" className="text-reply" data-bs-target="#reply" data-bs-toggle="modal" data-bs-dismiss="modal">Reply</a>
                    )} */}
                </div>
                <div className="heart">
                    {item?.user_type === 'U' && (
                        <Link onClick={handleLikeUnlikeComment}>
                            {isLiked ? (
                                <img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/heart-red.svg'} alt="" className="" />
                            ) : (
                                <img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/heart.svg'} alt="" className="logo_1" />
                            )}
                        </Link>
                    )}
                </div>

            </div>
            {item.replies.map((item2, index2) => (
                <div className="inner_comment" key={item2.id}>

                    <ul>
                        {(item2.parent_id === item.id &&
                            <li>
                                <div className="winning_main">
                                    <div className="winner_img">
                                        <img src={item2.profile_image_url} />
                                    </div>
                                    <div className="win_detail">
                                        <h5>{item2.user_name}</h5>
                                        <p>{item2.comment}</p>
                                        <img src={item2.image} className="w-100_px" />
                                    </div>
                                    <div className="heart">
                                        {item2?.user_type === 'U' && (
                                            <Link onClick={() => handleLikeUnlikeComment(item2?.id)}>
                                                {isLiked ? (
                                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/heart-red.svg'} alt="" className="" />
                                                ) : (
                                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/heart.svg'} alt="" className="logo_1" />
                                                )}
                                            </Link>
                                        )}
                                    </div>
                                </div>

                            </li>
                        )}

                    </ul>
                </div>
            ))}
        </div>
    );
};

export default ListingItem;
