import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getWinnerList, getCommentList, checkWinnerUser } from "../../utils/api.service";
import { TOAST_ERROR,TOAST_INFO } from "../../utils/common.service";
import { Link, useLocation } from "react-router-dom";
import ListingItem from "./ListingItem";
import CommentModal from "../../components/modals/CommentModal";
import { openModal ,closeModal } from "../../store/slices/commentSlice";
import { useDispatch } from "react-redux";
import { openModal as openClaimModal } from "../../store/slices/claimSlice";
import ClaimDetailModal from "../../components/modals/ClaimDetailModal";
import Layout from "../../layouts/Layout";

const Winner = () => {
    // const location = useLocation(); 
    // const { isWinningPageRedirect } = location.state || {}; 

    // localStorage.setItem('isWinningPageRedirect',isWinningPageRedirect)

   const isWinningPageRedirect = localStorage.getItem('isWinningPageRedirect')
    const dispatch = useDispatch();
    const [winnerList, setWinnerList] = useState([]);
    
    const [campaignId, setCampaignId] = useState(0);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [loadMore, setLoadMore] = useState(true);
    const [isWinner, setIsWinner] = useState(0);
    
    const [isComment, setIsComment] = useState(0);
    const [winnerUser, setWinnerUser] = useState(null);
    const [isClaimed, setIsClaimed] = useState(0);
    const [isWinningPage,setIsWinningPage]= useState(isWinningPageRedirect)
    
    
    
    const handleOpenCommentModal = (gift_type, campaign_id, gift_id) => {
        // dispatch(openModal({campaignId: campaignId, addComment: handleAddNewComment}));
        dispatch(
            openModal({
              campaignId: campaign_id,
              GiftType: gift_type,
              GiftId: gift_id,
              addComment: handleAddNewComment
            })
          );
         
    };

    const handleCloseCommentModal = () => {
        dispatch(closeModal());
    };

    const handleOpenClaimModal = (gift_type, campaign_id, gift_id) => {
        dispatch(openClaimModal({gift_type: gift_type, campaign_id: campaign_id, gift_id: gift_id, handleIsClaimedLabel: handleIsClaimedLabel}))
    }
    const handleCloseClaimModal = () => {
        dispatch(closeModal())
    }

  

    const handleIsClaimedLabel = () => {
        setIsClaimed(1);
    };

  
    const fetchGetCommentList = () => {

        if (loadMore) {

            let payload = {
                campaign_id: campaignId,
                page: page
            };
            getCommentList(payload).then(function (response) {

                if (response.code === '1') {
                    const newData = response.data.result;
                    setData((prevData) => [...prevData, ...newData]);
                } else if (response.code === '2') {
                    setLoadMore(false);
                    // TOAST_ERROR(response.message);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }

    }

    const handleAddNewComment = (comment) => {
        setData((prevData) => [comment, ...prevData]);
        setIsComment(1)
    };

    useEffect(() => {
        if (campaignId !== 0) {
            fetchGetCommentList()
        }
    }, [page, campaignId])

    const handleScroll = () => {
        if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const fetchGetWinnerList = () => {
        let payload = {};
        getWinnerList(payload).then(function (response) {

            if (response.code === '1') {
                setWinnerList(response.data.result);
                setIsWinner(response.data.is_winner);
                setIsComment(response.data.is_comment);
                setCampaignId(response.data.result?.[0].campaign_id);
            } else if (response.code === '2') {
                // TOAST_ERROR(response.message);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }

        }).catch(function (error) {
            
        });
    }

    const fetchWinnerUser = () => {
        let payload = {};
        checkWinnerUser(payload).then(function (response) {
            if (response.code === '1') {
                setWinnerUser(response.data)
                setIsClaimed(response.data?.is_claim)
            } else if (response.code !== '0') {
                // TOAST_ERROR(response.message);
            }

        }).catch(function (error) {
            
        });
    }
    const handleClaimClick = () => {
        // if(isWinningPage === 'true'){
        //     const isWinningPageRedirect = localStorage.setItem('isWinningPageRedirect',true)

        // }
          if (isComment === 1) {
            handleOpenClaimModal(winnerUser?.gift_type, winnerUser?.campaign_id, winnerUser?.gift_id);
           const isWinningPageRedirect = localStorage.removeItem('isWinningPageRedirect')

          } else {
              
              TOAST_INFO('you need to post a comment to claim !');
              
            
          }
      
      };
      

    
    useEffect(() => {
        fetchGetWinnerList()
        fetchWinnerUser()
    }, [])

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | Winner`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            {(winnerList?.length > 0 ) ? <><section className="winnig_section">
                <div className="winner_dashboard ">
                    <div className="container">
                        <div className="row justify-content-center align-items-center ">

                            {winnerList[1] != undefined ? <div className="col-md-3 col-sm-3 col-4">
                                <div className="dashboard_list">
                                    <h4>2</h4>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/arrow-down.svg"} alt="" />
                                    <div className="user_winner">
                                        <img src={winnerList?.[1].profile_image} alt={winnerList?.[1].winner_name} />
                                    </div>
                                    <Link>{winnerList?.[1].winner_name}</Link>
                                    <label>
                                        {winnerList?.[1].no_of_resp} <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/coins.svg"} className="ms-1" />
                                    </label>
                                </div>
                            </div> : <div className="col-md-3 col-sm-3 col-4"></div>}

                            {winnerList[0] != undefined ? <div className="col-md-3 col-sm-3 col-4">
                                <div className="dashboard_list wiiner_div">
                                    <h4>1</h4>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/crown.svg"} alt="" />
                                    <div className="user_winner">
                                        <img src={winnerList?.[0].profile_image} alt={winnerList?.[0].winner_name} />
                                    </div>
                                    <Link>{winnerList?.[0].winner_name}</Link>
                                    <label>
                                        {winnerList?.[0].no_of_resp} <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/coins.svg"} className="ms-1" />
                                    </label>
                                </div>
                            </div> : <div className="col-md-3 col-sm-3 col-4"></div>}

                            {winnerList[2] != undefined ? <div className="col-md-3 col-sm-3 col-4">
                                <div className="dashboard_list">
                                    <h4>3</h4>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/arrow-down-dark.svg"} alt="" />
                                    <div className="user_winner">
                                        <img src={winnerList?.[2].profile_image} alt={winnerList?.[2].winner_name} />
                                    </div>
                                    <Link>{winnerList?.[2].winner_name}</Link>
                                    <label>
                                        {winnerList?.[2].no_of_resp} <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/coins.svg"} className="ms-1" />
                                    </label>
                                </div>
                            </div> : <div className="col-md-3 col-sm-3 col-4"></div>}

                        </div>
                    </div>
                </div>
            </section>
                <section className="winnig_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="ad_name">
                                        <h4>Comment</h4>
                                    </div>
                                    {/* {(isWinner === 1 && isComment === 1) && <Link className="btn btn-theme btn-sm ms-auto ps-2" onClick={handleOpenCommentModal}>
                                        Post
                                    </Link>} */}
                                    {isWinner === 1 ? (
                                            isComment === 1 ? (
                                                handleCloseCommentModal()
                                            ) : (
                                                // <button className="btn btn-theme btn-sm ms-auto ps-2" onClick={handleOpenCommentModal(winnerUser?.gift_type, winnerUser?.campaign_id, winnerUser?.gift_id)}>
                                            <button className="btn btn-theme btn-sm ms-auto ps-2" onClick={() => handleOpenCommentModal(winnerUser?.gift_type, winnerUser?.campaign_id, winnerUser?.gift_id)}>
    
                                                Post
                                                </button>
                                            )
                                        ) : (
                                            handleCloseClaimModal()
                                            // <button className="btn btn-theme btn-sm ms-auto ps-2" onClick={handleCloseClaimModal}>
                                            //     Close Claim Modal
                                            // </button>
                                        )}

                                </div>
                            </div>

                            {(data.length > 0) ? data.map((item, index) => (
                                <ListingItem key={index} item={item} />
                            )) : <div className="col-12 mt-10 text-center">
                                <strong style={{ fontSize: '20px' }}>No Data Found</strong>
                            </div>}

                        </div>
                        <div>
                            {/* condition here is_comment === 1 then show otherwise toast_info ('messageeeee) */}
                        {/* {(isClaimed === 0 && isWinner === 1) && <Link onClick={() => handleOpenClaimModal(winnerUser?.gift_type, winnerUser?.campaign_id, winnerUser?.gift_id)}>
                            <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/icon/claim.svg"} className="claim-icon" alt="" />
                        </Link>} */}

                         {(isClaimed === 0 && isWinner === 1) && ( 
                            <Link onClick={handleClaimClick}>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/icon/claim.svg"} className="claim-icon" alt="Claim" />
                            </Link>
                            )} 
                    </div>
                    </div>
                </section></> : <div className="col-12 mt-10 text-center">
                <strong style={{ fontSize: '20px' }}>No winner has been declared yet, the winner will be announced soon..</strong>
            </div>}

            <CommentModal />
            <ClaimDetailModal />
        </Layout>
    )
}

export default Winner;