import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/createAdsSlice';
import { useForm, Controller } from 'react-hook-form';
import { openModal as openAdsApprovalModal } from '../../store/slices/adsApprovalSlice';
import { adsCategoryList, adsPriceList, createAdvertisement, getStates, createStripeCheckoutSession ,SubCategoryList ,BrandList,CategoryList,CategoryListDeatils,FetchBrandList} from '../../utils/api.service';
import { validateFile, TOAST_SUCCESS, TOAST_ERROR, SWAL_ALERT } from '../../utils/common.service';
import StateCityRow from '../common/StateCityRow';
import moment from 'moment';
import { uploadFile, uploadBase64Image } from '../../utils/aws.service';
import Loader from '../loader/Loader';

const CreateAdsModal = () => {

    const loginUserData = useSelector((state) => state.authUser.loginUserData);
    const isModalOpen = useSelector((state) => state.modalCreateAds.isModalOpen);
    const dispatch = useDispatch();

    const handleCloseCreateAdsModal = () => {
        dispatch(closeModal());
    };

    const handleOpenAdsApprovalModal = () => {
        handleCloseCreateAdsModal();
        dispatch(openAdsApprovalModal());
    };

    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
   const [Subcategories,setSubCategories] =useState()

   const [SubcategoryId, setSubCategoryId] = useState([]);
   const [BrandId, setBrandId] = useState([]);
   const [Brandlistt,setBrandListt] =useState()
    const [states, setStates] = useState([]);
    const [fileInput, setFileInput] = useState(false);
    const [fileType, setFileType] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileExtension, setFileExtension] = useState(null);
    const [stateCityIds, setStateCityIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [unitAmount, setUnitAmount] = useState(0);
    const [unitGST, setUnitGST] = useState(0);
    const [unitTotalAmount, setUnitTotalAmount] = useState(0);
    const labelref = useRef(null);

    const handleAdType = (e) => {
        setSelectedFile(null)
        if (e.target.value != undefined && e.target.value != '') {
            if (e.target.value === "image") {
                labelref.current.textContent = "Note:For image ads resolution of Aspected Ratio-: 9:16 is suggested.Maximum File Size Limit:5 MB."; // Set the value dynamically
            }
            else {
                labelref.current.textContent = "Note:For video suggested video is around 10-15 sec,Aspected Ratio-: 9:16 and Maximum File Size Limit:50 MB"; // Set the value dynamically
            }
            setFileType(e.target.value);
            clearErrors("ad_type");
            clearErrors("image_video");
            setValue('image_video', null);
            setFileInput(true);
            let payload = {
                ad_id: e.target.value
            }
            adsPriceList(payload).then(function (response) {
                if (response.code === '1') {
                    setValue("ads_display_no", response.data.ad_display_no);
                    setValue("amount", response.data.amount * response.data.ad_display_no);
                    setValue("gst", (getValues("amount") * 18) / 100);
                    setValue("total_amount", (response.data.amount + getValues("gst")));

                    setUnitAmount(response.data.amount);
                    setUnitGST(getValues("gst"));
                    setUnitTotalAmount(getValues("total_amount"));

                } else if (response.code !== '0') {
                    // TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        }
        else {
            setFileType('');
            clearErrors("image_video");
            setFileInput(false);
            labelref.current.textContent = ""; 
            setValue("ads_display_no", "");
            setValue("amount", "");
            setUnitAmount(0);
            setUnitGST(0);
            setUnitTotalAmount(0);
        }
    }

    const handleUnitAmount = (e) => {
        if (e.target.value != undefined && e.target.value != '') {
            setValue("amount", unitAmount * e.target.value);
            setValue("gst", ((unitAmount * e.target.value) * 18) / 100);
            setValue("total_amount", ((unitAmount * e.target.value) + getValues("gst")));
        }
        else {
            setValue("ads_display_no", "");
            setValue("gst", "");
            setValue("total_amount", "");

        }
    }

    const handleFileInput = (e) => {

        let file = e.target.files[0];
        if (file) {
            let fileSize = file.size / 1024
            let ad_type = getValues('ad_type');
            let validateSize = (ad_type === 'image' ? 5120 : 51200)
            let message = "";

            if (!validateFile(file.name, ad_type)) {
                if (ad_type === 'image') {
                    message = "Image must be jpg, jpeg, png type";
                }
                else {
                    message = "Video must be MP4, MPEG, MKV type";
                }
                setError("image_video", {
                    type: "manual",
                    message: message,
                })
            }
            else if (fileSize > validateSize) {
                if (ad_type === 'image') {
                    message = "Please select a image smaller than 5MB";
                }
                else {
                    message = "Please select a video smaller than 20MB";
                }
                setError("image_video", {
                    type: "manual",
                    message: message,
                })
            }
            else {
                if (file) {

                    let parts = file.name.split('.');
                    let extension = parts[parts.length - 1];
                    setFileExtension(extension);

                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const base64String = e.target.result;

                        if (ad_type === 'image') {
                            const img = new Image();
                            img.src = base64String;
                            img.onload = () => {
                                const aspectRatio = img.width / img.height;
                                if (Math.abs(aspectRatio - 9 / 16) < 0.01) {
                                    setValue('image_video', base64String);
                                    setSelectedFile(base64String);
                                    clearErrors("image_video");
                                } else {
                                    setError("image_video", {
                                        type: "manual",
                                        message: "Invalid aspect ratio. Please upload a 9:16 image.",
                                    });
                                }
                            };
                        }
                        else {
                            const video = document.createElement('video');
                            video.src = URL.createObjectURL(file);
                            video.onloadedmetadata = () => {
                                const aspectRatio = video.videoWidth / video.videoHeight;
                                if (Math.abs(aspectRatio - 9 / 16) < 0.01) {
                                    setValue('image_video', file);
                                    createVideoThumbnail(file);
                                    clearErrors("image_video");
                                } else {
                                    setError("image_video", {
                                        type: "manual",
                                        message: "Invalid aspect ratio. Please upload a 9:16 video.",
                                    });
                                }
                            };
                        }
                    };
                    reader.readAsDataURL(file);
                    clearErrors("image_video");

                }

            }
        }
    }

    const createVideoThumbnail = (videoFile) => {
        var video = document.createElement('video');
        video.preload = 'metadata';

        video.onerror = function () {
            
        };
        video.autoplay = true;
        video.src = URL.createObjectURL(videoFile);

        setTimeout(() => {
            var canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            var thumbnailUrl = canvas.toDataURL('image/jpeg');

            setSelectedFile(thumbnailUrl);
        }, 1000);

    };

    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        getValues,
        reset,
        control,
        setError,
        clearErrors,
        trigger,
        formState: { errors, isValid },
    } = useForm();

    const onSubmit = async (data) => {

        setIsLoading(true);

        let file_name = '';
        if (parseInt(data.seen_perday) > parseInt(data.ads_display_no)) {
            setIsLoading(false);

            TOAST_ERROR("Please ensure that the Ad Display Number is greater than or equal to the minimum required number of times an ad should be seen by a person per day.");
            return;
        }
        if (data.image_video !== null) {

            file_name = Math.floor(Math.random() * 999999) + '_' + moment().format('X') + '.' + fileExtension;
            let filePath = process.env.REACT_APP_AWS_FOLDER_NAME + '/watch/' + file_name;

            if (data.ad_type == 'video') {
                await uploadFile(data.image_video, filePath)
            }
            else {
                await uploadBase64Image(data.image_video, filePath)
            }
        }
     
        if (isValid && data.image_video !== null) {

            let payload = {
                ad_category_id: data.category,
                ad_type_id: data.ad_type,
                category_id:categoryId,
                sub_category_id:SubcategoryId,
                brand_id:BrandId,
                ad_type: data.ad_type,
                name: data.name,
                image_video: file_name,
                seen_perday: data.seen_perday,
                ad_display_no: data.ads_display_no,
                amount: data.amount,
                gst: data.gst,
                total_amount: data.total_amount,
                description: data.description,
                audience_type: 'behavioral audience',
                ad_url: data.ad_url,
                is_comment_view: data.comment_view ? 1 : 0,
                states_cities_ids: stateCityIds,
                country: 'INDIA',
                user_type: 'U'
            };

            let stripe_payload = {
                amount: data.total_amount,
                quantity: 1,
                currency: "inr",
                email: loginUserData?.email,
                product_name: data.name,
                success_url: process.env.REACT_APP_PUBLIC_URL + "manage-advertisement/stripe-success/advertisement-add",
                cancel_url: process.env.REACT_APP_PUBLIC_URL + "manage-advertisement/stripe-cancel/advertisement-add"
            };

            createStripeCheckoutSession(stripe_payload).then(async function (stripe_response) {
                setIsLoading(false);

                if (stripe_response.code === '1') {
                    payload.checkout_session_id = stripe_response.data.id;

                    createAdvertisement(payload).then(function (response) {
                        setIsLoading(false);
                        if (response.code === '1') {
                            window.location.href = stripe_response.data.url;
                        } else if (response.code !== '0') {
                            TOAST_ERROR(response.message);
                        }
                    }).catch(function (error) {
                        setIsLoading(false);
                        
                    });

                } else if (stripe_response.code !== '0') {
                    TOAST_ERROR(stripe_response.message);
                }
            }).catch(function (error) {
                setIsLoading(false);
                
            });

        }

    };

    const handlePreSubmit = async () => {

        fields.map(field => {
            setError(`states_cities_ids[${field.id}].state`, { type: "manual", message: "Please select state" });
            setError(`states_cities_ids[${field.id}].city`, { type: "manual", message: "Please select city" });

            if (stateCityIds.length > 0) {
                stateCityIds.map((item, index) => {
                    clearErrors(`states_cities_ids[${index + 1}].state`);

                    if (item.city.length > 0) {
                        clearErrors(`states_cities_ids[${index + 1}].city`);
                    }
                })
            }
        })

        try {
            await trigger(); 
            if (Object.keys(errors).length === 0) {
              
                const data = getValues(); 

                if (data.image_video === null) {
                    setError('image_video', {
                        type: 'manual',
                        message: `Please select ${(data.ad_type == 'video') ? 'video' : 'image'}`
                    });
                }
                else {
                    onSubmit(data);
                }
            }
        } catch (err) {
            
        }
    };

    const getCategoryList = () => {
        let payload = {}
        CategoryListDeatils(payload).then(function (response) {
            if (response.code === '1') {
                setCategories(response.data);
            } else if (response.code !== '0') {
                // TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }

    const getStateList = () => {
        let payload = {};
        getStates(payload).then(function (response) {
            if (response.code === '1') {
                setStates(response.data);
            } else if (response.code !== '0') {
                // TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }

    const [fields, setFields] = useState([{ id: 1 }]);

    const addField = () => {
        const newField = { id: fields.length + 1 };
        setFields([...fields, newField]);
    };

    const deleteField = (idToDelete, selectedState, selectedCities) => {
        if (idToDelete !== undefined && idToDelete !== '') {
            setFields(fields.filter(field => field.id !== idToDelete));

            if (stateCityIds.some((item) => item.state === selectedState)) {
                setStateCityIds((prevIds) => prevIds.filter((item) => item.state !== selectedState));
            }

            clearErrors(`states_cities_ids[${idToDelete}].state`);
            clearErrors(`states_cities_ids[${idToDelete}].city`);
        }
        else {
            setFields(fields.filter(field => field.id === 1));
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            reset();
            getCategoryList();
            getStateList();
            setFileType('');
            clearErrors("image_video");
            setFileInput(false);
            labelref.current.textContent = ""; // Set the value dynamically
            setValue("ads_display_no", "");
            setValue("amount", "");
            setUnitAmount(0);
            setUnitGST(0);
            setUnitTotalAmount(0);
        }
    }, [isModalOpen])

    useEffect(() => {
        const pathName = window.location.pathname;
        let pathArr = pathName.split('/');
        let newUrl = process.env.REACT_APP_PUBLIC_URL + "manage-advertisement";

        if (pathArr[pathArr.length - 2] === 'stripe-success') {
            if (pathArr[pathArr.length - 1] === 'advertisement-add') {

                let redirectUrl = process.env.REACT_APP_PUBLIC_URL + "manage-advertisement";
                dispatch(openAdsApprovalModal({ redirectUrl: redirectUrl }));
                window.history.replaceState(newUrl, '', newUrl);
            }
            else {
                let redirectUrl = process.env.REACT_APP_PUBLIC_URL + "manage-advertisement";
                dispatch(openAdsApprovalModal({ redirectUrl: redirectUrl, name: 'advertisement-add' }));
                window.history.replaceState(newUrl, '', newUrl);
            }
            window.history.pushState(null, null, newUrl);
            TOAST_SUCCESS('Payment created successfully');
        }
        if (pathArr[pathArr.length - 2] === 'stripe-cancel') {
            let redirectUrl = process.env.REACT_APP_PUBLIC_URL + "manage-advertisement";
            SWAL_ALERT('Payment failed', 'error', true, null, redirectUrl);
            window.history.replaceState(newUrl, '', newUrl);
            window.history.pushState(null, null, newUrl);
        }

    }, []);

    function getCaterogyid(e){
        setCategoryId(e.target.value)

        setValue('subcategory','')
        setValue('brand','')
            SubCategoryList({category_id:e.target.value}).then(function (response) {
                if (response.code === '1') {
                    setSubCategories(response.data);
                } else if (response.code !== '0') {
                    // TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
      
    }
        
    function getSubCaterogyid(e){
        setSubCategoryId(e.target.value)
        setValue('brand','')
        FetchBrandList({category_id:categoryId ,sub_category_id: e.target.value}).then(function (response) {
            if (response.code === '1') {
                setBrandListt(response.data);
            } else if (response.code !== '0') {
                // TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
  
}


function getBrandid(e){
    setBrandId(e.target.value)
   

}




    
    return (

        <>

            <Modal show={isModalOpen} size="lg">
                <Modal.Body>
                    <button type="button" className="custom_btn" onClick={handleCloseCreateAdsModal}>X</button>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={`row`} >
                            <div className="col-md-12 mb-3">
                                <div className="slider_rutorial text-center">
                                    <h1>Create ads</h1>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <div className='select_div'>
                                        <select className="form-select" {...register("category", { required: "Please select category" })} autoComplete="off" onChange={(e) => getCaterogyid(e)}>
                                            <option value="" >Select Ad Category</option>
                                            {categories.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <label className="error">{errors.category?.message}</label>
                            </div>
                            
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <div className='select_div'>
                                        <select className="form-select" {...register("subcategory", { required: "Please select sub category" })} autoComplete="off" onChange={(e) => getSubCaterogyid(e)}>
                                            <option value="" >Select Sub Category</option>
                                            {Subcategories && Subcategories.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <label className="error">{errors.subcategory?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <div className='select_div'>
                                        <select className="form-select" {...register("brand", { required: "Please select brand" })} autoComplete="off" onChange={(e) => getBrandid(e)} >
                                            <option value="" >Select Brand</option>
                                            {Brandlistt && Brandlistt.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <label className="error">{errors.brand?.message}</label>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("name", { required: "Please enter advertisement name", pattern: { value: /^[a-zA-Z0-9 ]*$/, message: "Please enter only letters, numbers & spaces with no special characters" }, maxLength: { value: 100, message: "Advertisement name must not exceed 100 characters" } })} placeholder="Advertisement Name" />
                                </div>
                                <label className="error">{errors.name?.message}</label>
                            </div>

                            {/* <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group  w-100">
                                <input type="text" className="form-control" {...register("company_name", { required: false, pattern: { value: /^[a-zA-Z]*$/, message: "Please enter only letters (A-Z, a-z) with no special characters or numbers" }, maxLength: { value: 100, message: "Company name must not exceed 100 characters" } })} placeholder="Company Name" />
                            </div>
                            <label className="error">{errors.company_name?.message}</label>
                        </div> */}
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <textarea className="form-control" {...register("description", {
                                        required: "Please enter description",
                                        // pattern: { value: /^[a-zA-Z0-9. \n\uD83C-\uDBFF\uDC00-\uDFFF]*$/, message: "Please enter only letters, numbers, emojis & spaces with no special characters" },
                                        maxLength: { value: 1000, message: "Description must not exceed 1000 characters" }
                                    })} autoComplete="off" placeholder="Description"></textarea>
                                </div>
                                <label className="error">{errors.description?.message}</label>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <div className='select_div'>
                                        <select className="form-select" {...register("ad_type", { required: "Please select ad type" })} autoComplete="off" onChange={handleAdType}>
                                            <option value="">Select Ad Type</option>
                                            <option value="video">Video</option>
                                            <option value="image">Image</option>
                                        </select>
                                    </div>
                                </div>
                                <label className="error">{errors.ad_type?.message}</label>
                            </div>

                            <div className={`col-md-12 col-12 mb-3 ${fileInput ? '' : 'd-none'}`} >
                                <div className="upload_image_video">
                                    <Controller name="image_video" control={control} defaultValue={null} render={({ field }) => (
                                        <>
                                            <input type="file" accept={fileType === 'image' ? 'image/*' : 'video/*'} onChange={handleFileInput} />
                                        </>
                                    )} />
                                    <h6>Upload Image/Video</h6>
                                    <span className="error" ref={labelref}></span>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/image.svg"} alt='' />
                                    <img src={selectedFile} alt='' height={'150px'} className={selectedFile === null ? 'd-none' : ''} />
                                </div>
                                <label className="error">{errors.image_video?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" placeholder="Ad Display Number" {...register("ads_display_no", {
                                        required: "Please enter ad display number", pattern: { value: /^[0-9].*$/, message: "Please enter only numbers" },
                                        min: { value: 1, message: "Please enter minimum 1 and maximum 10000 ad display number" }, max: { value: 10000, message: "Please enter minimum 1 and maximum 10000 ad display number" }
                                    })} autoComplete="off" onChange={handleUnitAmount} />
                                </div>
                                <label className="error">{errors.ads_display_no?.message}</label>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" placeholder="No. of times ad seen by a person per day" {...register("seen_perday", {
                                        required: "Please enter no. of times ad seen by a person per day", pattern: { value: /^[0-9].*$/, message: "Please enter only numbers" },
                                        min: { value: 0, message: "No. of times ad seen by a person per day must be 0 or less than & equal to ad display number" }, max: { value: 10000, message: "No. of times ad seen by a person per day must be 0 or less than & equal to ad display number" }
                                    })} autoComplete="off" />
                                </div>
                                <label className="error">{errors.seen_perday?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" placeholder="Amount" {...register("amount", {
                                        required: "Please enter amount", pattern: { value: /^[0-9].*$/, message: "Please enter only numbers" }
                                    })} readOnly autoComplete="off" />
                                </div>
                                <label className="error">{errors.amount?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" placeholder="GST (18%)" {...register("gst", {
                                        required: "Please enter GST", pattern: { value: /^[0-9].*$/, message: "Please enter only numbers" }
                                    })} readOnly autoComplete="off" />
                                </div>
                                <label className="error">{errors.gst?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" placeholder="Total Amount" {...register("total_amount", {
                                        required: "Please enter total amount", pattern: { value: /^[0-9].*$/, message: "Please enter only numbers" }
                                    })} readOnly autoComplete="off" />
                                </div>
                                <label className="error">{errors.gst?.message}</label>
                            </div>


                            <div className="col-md-12 col-sm-12 col-12 mb-3">

                                {fields.map(field => (
                                    <div key={field.id} className="row">
                                        <StateCityRow states={states} rowId={field.id} addField={addField} deleteField={deleteField} stateCityIds={stateCityIds} setStateCityIds={setStateCityIds} useFormObject={{ register, errors, setError, clearErrors }} />
                                    </div>
                                ))}

                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" placeholder="Advertisement URL" {...register("ad_url", { required: "Please enter advertisement URL", pattern: { value: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/, message: "Please enter a valid advertisement URL" } })} autoComplete="off" />
                                </div>
                                <label className="error">{errors.ad_url?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" {...register("comment_view", { required: false })} />
                                    <label className="form-check-label modal-text" htmlFor="flexCheckDefault">
                                        Disable Comments on Advertisement
                                    </label>
                                </div>
                                <label className="error">{errors.comment_view?.message}</label>
                            </div>

                            <div className="col-md-12 mb-2">
                                <button type="button" className="btn btn-theme mt-auto w-100" onClick={handlePreSubmit}>Done</button>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>

            <Loader isLoading={isLoading} />
        </>
    );
};

export default CreateAdsModal;
