import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Layout from '../../layouts/Layout';
import { adImpression } from '../../utils/api.service';
import { TOAST_ERROR } from '../../utils/common.service';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const ImpressionAnalytics = () => {

    const { id } = useParams();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [states, setStates] = useState([]);
    const [totalHit, setTotalHit] = useState(0);
    const [totalLike, setTotalLike] = useState(0);
    const [loadMore, setLoadMore] = useState(true);

    const fetchAdImpression = () => {

        if (loadMore) {

            let payload = {
                ad_id: id,
                page: page
            };
            adImpression(payload).then(function (response) {

                if (response.code === '1') {
                    const newData = response.data.result;
                    setData((prevData) => [...prevData, ...newData]);
                    setStates(response.data.state_city);
                    setTotalHit(response.data['Total Hit']);
                    setTotalLike(response.data['Total Like']);
                } else if (response.code === '2') {
                    setLoadMore(false);
                    TOAST_ERROR(response.message);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }

    }

    useEffect(() => {
        fetchAdImpression()
    }, [page])

    const handleScroll = () => {
        if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | Impression Analytics`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            <section className="winnig_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 mb-5">
                            <div className="d-flex align-items-center">
                                <div className="ad_name">
                                    <h4>Impression Analytics</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="impression_main">
                                <div className="im_1 impression_sub">
                                    <h5>State</h5>
                                    {(states.length > 0) ? (states.length == 1 ? <p>{states[0].state}</p> : <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/more_info.svg"} className="logo_1" width={24} />) : <p>All States</p>}
                                </div>
                                <div className="im_1 impression_sub">
                                    <h5>City</h5>
                                    {(states.length > 0) ?  
                                    (states.length == 1 ? ((states[0].City.length > 0) ? (states[0].City.length == 1 ? <p>{states[0].City[0].name}</p> : <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/more_info.svg"} className="logo_1" width={24} />) : 'All Cities')
                                    : <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/more_info.svg"} className="logo_1" width={24} />)
                                    : <p>All Cities</p>}
                                </div>
                                <div className="im_1 impression_sub">
                                    <h5>Total Hit</h5>
                                    <p>{totalHit}</p>
                                </div>
                                <div className="impression_sub">
                                    <h5>Likes</h5>
                                    <p>{totalLike}</p>
                                </div>
                            </div>
                        </div>

                        {(data.length > 0) ? data.map((item, index) => (
                            <div key={index} className="col-md-6 col-sm-12 col-12 mb-3">
                                <div className="impression_main flex-column">
                                    <div className="date_impre">
                                        <h4>Date</h4>
                                        <p>{moment(item.view_date).format('DD MMM, YYYY')}</p>
                                    </div>
                                    <div className="impres_2">
                                        <div className="im_1 impression_sub">
                                            <h5>Total Remaining</h5>
                                            <p>{item.total_remaining}</p>
                                        </div>
                                        <div className="im_1 impression_sub">
                                            <h5>View Count</h5>
                                            <p>{item.view_count}</p>
                                        </div>
                                        <div className="impression_sub">
                                            <h5>Unique View</h5>
                                            <p>{item.unique_view}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : <div className="col-12 mt-10 text-center">
                            <strong style={{ fontSize: '20px' }}>No Data Found</strong>
                        </div>}
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default ImpressionAnalytics;
