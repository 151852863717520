import { createSlice } from "@reduxjs/toolkit";

const AuthUserSlice = createSlice({
    name: 'authUser',
    initialState: {
        isLoggedin: false,
        loginUserData: null,
    },
    reducers: {
        loginUser: (state, authUserData) => {
            state.isLoggedin= true;
            state.loginUserData = authUserData.payload;
        },
        logoutUser: (state) => {
            state.isLoggedin= false;
            state.loginUserData = null;
        },
    },
});

export const { loginUser, logoutUser } = AuthUserSlice.actions;
export default AuthUserSlice.reducer;