import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeModal } from "../../store/slices/modalLoginSlice";
import { openModal } from "../../store/slices/modalSignupSlice";
import { useForm } from "react-hook-form";
import {
  TOAST_SUCCESS,
  TOAST_ERROR,
  storeAuthData,
} from "../../utils/common.service";
import { signIn, login } from "../../utils/api.service";
import { useNavigate, Link } from "react-router-dom";
import { loginUser } from "../../store/slices/authUserSlice";
import { openModal as openForgotModal } from "../../store/slices/forgotPasswordSlice";
import countriesData from "../../assets/country_dial_info.json";

// import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";

import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";

import {
  auth,
  googleProvider,
  appleProvider,
  facebookProvider,
} from "./FirebaseConfig";

const LoginModal = () => {
  const userAgent = navigator.userAgent;
  const oscpu = navigator.oscpu;
  const [SocialLogin, SetSocialLogin] = useState(false);
  const [profile, setProfile] = useState(null);
  const isModalOpen = useSelector((state) => state.modalLogin.isModalOpen);
  const dispatch = useDispatch();

  const handleCloseModalLogin = () => {
    dispatch(closeModal());
  };

  const handleOpenModalSignup = () => {
    dispatch(openModal());
    // handleCloseModalLogin();
  };
  const handleCloseModalSignup = () => {
    dispatch(closeModal());
  };

  const handleOpenModalForgotPassword = () => {
    dispatch(openForgotModal({ otpFor: showEmailDiv }));
    handleCloseModalLogin();
  };

  const navigate = useNavigate();

  const [showEmailDiv, setShowEmailDiv] = useState("email");
  const [showEmail, setShowEmail] = useState(true);
  const [showMobile, setShowMobile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOpenEmailMobileField = (fieldName) => {
    setShowEmailDiv(fieldName);

    if (fieldName === "email") {
      setShowEmail(true);
      setShowMobile(false);
      unregister("countryCode");
      clearErrors("countryCode");
      unregister("mobileNumber");
      clearErrors("mobileNumber");
      register("email");
    } else {
      setShowEmail(false);
      setShowMobile(true);
      unregister("email");
      clearErrors("email");
      register("countryCode");
      register("mobileNumber");
    }
  };

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    reset,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let payload = {
      password: data.password,
      login_type: "S",
      device_type: "W",
      device_name: userAgent,
      device_model: oscpu,
      device_token: "0",
    };

    if (showEmailDiv === "email") {
      payload.email = data.email;
    } else {
      payload.country_code = data.countryCode;
      payload.mobile_number = data.mobileNumber;
    }

    signIn(payload)
      .then(function (response) {
        if (response.code === "1") {
          const authUserData = storeAuthData(response.data);
          dispatch(loginUser(authUserData));
          localStorage.setItem("reps_count", 0);

          handleCloseModalLogin();
          setTimeout(() => {
            TOAST_SUCCESS(response.message);
          }, 100);

          navigate("/dashboard");
        } else if (response.code !== "0") {
          TOAST_ERROR(response.message);
        }else{
          TOAST_ERROR(response.message);
        }
      })
      .catch(function (error) {
        
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      reset();
    }
  }, [isModalOpen]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
   
      const user = result.user;
      const { providerData, email } = user;

      let user_name = providerData[0].displayName;
      let first_name = "";
      let last_name = "";

      if (user_name) {
        const nameParts = user_name.split(" ");
        first_name = nameParts[0];
        last_name = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
      }

      let user_email = email ? email : providerData[0].email;
      let user_profile_picture = providerData[0].photoURL;
      const socialobj = {
        first_name: first_name,
        last_name: last_name,
        login_type: "G",
        device_token: "0",
        device_type: "W",
        social_id: user.uid,
        email: user_email,
        profile_picture: user_profile_picture,
        is_18_above: 1,
      };
      
      SetSocialLogin(true);

      setProfile(socialobj);
      login(socialobj).then(function (response) {

        if (response.code === "1") {
          setTimeout(() => {
            TOAST_SUCCESS(response.message);
          }, 100);
          handleCloseModalSignup();

          const authUserData = storeAuthData(response.data);
          dispatch(loginUser(authUserData));
          localStorage.setItem("reps_count", 0);

          navigate("/dashboard");
        } else if (response.code === "11") {
          localStorage.setItem("SocialProfileData", JSON.stringify(socialobj));
          localStorage.setItem("SocialLogin", true);
          handleCloseModalLogin();
          handleOpenModalSignup();
        } else {
          TOAST_ERROR(response.message);
        }
      });
    } catch (error) {
      
    }
  };

  const handleFacebookLoginFailure = async (error) => {
    TOAST_ERROR("Facebook Login Failed");
  };

  const handleFacebookLogin = async (user) => {
    try {
      TOAST_SUCCESS("facebook success");
    } catch (error) {
      
    }
  };

  return (
    <Modal show={isModalOpen}>
      <Modal.Body>
        <button
          type="button"
          className="custom_btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleCloseModalLogin}
        >
          X
        </button>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="slider_rutorial text-center">
                <a href="#" className="mb-3 ">
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/logo.png"
                    }
                    alt=""
                    className="logo w-200-login logo_1"
                  />
                </a>

                <h1>Log In</h1>
                <h6>Welcome to Onestens</h6>
              </div>
            </div>
            <div className="row text-center">
              {/* <LoginSocialGoogle  className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6"> */}
                <div>
                  <a
                    className="login_with inter font_17"
                    style={{ cursor: "pointer" }}
                    onClick={handleGoogleSignIn}
                  >
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/google.svg`}
                      className="me-2"
                      alt="google"
                      style={{
                        width: "200px",
                        height: "50px",
                        marginLeft: "0%",
                      }}
                    />
                  </a>
                </div>
              {/* </LoginSocialGoogle> */}

              {/* <LoginSocialFacebook
                      appId={process.env.REACT_APP_FB_APP_ID}
                      scope="public_profile,email"
                      onResolve={(response) => {
                        const user = response?.data;
                        handleFacebookLogin(user);
                      }}
                      onReject={handleFacebookLoginFailure}
                    >  <div>
                    <a className="login_with inter font_17" style={{ cursor: "pointer" }}>
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/facebook.png`}
                        className="me-2"
                        alt="facebook"
                      />
                    </a>
                  </div>
                    </LoginSocialFacebook> */}
            </div>

            <div
              className={`col-md-12 mb-3 mt-5 d-flex ${
                showEmailDiv === "email" ? "" : "d-none"
              }`}
            >
              <div className="form-group fixed_icon w-100">
                {showEmail && (
                  <input
                    type="text"
                    className="form-control"
                    {...register("email", {
                      required: "Please enter email address",
                      pattern: {
                        value:
                          /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
                        message: "Please enter a valid email address",
                      },
                    })}
                    autoComplete="off"
                    placeholder="Email Address"
                  />
                )}
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "assets/images/icon/sms.svg"
                  }
                  className="front_icon logo_1"
                />
              </div>
              <div
                className="mobile_div"
                onClick={() => handleOpenEmailMobileField("mobile")}
              >
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "assets/images/icon/mobile.svg"
                  }
                  className="logo_1"
                  alt=""
                />
              </div>
            </div>
            <label
              className={`error mb-3 ${
                showEmailDiv === "email" ? "" : "d-none"
              }`}
              style={{ marginTop: "-0.9375rem" }}
            >
              {errors.email?.message}
            </label>

            <div
              className={`col-md-12 mb-3 d-flex ${
                showEmailDiv === "mobile" ? "" : "d-none"
              }`}
            >
              <div className="form-group fixed_icon w-100">
                <div className="mobile_div_1">
                  {showMobile && (
                    <select
                      defaultValue={"+91"}
                      className="form-select"
                      {...register("countryCode", {
                        required: "Please select country code",
                      })}
                      style={{ paddingRight: "30px" }}
                    >
                      <option value="">Country Code</option>
                      {countriesData.map((item, index) => (
                        <option key={index} value={item.dial_code}>
                          {item.dial_code + " " + item.name.substring(0, 15)}
                        </option>
                      ))}
                    </select>
                  )}

                  {showMobile && (
                    <input
                      type="tel"
                      className="form-control"
                      style={{ paddingLeft: "15px" }}
                      placeholder="Mobile No"
                      {...register("mobileNumber", {
                        required: "Please enter mobile number",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Please enter only numbers",
                        },
                        minLength: {
                          value: 8,
                          message:
                            "Please enter minimum 8 & maximum 10 digits for mobile number",
                        },
                        maxLength: {
                          value: 10,
                          message:
                            "Please enter minimum 8 & maximum 10 digits for mobile number",
                        },
                      })}
                      autoComplete="off"
                    />
                  )}
                  <img
                    src="assets/images/icon/mobile.svg"
                    className="front_icon logo_1"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="mobile_div"
                onClick={() => handleOpenEmailMobileField("email")}
              >
                <img
                  src="assets/images/icon/sms.svg"
                  alt=""
                  className="logo_1"
                />
              </div>
            </div>
            <label
              className={`error mb-3 ${
                showEmailDiv === "mobile" ? "" : "d-none"
              }`}
              style={{ marginTop: "-0.9375rem" }}
            >
              {errors.countryCode != undefined
                ? errors.countryCode?.message
                : errors.mobileNumber?.message}
            </label>

            <div className="col-md-12 mb-3">
              <div className="form-group fixed_icon back_icon">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  {...register("password", {
                    required: "Please enter password",
                    minLength: 6,
                  })}
                  autoComplete="new-password"
                  placeholder="Password"
                />
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "assets/images/icon/lock.svg"
                  }
                  className="front_icon logo_1"
                />
                {showPassword ? (
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/eye.svg"
                    }
                    className="end_icon logo_1"
                    onClick={handleTogglePassword}
                  />
                ) : (
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/eye-slash.svg"
                    }
                    className="end_icon logo_1"
                    onClick={handleTogglePassword}
                  />
                )}
              </div>
              <label className="error">{errors.password?.message}</label>
            </div>
            <div className="col-md-12 text-end mb-3">
              <Link
                className="text-theme font-16"
                onClick={handleOpenModalForgotPassword}
              >
                Forgot Password?
              </Link>
            </div>
            <div className="col-md-12 mb-5">
              <button className="btn btn-theme mt-auto w-100">Log In</button>
            </div>

            <div className="col-md-12 text-cente  r mt-5 mb-3">
              <span className="text-theme font-16">
                Don’t have an account ?{" "}
                <Link className="fw-bold" onClick={handleOpenModalSignup}>
                  Sign Up
                </Link>
              </span>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
