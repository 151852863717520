import React from 'react';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AddCategory } from '../../utils/api.service';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/common.service';
import { useSelector } from "react-redux";
function Category({ show ,setShowModal }) {

  const { register, handleSubmit,setValue, formState: { errors } ,reset} = useForm();
 
  const handleClose = () => {
    setShowModal(false);
    // setValue('name','')
    reset()
  } 

  const darkMode = useSelector((state) => state.theme.themeValue);


  const buttonStyle = {
    backgroundColor: darkMode === 'theme-dark' ? 'white' : 'black',
    color: darkMode === 'theme-dark' ? 'black' : 'white',
    borderColor: darkMode === 'theme-dark' ? 'white' : 'black',
  };

  const onSubmit = (data) => {
    


    AddCategory(data).then(function (response) {
                
        if (response.code === '1') {
            TOAST_SUCCESS(response.message)
            setValue('name','')
            handleClose();
        } else {
            TOAST_ERROR(response.message);
        }
    })
  };

  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Category</Modal.Title>
        </Modal.Header>
        
        <form onSubmit={handleSubmit(onSubmit)}>
  <Modal.Body>
    <div className="form-group">
      <label htmlFor="category_name">Category Name</label>
      <input
        id="category_name"
        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
        {...register('name', { 
          required: 'Category Name is required', 
          pattern: {
            value: /^[^\d\p{Emoji}]*$/u, 
            message: 'Please enter valid category name'
          }
        })}
        placeholder="Enter category name"
      />
      {errors.name && (
        <div className="invalid-feedback">
          {errors.name.message}
        </div>
      )}
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button style={buttonStyle} type="submit">
      Submit
    </Button>
  </Modal.Footer>
</form>


      </Modal>
    </>
  );
}

export default Category;
