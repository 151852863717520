import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  removeAuthData,
  TOAST_SUCCESS,
  TOAST_ERROR,
  secondsToMMSS,
  timeToSeconds,
  storeAuthData,
  bodyDecryption,
} from "../utils/common.service";
import { signOut, getProfileDetail, couponPoints } from "../utils/api.service";
import { logoutUser } from "../store/slices/authUserSlice";
import { openModal } from "../store/slices/modalContactSlice";
import { openModal as editProfileModalOpen } from "../store/slices/editProfileSlice";
import moment from "moment";
import { openModal as openReferFriendModal } from "../store/slices/referFriendSlice";
import { useActiveTab } from "../components/common/ActiveTabContext";
import "./Custom.css";
import { loginUser } from "../../src/store/slices/authUserSlice";
import Cookies from "js-cookie";
import { setIsSearchShow } from "../store/slices/couponSearchSlice";
import { setThemeValue } from "../store/slices/themeSlice";

const SubHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginUserData = useSelector((state) => state.authUser.loginUserData);
  const isLoggedin = useSelector((state) => state.authUser.isLoggedin);
  const pathName = window.location.pathname;
  const [showGreetings, setShowGreetings] = useState(false);
  const [greetingText, setGreetingText] = useState("");
  const [timer, setTimer] = useState(0);
  const [totalReps, setTotalReps] = useState(0);
  const { activeTab } = useActiveTab();
  const [fiveActive, setFiveActive] = useState();
  const [sixActive, setSixActive] = useState();
  const [points, setPoints] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const handleFiveIntro = () => {
    setFiveActive("");
    setSixActive("active");
    localStorage.setItem("introstep", "six");
  };

  const searchToggle = useSelector(
    (state) => state.couponsearchtoggle.isSearchShow
  );

  const headerSearchToggle = useSelector(
    (state) => state.couponsearchtoggle.isHeaderSearchShow
  );

  const handleHide = () => {
    dispatch(setIsSearchShow(!searchToggle));
  };
  

  const handleSixIntro = () => {
    setSixActive("");
    localStorage.setItem("showintro", "no");
    localStorage.removeItem("introstep");
  };

  const updateGreeting = () => {
    const currentTime = moment();
    const hour = currentTime.hours();

    let newGreeting = "";

    if (hour >= 5 && hour < 12) {
      newGreeting = "Good morning!";
    } else if (hour >= 12 && hour < 17) {
      newGreeting = "Good afternoon!";
    } else {
      newGreeting = "Good evening!";
    }

    setGreetingText(newGreeting);
  };

  const updateTimer = () => {
    const timeInSeconds = timeToSeconds(loginUserData?.total_watching_time);
    setTimer(timeInSeconds);
  };

  const handleToggleTheme = (e) => {
    if (localStorage.getItem("theme") === "theme-dark") {
      localStorage.setItem("theme", "theme-light");
      dispatch(setThemeValue("theme-light"));
      document.documentElement.className = "theme-light";
    } else {
      localStorage.setItem("theme", "theme-dark");
      dispatch(setThemeValue("theme-dark"));
      document.documentElement.className = "theme-dark";
    }
  };

  const handleOpenModalContact = () => {
    dispatch(openModal());
  };

  const handleOpenReferFriendModal = () => {
    dispatch(openReferFriendModal());
  };

  const handleOpenModalEditProfile = () => {
    dispatch(editProfileModalOpen());
  };

  const handleSignout = () => {
    let payload = {};

    signOut(payload)
      .then(function (response) {
        if (response.code === "1") {
          removeAuthData();
          dispatch(logoutUser());
          localStorage.removeItem("reps_count");
          localStorage.removeItem("SocialProfileData");
          localStorage.removeItem("SocialLogin");
          // localStorage.clear();
          setTimeout(() => {
            TOAST_SUCCESS(response.message);
          }, 100);
          navigate("/");
        } else if (response.code !== "0") {
          TOAST_ERROR(response.message);
        }
      })
      .catch(function (error) {
        
      });
  };
  {
    /* <Link href="#" className="timer me-2 remover_mobile"><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/timer.svg"} className="logo_1" /><span>{secondsToMMSS(timer)}</span></Link> */
  }
  const timerRepsHtml = (
    <>
      <Link
        href="#"
        className={`view_ad me-2 loading-add2 ${fiveActive}`}
        id="intro-five"
      >
        {activeTab !== "coupons" ? (
          <span className="reps-count">{totalReps}</span>
        ) : (
          <span className="reps-count">{points}</span>
        )}
        {/* {loginUserData?.total_reps} */}
        <div className="intro-text">
          <h4>Reps</h4>
          <p>Check here for the points you have earned called as Reps.</p>
        </div>
      </Link>
      <div className={`overlay ${fiveActive}`} onClick={handleFiveIntro}></div>
    </>
  );

  const menuItemsHtml = (
    <>
      <Link
        onClick={handleOpenReferFriendModal}
        className={`ms-auto me-2 loading-add2 remover_mobile ${sixActive}`}
        id="intro-six"
      >
        <img
          src={
            process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/share.svg"
          }
          alt=""
          className="share-btn"
        />
        <div className="intro-text">
          <h4>Share</h4>
          <p>
            Save 10 minutes by sharing the app with your circle and earn a Rep
            for every successful signup!!
          </p>
        </div>
      </Link>
      <div className={`overlay ${sixActive}`} onClick={handleSixIntro}></div>

      <div className="color_icore me-2 remover_mobile">
        {/* <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/color-pic.svg"} alt="" /> */}
        <img
          src={
            process.env.REACT_APP_PUBLIC_URL +
            "assets/images/icon/color-picker.png"
          }
        />

        <label id="switch" className="switch hide_pic">
          <input type="checkbox" onChange={handleToggleTheme} id="slider" />
          <span className="slider round"></span>
        </label>
      </div>
      <Link to={"/notification"} className="me-2 notification remover_mobile">
        <img
          src={
            process.env.REACT_APP_PUBLIC_URL + "assets/images/notification.svg"
          }
          alt=""
          className="logo_1"
        />
      </Link>
      {/* For Search icon show hide event */}

      {activeTab === "coupons" && headerSearchToggle && (
        <Link
          to="#"
          className="me-2 notification remover_mobile"
          onClick={handleHide}
        >
          <img
            src={
              process.env.REACT_APP_PUBLIC_URL + "assets/images/search_new.svg"
            }
            alt=""
            className="logo_1"
          />
        </Link>
      )}
      <div className="dropdown user_account ">
        <Link
          to="#"
          className="dropdown-toggle"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src={loginUserData?.profile_image}
            alt=""
            width={40}
            style={{ borderRadius: "50%" }}
          />
        </Link>
        <ul
          className="dropdown-menu mobile_text"
          aria-labelledby="dropdownMenuLink"
        >
          <li>
            <div className="d-flex justify-content-center gap-5 py-2 mobile_responsive">
              <Link
                onClick={handleOpenReferFriendModal}
                className={`ms-auto me-2 `}
              >
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "assets/images/icon/share.svg"
                  }
                  alt=""
                  className="share-btn"
                />
              </Link>

              <div className="color_icore me-2">
                {/* <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/color-pic.svg"} alt="" /> */}
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "assets/images/icon/color-picker.png"
                  }
                />

                <label id="switch" className="switch hide_pic">
                  <input
                    type="checkbox"
                    onChange={handleToggleTheme}
                    id="slider"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <Link to={"/notification"} className="me-2 notification">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "assets/images/notification.svg"
                  }
                  alt=""
                  className="logo_1"
                />
              </Link>
            </div>
          </li>

          <li className="">
            <Link to="#" className="dropdown-item">
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL +
                  "assets/images/icon/timer.svg"
                }
                className="me-1"
              />
              <span>{secondsToMMSS(timer)}</span>
            </Link>
          </li>

          <li>
            <Link
              className="dropdown-item"
              onClick={handleOpenModalEditProfile}
            >
              Edit Profile
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/faqs"}>
              FAQs
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/terms-and-conditions"}>
              Terms & Conditions
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/privacy-policy"}>
              Privacy Policy
            </Link>
          </li>
          {/* <li><Link className="dropdown-item" to={'/about-us'}>About Us</Link></li> */}
          <li>
            <Link className="dropdown-item" to={"/contact-us"}>
              Contact Us
            </Link>
          </li>
          {/* <li><Link className="dropdown-item" onClick={handleOpenModalContact}>Contact Us</Link></li> */}
          <li>
            <Link
              className="dropdown-item"
              onClick={handleOpenReferFriendModal}
            >
              Refer a Friend
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" onClick={handleSignout}>
              Log Out
            </Link>
          </li>
        </ul>
      </div>
    </>
  );

  const fetchProfileDetail = () => {
    let payload = {};
    getProfileDetail(payload)
      .then(function (response) {
        
        if (response.code === "1") {
          let authgetUserData = Cookies.get("onestens-auth-user-data");
          authgetUserData = JSON.parse(bodyDecryption(authgetUserData));
          delete response.data.token;
          response.data.token = authgetUserData.token;
          const authUserData = storeAuthData(response.data);
          dispatch(loginUser(authUserData));

          setTotalReps(response.data.total_reps);
          const timeInSeconds = timeToSeconds(
            response.data?.total_watching_time
          );
          setTimer(timeInSeconds);
        } else if (response.code !== "0") {
          TOAST_ERROR(response.message);
        }
      })
      .catch(function (error) {
        
      });
  };
  const getCouponPoints = () => {
    let payload = {};
    couponPoints(payload)
      .then(function (response) {
        if (response.code === "1") {
          setPoints(response.data.total_points);
        } else if (response.code !== "0") {
          // TOAST_ERROR(response.message);
        }
      })
      .catch(function (error) {
        
      });
  };

  useEffect(() => {
    let pathArr = pathName.split("/");
    if (pathArr[pathArr.length - 1] === "dashboard") {
      setShowGreetings(true);
    } else {
      setShowGreetings(false);
    }
    updateGreeting();
    updateTimer();
  }, [pathName, loginUserData]);

  useEffect(() => {
    if (isLoggedin) {
      fetchProfileDetail();
      getCouponPoints();
    }

    const interval = setInterval(() => {
      if (isLoggedin) {
        fetchProfileDetail();
        getCouponPoints();
        updateTimer();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [isLoggedin, totalReps]);

  useEffect(() => {
    fetchProfileDetail();
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("showintro") === "yes" &&
      localStorage.getItem("introstep") === "five"
    ) {
      setFiveActive("active");
    } else {
      setFiveActive("");
    }
  }, [loginUserData]);

  return (
    <section className="header mt-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-3">
            <div
              className={`d-flex change_layout ${
                !showGreetings ? "align-items-center dashboard_mobile" : ""
              }`}
            >
              <Link to={"/dashboard"} className="logo_beta">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "assets/images/icon/mini-logo.svg"
                  }
                  className="logo_1 "
                />
                <span>(ɓetà)</span>
              </Link>
              {!showGreetings ? (
                <div className="ms-auto d-flex right_menu">
                  {timerRepsHtml}
                  {menuItemsHtml}
                </div>
              ) : (
                menuItemsHtml
              )}
            </div>
          </div>

          {showGreetings && (
            <div className="col-md-12 mb-3 slider_rutorial text-left d-flex flex-row align-items-center">
              <div className="d-flex flex-column me-auto">
                <h6 className="text-start mb-0">
                  Hello, {loginUserData?.first_name}
                </h6>
                <h4>{greetingText}</h4>
              </div>
              {timerRepsHtml}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SubHeader;
