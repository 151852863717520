import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/modalContactSlice';
import { contactUs } from '../../utils/api.service';
import { useForm } from 'react-hook-form';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';

const ContactModal = () => {

    const isModalOpen = useSelector((state) => state.modalContact.isModalOpen);
    const dispatch = useDispatch();

    const handleCloseModalContact = () => {
        dispatch(closeModal());
    };

    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        reset,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();

    const onSubmit = async data => {

        let payload = {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            subject: data.subject,
            description: data.description,
        }

        contactUs(payload).then(function (response) {
            if (response.code === '1') {
                handleCloseModalContact();
                TOAST_SUCCESS(response.message);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    };

    useEffect(() => {
        if (isModalOpen) {
            reset();
        }
    }, [isModalOpen])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" onClick={handleCloseModalContact}>X</button>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-12 mb-3 mt-3">
                            <div className="slider_rutorial text-center">
                                <h1>Contact Us</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group  w-100">
                                <input type="text" className="form-control" {...register("firstName", { required: "Please enter first name", pattern: { value: /^[a-zA-Z]*$/, message: "Please enter only letters (A-Z, a-z) with no special characters or numbers" }, maxLength: { value: 15, message: "First name must not exceed 15 characters" } })} autoComplete="off" placeholder="First Name" />
                            </div>
                            <label className="error">{errors.firstName?.message}</label>
                        </div>

                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group  w-100">
                                <input type="text" className="form-control" {...register("lastName", { required: "Please enter last name", pattern: { value: /^[a-zA-Z]*$/, message: "Please enter only letters (A-Z, a-z) with no special characters or numbers" }, maxLength: { value: 15, message: "Last name must not exceed 15 characters" } })} autoComplete="off" placeholder="Last Name" />
                            </div>
                            <label className="error">{errors.lastName?.message}</label>
                        </div>

                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group  w-100">
                                <input type="text" className="form-control" {...register("email", { required: "Please enter email address", pattern: { value: /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/, message: "Please enter a valid email address" } })} autoComplete="off" placeholder="Email Address" />
                            </div>
                            <label className="error">{errors.email?.message}</label>
                        </div>

                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group  w-100">
                                <input type="text" className="form-control" {...register("subject", { required: "Please enter subject", pattern: { value: /^[a-zA-Z0-9\s]*$/, message: "Please enter only letters, numbers & spaces with no special characters" }, maxLength: { value: 250, message: "Subject must not exceed 250 characters" } })} autoComplete="off" placeholder="Subject" />
                            </div>
                            <label className="error">{errors.subject?.message}</label>
                        </div>

                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group  w-100">
                                <textarea className="form-control" {...register("description", { required: "Please enter description", pattern: { value: /^[a-zA-Z0-9\s]*$/, message: "Please enter only letters, numbers & spaces with no special characters" }, maxLength: { value: 1000, message: "Description must not exceed 1000 characters" } })} autoComplete="off" placeholder="Description"></textarea>
                            </div>
                            <label className="error">{errors.description?.message}</label>
                        </div>

                        <div className="col-md-12 mb-2">
                            <button type="submit" className="btn btn-theme mt-auto w-100">Submit</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ContactModal;
