import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getWinList } from "../../utils/api.service";
import { TOAST_ERROR } from "../../utils/common.service";
import Layout from "../../layouts/Layout";
import { Link } from "react-router-dom";
import moment from "moment";

const Win = () => {

    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [loadMore, setLoadMore] = useState(true);

    const fetchGetWinList = () => {

        if (loadMore) {

            let payload = {
                page: page
            };
            getWinList(payload).then(function (response) {

                if (response.code === '1') {
                    const newData = response.data.result;
                    setData((prevData) => [...prevData, ...newData]);
                } else if (response.code === '2') {
                    setLoadMore(false);
                    // TOAST_ERROR(response.message);
                } else if (response.code !== '0') {
                    // TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }

    }

    useEffect(() => {
        fetchGetWinList()
    }, [page])

    const handleScroll = () => {
        if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | Win`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            <section className="winnig_section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center mb-5">
                            <b>"Disclaimer : Kudos are not for everyone, So get one REP a day."</b>
                        </div>

                        {(data.length > 0) ? data.map((item, index) => (
                            <div key={index} className="col-md-6 col-sm-12 col-12 mb-3">
                                <div className="winning_main flex-column">
                                    <div className="win_main">
                                        <div className="wini_img">
                                            <img src={item.image} alt="" />
                                        </div>
                                        <div className="win_detail">
                                            <div className="win_detail-btn-div">
                                                <h5>{item.tagline} </h5>
                                                <Link to="#" className="win_detail-rank-btn">
                                                    Rank {item.rank}
                                                </Link>
                                            </div>
                                            <label>Kudo Details :</label>
                                            <p>{item.prize_details}</p>
                                        </div>
                                    </div>
                                    <div className="w_detail">
                                        <p>{item.description}</p>
                                        <div className="w_detail_date">
                                            <div className="w_detail_date_left">
                                                <span className="date">{moment(item.start_date, 'DD/MM/YYYY').format('DD MMM YYYY')}</span>
                                                <span className="start_date">Start Date </span>
                                            </div>
                                            <div className="w_detail_date_right">
                                                <span className="date">{moment(item.end_date, 'DD/MM/YYYY').format('DD MMM YYYY')}</span>
                                                <span className="start_date">End Date </span>
                                            </div>
                                        </div>
                                      </div>
                                </div>
                            </div>
                        )) : <div className="col-12 mt-10 text-center">
                            <strong style={{ fontSize: '20px' }}>No Data Found</strong>
                        </div>}
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Win;