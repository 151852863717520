import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
    const auth = useAuth();

    const isLoggedin = useSelector((state) => state.authUser.isLoggedin);

    if (auth === null || auth.user === null) {
        if(isLoggedin) {
            return <Outlet />;
        }
        else{
            return <Navigate to={'/'} />;
        }
    }else{
        return <Outlet />;
    }
};

export default PrivateRoute;
