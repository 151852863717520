import React from 'react';
import './Loader.css'; 

const Loader = ({ isLoading }) => {
    return (
        <div className={`ajax-preloader ${isLoading ? 'visible' : ''}`}>
            <div className="lds-ellipsis">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
};

export default Loader;