import { createSlice } from "@reduxjs/toolkit";

const ModalLoginSlice = createSlice({
    name: 'modalLogin',
    initialState: {
        isModalOpen: false,
    },
    reducers: {
        openModal: (state) => {
            state.isModalOpen = true;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = ModalLoginSlice.actions;
export default ModalLoginSlice.reducer;