import React, { useEffect, useRef } from "react";
import styles from "./Home.module.css";
import { TypeAnimation } from 'react-type-animation';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch } from 'react-redux';
import { openModal } from "../../store/slices/modalTutorialSlice";
import Layout from "../../layouts/Layout";
import Cookies from "js-cookie";
import { openModal as openSignupModal } from "../../store/slices/modalSignupSlice";
import { openModal as openLoginModal } from "../../store/slices/modalLoginSlice";
import { Link } from "react-router-dom";

const Home = () => {

    const serviceDivRef = useRef();
    const howItWorksDivRef = useRef();
    const downloadAppDivRef = useRef();

    const dispatch = useDispatch();
    const authUserData = Cookies.get('onestens-auth-user-data');

    const handleOpenModalSignup = () => {
        dispatch(openSignupModal());
    };

    const handleOpenModalLogin = () => {
        dispatch(openLoginModal());
    };

    useEffect(() => {
       

        let sectionId = localStorage.getItem('homeSection');
        if (sectionId !== undefined && sectionId !== '') {
            if (sectionId === '#services') {
                serviceDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            else if (sectionId === '#how_it_works') {
                howItWorksDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            else if (sectionId === '#download_app') {
                downloadAppDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
        localStorage.removeItem('homeSection');
    }, [dispatch])

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | HOME`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            <section className="banner3" id="home_section">
                <div className="container custom-container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div className="banner3__content">
                                <h1 className="title wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">watch ads and win KUDOS AND
                                    <br className={styles.brhide} />
                                    <TypeAnimation
                                        className={styles.typer}
                                        preRenderFirstString={true}
                                        sequence={['', 500, 'COUPON!', 2000, '', 500]}
                                        repeat={Infinity}
                                    />
                                </h1>
                                <p className=" wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="300ms">"Watch ads and earn Gifts ! Get Discounts for your time and attention."</p>
                                {/* <form className="form-box wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="600ms" action="#" method="#">
                                    <div className="row">
                                        <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                                            <div className="app_img d-flex">
                                                <a href="#" className="app_store"><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/store-apple@2x.jpg"} /></a>
                                                <a href="#" className="play_store"><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/store-play@2x.jpg"} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </form> */}
                                <div className="top-title-btn text-center text-center ">
                                    <a className="btn btn-2 me-2" onClick={handleOpenModalLogin}>Login</a>
                                    <a className="btn btn-3" onClick={handleOpenModalSignup}>Register</a>
                                </div>
                                <div className="trust-user wow fadeInUp d-flex flex-column align-items-start" data-wow-duration="1500ms" data-wow-delay="900ms">
                                    <span><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/trust-icon.png"} alt="" />Trusted users around India </span>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/users-img.png"} alt="" />
                                    
                                    <form className="form-box wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="600ms" action="#" method="#">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                                                <div className="app_img d-flex">
                                                    <a href="#" className="app_store"><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/store-apple@2x.jpg"} /></a>
                                                    <a href="#" className="play_store"><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/store-play@2x.jpg"} /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-12 mt-5 mt-md-0">
                            <div className=" wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="1200ms">
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/banner-thumb-4.png"} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="features3" id="services" ref={serviceDivRef}>
                <div className="container custom-container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="top-title text-center">
                                <h3 className="title">Some excellent <span>Services</span> for you</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                            <div className="features3__item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="icon icon-1">

                                </div>
                                <h4 className="title">Watch</h4>
                                <p>Click watch icon to watch ads and enroll into program, Watch 10 minutes to earn a rep, Earn multiple reps to get higher chance of winning. </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                            <div className="features3__item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <div className="icon icon-2">

                                </div>
                                <h4 className="title">Win</h4>
                                <p>Click win icon to know the prize details and announcement date.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                            <div className="features3__item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                                <div className="icon icon-3">

                                </div>
                                <h4 className="title">Winner</h4>
                                <p>Click winner icon to check out winners and winners can comment in it.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                            <div className="features3__item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1000ms">
                                <div className="icon icon-4">

                                </div>
                                <h4 className="title">Winning</h4>
                                <p>Click winning icon to checkout personal winning and claim it.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="how-work-2" id="how_it_works" ref={howItWorksDivRef}>
                <div className="container custom-container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="top-title text-center">
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/work-title-shape.png"} alt="" />
                                <h3 className="title">How its <span>works</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="how-work-3-item wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="work-arrow">
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/work-item-arrow.png"} alt="" />
                                </div>
                                <span>1</span>
                                <h4 className="title">Watch </h4>
                                <p>Earning money has never been easier, just spare  few minutes of your time and watch ads.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="how-work-3-item wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="300ms">
                                <div className="work-arrow">
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/work-item-arrow.png"} alt="" />
                                </div>
                                <span>2</span>
                                <h4 className="title">Win</h4>
                                <p>Get to know the exciting kudos details and announcement date.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="how-work-3-item wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <span>3</span>
                                <h4 className="title">Winner</h4>
                                <p> Lucky users will be selected as winners for kudos and all users with reps gain acess to coupon.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service3">
                <div className="container custom-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="service3__thumb wow fadeInLeft text-center" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/service-thumb-3.png"} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="service3__content wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="top-title">
                                    <h3 className="title">Onestens Ads is a simple ad <span>streaming platform</span> to earn free money Through discounts.</h3>

                                </div>
                                <div className="service3-content-box">
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/features-icon-1.png"} alt="" />
                                    <h4 className="title">AI </h4>
                                    <p>Industry leading face recognition systems are used to sense the users.</p>
                                </div>
                                <div className="service3-content-box">
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/features-icon-3.png"} alt="" />
                                    <h4 className="title">Algorithm</h4>
                                    <p>We have designed our winner algorithm in a way, so that every user gets a chance to win kudos in every possibilities.</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="service3">
                <div className="container custom-container">

                    <div className="row item-2 align-items-center">
                        <div className="col-lg-5">
                            <div className="service3__content wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="top-title">
                                    <h3 className="title">Christmas every week at <span>onestens</span></h3>

                                    <p>Christmas once a year is an old saying, meet Santa every week may be  in the near future everyday so that you can get kudos.</p>
                                    <p>Time plays a significant role in our life, Whether time is real its an big debate. Here at onestens get kudos for your time as money!</p>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="service3__thumb wow fadeInRight text-center" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/service-thumb-4.png"} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="cta cta2 cta3" id="download_app" ref={downloadAppDivRef}>
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="cta__content-box wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <h3 className="title">Download onestens and win some exciting kudos <span>every week.</span></h3>

                                <div className="download-box">
                                    <span>Download Now On :</span>
                                    <ul className="list">
                                        <li className="item">
                                        <Link to="#">
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/store-1.png"} alt="" />
                                                </Link>
                                        </li>
                                        <li className="item">
                                            <Link to="#">
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/store-2.png"} alt="" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cta__thumb text-center text-lg-end wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/cta-mobile-3.png"} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Home;