import React, { useEffect } from "react";
import Aux from "../hoc/Auxiliary/Auxiliary";
import Header from "./Header";
import SubHeader from "./SubHeader";
import Footer from "./Footer";

import TutorialModal from "../components/modals/TutorialModal";
import LoginModal from "../components/modals/LoginModal";
import ContactModal from "../components/modals/ContactModal";
import SignupModal from "../components/modals/SignupModal";
import OTPModal from "../components/modals/OTPModal";
import EditProfileModal from "../components/modals/EditProfileModal";
import ForgotPasswordModal from "../components/modals/ForgotPasswordModal";
import ResetPasswordModal from "../components/modals/ResetPassword";
import ReferFriendModal from "../components/modals/ReferFriendModal";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Cookies from 'js-cookie';
import { loginUser, logoutUser } from '../store/slices/authUserSlice';
import { useSelector, useDispatch } from 'react-redux';
import { bodyDecryption } from "../utils/common.service";

const Layout = (props) => {

    const authUserData = Cookies.get('onestens-auth-user-data');
    const isLoggedin = useSelector((state) => state.authUser.isLoggedin);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(authUserData!=undefined && authUserData!=null){
            dispatch(loginUser(JSON.parse(bodyDecryption(authUserData))));
        }else{
            dispatch(logoutUser());
        }
    },[authUserData])

    return (
        <Aux>
            {(isLoggedin) ? <SubHeader /> : <Header />}
            {props.children}
            {(isLoggedin) ? '' : <Footer />}

            {(isLoggedin) ? <EditProfileModal /> : <SignupModal />}
            <TutorialModal />
            <LoginModal />
            <ContactModal />
            <OTPModal />
            <ForgotPasswordModal />
            <ResetPasswordModal />
            <ReferFriendModal />

            <ToastContainer autoClose={1000} />
        </Aux>
    )
}

export default Layout;