import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../AuthProvider";

const PublicRoute = () => {
    const auth = useAuth();

    if (auth !== null && auth.user !== null) return <Navigate to={'dashboard'} />;
    return <Outlet />;
};

export default PublicRoute;
