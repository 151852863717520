import { createSlice } from "@reduxjs/toolkit";

const CouponSerchSlice = createSlice({
  name: "coupon_search",
  initialState: {
    isSearchShow: false,
    isHeaderSearchShow: true,
  },
  reducers: {
    setIsSearchShow: (state, props) => {
      
    
      state.isSearchShow = props.payload;
    },
    setIsHeaderSearchShow: (state, props) => {
    
      state.isHeaderSearchShow = props.payload;
    },
  },
});

export const { setIsSearchShow, setIsHeaderSearchShow } =
  CouponSerchSlice.actions;
export default CouponSerchSlice.reducer;
