import { createSlice } from "@reduxjs/toolkit";

const ModalcommentSlice = createSlice({
    name: 'modalcomment',
    initialState: {
        isModalOpen: false,
        props: null,
    },
    reducers: {
        openModal: (state, props) => {
            
            
            state.isModalOpen = true;
            state.props = props;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = ModalcommentSlice.actions;
export default ModalcommentSlice.reducer;