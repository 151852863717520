import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { ReportCoupon } from '../../utils/api.service';
import { useForm } from 'react-hook-form';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';

const ReportCouponModal = ({handleCloseReportModal}) => {

    const isModalOpen = useSelector((state) => state.reportCoupon.isModalOpen);

    const couponData = useSelector((state) => state.reportCoupon.props != null ? state.reportCoupon.props.payload.couponData : null);
    const couponId = useSelector((state) => state.reportCoupon.props != null ? state.reportCoupon.props.payload.couponId : 0);
    const dispatch = useDispatch();

  

    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        reset,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();

    const onSubmit = async data => {

        let payload = {
            coupon_id: couponId,
            coupon_code_id: couponData.coupon_code_id,
            reason:data.reason
        }

        ReportCoupon(payload).then(function (response) {
            if (response.code === '1') {
                handleCloseReportModal(1);
                TOAST_SUCCESS(response.message);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    };

    useEffect(() => {
        if (isModalOpen) {
            reset();
        }
    }, [isModalOpen])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" onClick={handleCloseReportModal}>X</button>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-12 mb-3 mt-3">
                            <div className="slider_rutorial text-center">
                                <h1>Report Coupon</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group  w-100">
                                <textarea className="form-control" {...register("reason", { required: "Please enter reason", maxLength: { value: 1000, message: "reason must not exceed 1000 characters" } })} autoComplete="off" placeholder="Enter Reason here"></textarea>
                            </div>
                            <label className="error">{errors.reason?.message}</label>
                        </div>

                        <div className="col-md-12 mb-2">
                            <button type="submit" className="btn btn-theme mt-auto w-100">Submit</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ReportCouponModal;
