import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/commentSlice';
import { postComment } from '../../utils/api.service';
import { useForm } from 'react-hook-form';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';
import { openModal as openClaimModal} from "../../store/slices/claimSlice";
import ClaimDetailModal from "../../components/modals/ClaimDetailModal";

const CommentModal = () => {

    const isModalOpen = useSelector((state) => state.modalComment.isModalOpen);
    
    const campaignId = useSelector((state) => state.modalComment.props != null ? state.modalComment.props.payload.campaignId : null);
    const giftId = useSelector((state) => state.modalComment.props != null ? state.modalComment.props.payload.GiftId : null);
    const giftType = useSelector((state) => state.modalComment.props != null ? state.modalComment.props.payload.GiftType : null);
    const addComment = useSelector((state) => state.modalComment.props != null ? state.modalComment.props.payload.addComment : null);
    const loginUserData = useSelector((state) => state.authUser.loginUserData);
    const dispatch = useDispatch();

    const [isClaimed, setIsClaimed] = useState(0);
    
    const isWinningPageRedirect = localStorage.getItem('isWinningPageRedirect')

    const handleIsClaimedLabel = () => {
        setIsClaimed(1);
    };

    const handleCloseCommentModal = () => {
        dispatch(closeModal());
    };


    const handleOpenClaimModal = () => {
    
        dispatch(openClaimModal({gift_type: giftType, campaign_id: campaignId, gift_id: giftId,isWinningPageRedirect :true, handleIsClaimedLabel: handleIsClaimedLabel}))
        localStorage.removeItem('isWinningPageRedirect')
    }


    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        reset,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();

    const onSubmit = async data => {
        
        let payload = {
            comment: data.comment,
            campaign_id: campaignId,
        }
        
        postComment(payload).then(function (response) {
            if (response.code === '1') {
                handleCloseCommentModal();
                
                if(response.data !== null){
                    response.data.profile_image_url = loginUserData?.profile_image;
                    response.data.user_name = loginUserData?.first_name + ' ' +loginUserData?.last_name;
                    response.data.user_type = "U";
                    response.data.image = null;
                    response.data.parent_id = 0;
                    response.data.is_like = 0;
                    // let pushComment = JSON.parse(addComment);
                    // pushComment(response.data)
                    addComment(response.data);
                }

                TOAST_SUCCESS(response.message);
           
              
                if(isWinningPageRedirect === 'true'){
                    setTimeout(() => {

                        handleOpenClaimModal();
                    }, 2000);
                }
               
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    };

    useEffect(() => {
        if (isModalOpen) {
            reset();
        }
    }, [isModalOpen])

    return (

        <> 
        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" onClick={handleCloseCommentModal}>X</button>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-12 mb-3 mt-3">
                            <div className="slider_rutorial text-center">
                                <h1>Comment</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group  w-100">
                                <textarea className="form-control" {...register("comment", { required: "Please enter comment", pattern: { value: /^[a-zA-Z0-9. \n\uD83C-\uDBFF\uDC00-\uDFFF]*$/, message: "Please enter only letters, numbers, emojis & spaces with no special characters" }, maxLength: { value: 1000, message: "Comment must not exceed 1000 characters" } })} autoComplete="off" placeholder="Comment"></textarea>
                            </div>
                            <label className="error">{errors.comment?.message}</label>
                        </div>

                        <div className="col-md-12 mb-2">
                            <button type="submit" className="btn btn-theme mt-auto w-100">Post</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
        <ClaimDetailModal />
        </>
    );
};

export default CommentModal;
