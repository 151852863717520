import { createSlice } from "@reduxjs/toolkit";

const ModalcreateCouponSliceSlice = createSlice({
    name: 'modalCreateCoupon',
    initialState: {
        isModalOpen: false,
        payload: null,
    },
    reducers: {
        openModal: (state, action) => {
            state.isModalOpen = true;
            state.payload = action.payload;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = ModalcreateCouponSliceSlice.actions;
export default ModalcreateCouponSliceSlice.reducer;