import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { notificationList } from "../../utils/api.service";
import { TOAST_ERROR } from "../../utils/common.service";
import moment from "moment";
import Layout from "../../layouts/Layout";
import ListingItem from "./ListingItem";

const Notification = () => {

    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    const [loadMore, setLoadMore] = useState(true);

    const fetchNotificationList = () => {

        if (loadMore) {

            let payload = {
                page: page
            };
            notificationList(payload).then(function (response) {

                if (response.code === '1') {
                    const newData = response.data.result;
                    setData((prevData) => [...prevData, ...newData]);
                } else if (response.code === '2') {
                    setLoadMore(false);
                    TOAST_ERROR(response.message);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }

    }

    const handleGroupedData = () => {
        const listElements = [];

        if (Object.keys(groupedData).length === 0) {
            listElements.push(<div key={'1'} className="col-12 mt-10 text-center">
                <strong style={{ fontSize: '20px' }}>No Data Found</strong>
            </div>);
        } else {
            Object.entries(groupedData).forEach(([key, value]) => {

                listElements.push(<div key={key} className="col-md-12 col-sm-12 col-12 mb-3">
                    <div className="lable-date-div">
                        <label htmlFor="date" className="notification-date">
                            {(moment(key).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) ? 'Today'
                                : (moment(key).format('YYYY-MM-DD') == moment().subtract(1, 'days').format('YYYY-MM-DD')) ? 'Yesterday'
                                    : moment(key).format('DD MMM YYYY')}
                        </label>
                    </div>
                </div>)

                if (value.length > 0) {
                    value.forEach((item, index) => (
                        listElements.push(<ListingItem key={key + '-' + index} item={item} />)
                    ));
                }
            });
        }

        return listElements;
    }


    useEffect(() => {
        const groupedDataByDate = data.reduce((acc, obj) => {
            const date = moment.unix(obj.insert_datetime).format('YYYY-MM-DD');
            acc[date] = acc[date] || [];
            acc[date].push(obj);
            return acc;
        }, {});

        setGroupedData(groupedDataByDate)
    }, [data])

    useEffect(() => {
        fetchNotificationList()
    }, [page])

    const handleScroll = () => {
        if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | Notification`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            <section className="winnig_section">

                <div className="container">
                    <div className="row">

                        {handleGroupedData()}

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Notification;