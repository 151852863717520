import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import moment from 'moment';
import Cookies from 'js-cookie';

const KEY = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY_256);
const IV = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV);

export const bodyEncryption = (request, isStringify) => {
    var requestData = (isStringify) ? JSON.stringify(request) : request;
    var encrypted = CryptoJS.AES.encrypt(requestData, KEY, { iv: IV });
    return encrypted.toString();
}

export const bodyDecryption = (response) => {
    var decrypted = CryptoJS.AES.decrypt(response.toString(), KEY, { iv: IV });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

// Sweet Alert Messages
export const SWAL_ALERT = (message,html=null, status, isConfirm = false, confirmTitle = null, redirectUrl = null) => {
    let title = 'Success!';
    let icon = 'success';
    
    switch (status) {
        case 'success':
            icon = 'success';
            title = 'Success!';
            break;

        case 'error':
            icon = 'error';
            title = 'Error!';
            break;

        case 'warning':
            icon = 'warning';
            title = 'Warning!';
            break;

        case 'info':
            icon = 'info';
            title = 'Info!';
            break;

        default:
            break;
    }

    if (confirmTitle !== null) {
        title = confirmTitle;
    }

    return Swal.fire({
        icon: icon,
        title: title,
        text: message,
        html,html,
        type: (status !== undefined && status === 'error') ? 'error' : 'success',
        confirmButtonText: 'OK',
        timer: (isConfirm) ? false : 1500,
        showCancelButton: (isConfirm) ? ((redirectUrl !== null) ? false : true) : false,
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed) {
            if(redirectUrl !== null){
                window.location.href = redirectUrl;
            }
        }
    });
}

export const SWAL_ALERT_OK = (message, html = null, status, isConfirm = false, confirmTitle = null, redirectUrl = null) => {
    let title = 'Success!';
    let icon = 'success';

    switch (status) {
        case 'success':
            icon = 'success';
            title = 'Success!';
            break;

        case 'error':
            icon = 'error';
            title = 'Error!';
            break;

        case 'warning':
            icon = 'warning';
            title = 'Warning!';
            break;

        case 'info':
            icon = 'info';
            title = 'Info!';
            break;

        default:
            break;
    }

    if (confirmTitle !== null) {
        title = confirmTitle;
    }

    return Swal.fire({
        icon: icon,
        title: title,
        text: message,
        html: html,
        type: (status !== undefined && status === 'error') ? 'error' : 'success',
        confirmButtonText: 'OK',
        timer: (isConfirm) ? false : 4000,
        showCancelButton: false,
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed) {
            if (redirectUrl !== null) {
                window.location.href = redirectUrl;
            }
        }
    });
}



// Toast Alert Messages
export const TOAST_INFO = (message) => {
    return toast.info(message);
};

export const TOAST_SUCCESS = (message) => {
    return toast.success(message);
};

export const TOAST_ERROR = (message) => {
    return toast.error(message);
};

export const TOAST_WARNING = (message) => {
    return toast.warning(message);
};

function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
}

function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'jpg':
            return true;
        case 'jpeg':
            return true;
        case 'png':
            return true;
        default:
            break;
    }
    return false;
}

function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'mkv':
            return true;
        case 'mp4':
            return true;
        case 'mpeg':
            return true;
        default:
            break;
    }
    return false;
}

function isExcel(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'xlsx':
            return true;
        case 'csv':
            return true;
        default:
            break;
    }
    return false;
}

export const validateFile = (filename, type) => {
    if (type === 'image') {
        if (isImage(filename)) {
            return true;
        } else {
            return false;
        }
    } else if (type === 'video') {
        if (isVideo(filename)) {
            return true;
        } else {
            return false;
        }
    } else if (type === 'excel') {
        if (isExcel(filename)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const niceDatetimeFromUnixTimestamp = (unix_timestamp) => {
    return moment(unix_timestamp, 'X').format('DD MMM YYYY, h:mm A');
}

export function removeAuthData() {
    Cookies.remove('onestens-auth-user-data');
}

export function storeAuthData(authData) {
    // localStorage.setItem('theme', 'theme-light');
    // localStorage.setItem('showintro', 'yes');

    let authUserData = {
        id: authData.id,
        token: authData.token,
        first_name: authData.first_name,
        last_name: authData.last_name,
        mobile_number: authData.mobile_number,
        email: authData.email,
        country_code: authData.country_code,
        total_watching_time: authData.total_watching_time,
        total_reps: authData.total_reps,
        profile_image: authData.profile_image,
        my_referral_code: authData.my_referral_code,
        introstep: 'first'
    };

    let encryptedUserData = bodyEncryption(authUserData, true);
    
    Cookies.set('onestens-auth-user-data', encryptedUserData, { expires: 7 });
    
    return authUserData;
}

export function secondsToMMSS(seconds) {
    // Ensure seconds is a non-negative number
    seconds = Math.max(0, seconds);

    // Calculate minutes and remaining seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Convert to "mm:ss" format
    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

    return formattedTime;
}

export function secondsToHMS(seconds) {
    // Ensure seconds is a non-negative number
    seconds = Math.max(0, seconds);

    // Calculate hours, minutes, and remaining seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Convert to "hh:mm:ss" format
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

    return formattedTime;
}

export function timeToSeconds(time) {
    // Split the time string into hours, minutes, and seconds
    if (!time) return 0;

    const [hours, minutes, seconds] = time.split(':').map(Number);

    // Calculate total seconds
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    return totalSeconds;
}
