import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import { openModal } from "../../store/slices/watchSlice";
import { useDispatch, useSelector } from "react-redux";
import WatchModal from "../../components/modals/WatchModal";
import Layout from "../../layouts/Layout";
import { checkUserSusppended } from "../../utils/api.service";
import { TOAST_ERROR, SWAL_ALERT } from "../../utils/common.service";
import './Dashboard.css';
import { loginUser } from "../../store/slices/authUserSlice";

const Dashboard = () => {

    const dispatch = useDispatch();

    const handleOpenWatchModal = async () => {
        let payload = {};
        
        let defaultWebcam;
        let cameraId;
        try {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            defaultWebcam = mediaDevices.find((device) => device.kind === 'videoinput');
            cameraId = defaultWebcam ? defaultWebcam.deviceId : undefined;
        } catch (error) {
            
            cameraId = undefined;
        }

        let currentCount = parseInt(localStorage.getItem('reps_count'));

        // Check if the value is less than 2
        if (currentCount < 2) {
            if (cameraId === undefined || cameraId === '' || cameraId === null) {
                // Request permission to use the camera
                try {
                    await navigator.mediaDevices.getUserMedia({ video: true });
                } catch (error) {
                    TOAST_ERROR("Please enable your device's camera.");
                    return; // Stop further execution if camera permission is denied
                }
            }

            checkUserSusppended(payload).then(function (response) {
                if (response.code === '1') {
                    dispatch(openModal());
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        } else {
            SWAL_ALERT('To continue, please download the application', '<div style="margin-bottom: 10px;">To continue, please download the application available in <div class="image-container" style="display: inline-block; margin: 10px;"><a href="#" class="app_store"><img src="' + process.env.REACT_APP_PUBLIC_URL + 'assets/images/store-apple@2x.jpg" style="width: 150px; display: block;" /></a></div><div class="image-container" style="display: inline-block;"><a href="#" class="play_store"><img src="' + process.env.REACT_APP_PUBLIC_URL + 'assets/images/store-play@2x.jpg" style="width: 150px; display: block;" /></a></div></div>', 'warning', true, null, '');
        }


    }

    const [animateStop, setAnimateStop] = useState(localStorage.getItem('showintro') === 'yes' ? true : false);
    const [firstActive, setFirstActive] = useState('');
    const [secondActive, setSecondActive] = useState('');
    const [thirdActive, setThirdActive] = useState('');
    const [forthActive, setForthActive] = useState('');
    let loginUserData = useSelector((state) => state.authUser.loginUserData);

    const handleFirstIntro = () => {
        setFirstActive('');
        setSecondActive('active');
        localStorage.setItem('introstep', 'second');
    }
    const handleSecondIntro = () => {
        setSecondActive('');
        setThirdActive('active');
        localStorage.setItem('introstep', 'third');
    }
    const handleThirdIntro = () => {
        setThirdActive('');
        setForthActive('active');
        localStorage.setItem('introstep', 'forth');
    }
    const handleForthIntro = () => {
        setForthActive('');
        localStorage.setItem('introstep', 'five');

        loginUserData = {
            ...loginUserData,
            introstep: 'five'
        };
        dispatch(loginUser(loginUserData));
    }

    useEffect(() => {

        if (localStorage.getItem('showintro') === 'yes') {
            setFirstActive('active');
            localStorage.setItem('introstep', 'first');
        } else {
            setFirstActive('');
        }

    }, []);

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | ${process.env.REACT_APP_DASHBOARD_TITLE}`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            <section className="features3 dashboard_main dashboard_main_1">
                <div className="container ">

                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3" id="intro-first">
                            <Link className={`loading-add ${firstActive} features3__item wow fadeInUp w-100`} data-wow-duration={animateStop ? '0ms' : '1500ms'} data-wow-delay="0ms" onClick={handleOpenWatchModal}>
                                <div className="icon icon-1">
                                </div>
                                <h4 className="title">Watch</h4>

                                <div className="intro-text">
                                    <h4>Watch</h4>
                                    <p>
                                        Click watch icon to watch ads for ten minutes to earn a Rep in our reward program.
                                    </p>
                                    <p>
                                        Insight!! End up in the top of list to definitely win kudos else randomly, but anyway you will get coupons.
                                    </p>
                                </div>
                            </Link>
                            <div className={`overlay ${firstActive}`} onClick={handleFirstIntro}></div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3" id="intro-second">
                            <Link to={'/win'} className={`loading-add ${secondActive} features3__item wow fadeInUp w-100`} data-wow-duration={animateStop ? '0ms' : '1500ms'} data-wow-delay={animateStop ? '0ms' : '400ms'}>
                                <div className="icon icon-2">
                                </div>
                                <h4 className="title">Win</h4>

                                <div className="intro-text">
                                    <h4>Ad Rewards</h4>
                                    <p>
                                        Click win icon to know Prize details and to access Coupons.
                                    </p>
                                </div>
                            </Link>
                            <div className={`overlay ${secondActive}`} onClick={handleSecondIntro}></div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3" id="intro-third">
                            <Link to={'/winner'} className={`loading-add ${thirdActive} features3__item wow fadeInUp w-100`} data-wow-duration={animateStop ? '0ms' : '1500ms'} data-wow-delay={animateStop ? '0ms' : '800ms'}>
                                <div className="icon icon-3">
                                </div>
                                <h4 className="title">Winner</h4>

                                <div className="intro-text">
                                    <h4>Winner info</h4>
                                    <p>
                                        Click winner icon to check out winners and winners can comment in it.
                                    </p>
                                </div>
                            </Link>
                            <div className={`overlay ${thirdActive}`} onClick={handleThirdIntro}></div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3" id="intro-forth">
                            <Link to={'/winning'} className={`loading-add ${forthActive} features3__item wow fadeInUp w-100`} data-wow-duration={animateStop ? '0ms' : '1500ms'} data-wow-delay={animateStop ? '0ms' : '1000ms'}>
                                <div className="icon icon-4">
                                </div>
                                <h4 className="title">Winning</h4>

                                <div className="intro-text">
                                    <h4>Winning Details</h4>
                                    <p>
                                        Click winning icon to checkout personal winning and claim it.
                                    </p>
                                </div>
                            </Link>
                            <div className={`overlay ${forthActive}`} onClick={handleForthIntro}></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ads ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-sm-6 col-6 mb-3 mb-sm-0">
                            <Link to={'/manage-advertisement'}>
                                <h4>{'Click here to advertise >>'}</h4>
                            </Link>
                        </div>
                        <div className="col-md-4 col-sm-6 col-6 mb-3 mb-sm-0 text-end">
                            <Link to={'/manage-advertisement'}>
                                <div className="icon ads ms-auto me-0">
                                </div>
                                <h4 className="me-5">Ads</h4>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <WatchModal />
        </Layout>
    )
}

export default Dashboard;