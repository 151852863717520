
import AxiosClient from "./axios.service";
import { bodyEncryption } from './common.service';

export function getPages(payload){
    return AxiosClient.post('/common/get-content-pages', bodyEncryption(payload, true));
}
export function getFAQs(payload){
    return AxiosClient.post('/common/get-faqs', bodyEncryption(payload, true));
}
export function getStates(payload){
    return AxiosClient.post('/common/get-state-list', bodyEncryption(payload, true));
}
export function getCities(payload){
    return AxiosClient.post('/common/get-city-list', bodyEncryption(payload, true));
}
export function checkUnique(payload){
    return AxiosClient.post('/auth/check-unique', bodyEncryption(payload, true));
}
export function sendOTP(payload){
    return AxiosClient.post('/auth/send-otp', bodyEncryption(payload, true));
}
export function verifyOTP(payload){
    return AxiosClient.post('/auth/verify-otp', bodyEncryption(payload, true));
}
export function signUp(payload){
    return AxiosClient.post('/auth/signup', bodyEncryption(payload, true));
}
export function signIn(payload){
    return AxiosClient.post('/auth/signin', bodyEncryption(payload, true));
}
export function signOut(payload){
    return AxiosClient.post('/auth/signout', bodyEncryption(payload, true));
}
export function contactUs(payload){
    return AxiosClient.post('/auth/contact-us', bodyEncryption(payload, true));
}
export function notificationList(payload){
    return AxiosClient.post('/auth/notificationList', bodyEncryption(payload, true));
}
export function getProfileDetail(payload){
    return AxiosClient.post('/auth/get-profile', bodyEncryption(payload, true));
}
export function editCheckUnique(payload){
    return AxiosClient.post('/auth/edit-check-unique', bodyEncryption(payload, true));
}
export function editProfile(payload){
    return AxiosClient.post('/auth/edit-profile', bodyEncryption(payload, true));
}
export function manageAdsList(payload){
    return AxiosClient.post('/watch/manage-advertisement', bodyEncryption(payload, true));
}
export function getWinList(payload){
    return AxiosClient.post('/win/get-win-list', bodyEncryption(payload, true));
}
export function getWinningList(payload){
    return AxiosClient.post('/winner/get-winning', bodyEncryption(payload, true));
}
export function forgotPassword(payload){
    return AxiosClient.post('/auth/forgot-password', bodyEncryption(payload, true));
}
export function resetPassword(payload){
    return AxiosClient.post('/auth/reset-password', bodyEncryption(payload, true));
}
export function getWinnerList(payload){
    return AxiosClient.post('/watch/get-winner', bodyEncryption(payload, true));
}
export function getCommentList(payload){
    return AxiosClient.post('/watch/get-comment', bodyEncryption(payload, true));
}
export function likeUnlikeComment(payload){
    return AxiosClient.post('/watch/like-unlike-comment', bodyEncryption(payload, true));
}
export function postComment(payload){
    return AxiosClient.post('/watch/post-comment', bodyEncryption(payload, true));
}
export function claimGift(payload){
    return AxiosClient.post('/winner/claim-gift', bodyEncryption(payload, true));
}
export function checkWinnerUser(payload){
    return AxiosClient.post('/watch/check-winner-user', bodyEncryption(payload, true));
}

export function getAdvertisement(payload){
    return AxiosClient.post('/watch/get-advertisement', bodyEncryption(payload, true));
}
export function updateWatchReps(payload){
    return AxiosClient.post('/watch/update-watch-resp', bodyEncryption(payload, true));
}
export function checkUserSusppended(payload){
    return AxiosClient.post('/watch/check-user-suspended', bodyEncryption(payload, true));
}

export function activateDeactivateAd(payload){
    return AxiosClient.post('/watch/enable-disable-ad', bodyEncryption(payload, true));
}
export function adImpression(payload){
    return AxiosClient.post('/watch/ad-impression', bodyEncryption(payload, true));
}
export function adsUrlHit(payload){
    return AxiosClient.post('/watch/add-hit', bodyEncryption(payload, true));
}

export function adsCategoryList(payload){
    return AxiosClient.post('/watch/get-ad-category', bodyEncryption(payload, true));
}
export function adsPriceList(payload){
    return AxiosClient.post('/watch/get-ad-price', bodyEncryption(payload, true));
}

export function likeUnlikeAd(payload){
    return AxiosClient.post('/watch/like-unlike-ad', bodyEncryption(payload, true));
}

export function createPaymentIntent(payload){
    return AxiosClient.post('/common/create-payment-intent', bodyEncryption(payload, true));
}

export function couponCategoryList(payload){
    return AxiosClient.post('/coupons/get-coupons-categories', bodyEncryption(payload, true));
}
export function couponList(payload){
    return AxiosClient.post('/coupons/get-coupons', bodyEncryption(payload, true));
}
export function couponPoints(payload){
    return AxiosClient.post('/coupons/get-points', bodyEncryption(payload, true));
}
export function couponDetails(payload){
    return AxiosClient.post('/coupons/get-coupon-details', bodyEncryption(payload, true));
}
export function mycouponDetails(payload){
    return AxiosClient.post('/coupons/mycoupondetails', bodyEncryption(payload, true));
}
export function likeUnlikeCoupon(payload){
    return AxiosClient.post('/coupons/like-dislike-coupon', bodyEncryption(payload, true));
}
export function revealCouponCode(payload){
    return AxiosClient.post('/coupons/revel-coupon-code', bodyEncryption(payload, true));
}
export function getCouponCommentList(payload){
    return AxiosClient.post('/coupons/get-coupon-comments', bodyEncryption(payload, true));
}
export function postCouponComment(payload){
    return AxiosClient.post('/coupons/post-coupon-comment', bodyEncryption(payload, true));
}
export function likeUnlikeCouponComment(payload){
    return AxiosClient.post('/coupons/like-unlike-coupon-comment', bodyEncryption(payload, true));
}
export function ReportCoupon(payload){
    return AxiosClient.post('/coupons/report-coupon', bodyEncryption(payload, true));
}
export function getCouponPriceAPI(payload){
    return AxiosClient.post('/coupons/get-coupon-price', bodyEncryption(payload, true));
}


export function postAdComment(payload) {
    return AxiosClient.post('/watch/post-ad-comment', bodyEncryption(payload, true));
}
export function getAdComments(payload) {
    return AxiosClient.post('/watch/get-ad-comments', bodyEncryption(payload, true));
}
export function likeUnlikeAdComment(payload) {
    return AxiosClient.post('/watch/like-unlike-ad-comment', bodyEncryption(payload, true));
}


// --------------------------Category -----------------------------------

export function AddCategory(payload) {
    return AxiosClient.post('/common/add-category', bodyEncryption(payload, true));
}

export function AddSubCategory(payload) {
    return AxiosClient.post('/common/add-subcategory', bodyEncryption(payload, true));
}

export function CategoryList(payload) {
    return AxiosClient.post('/common/get-category-list', bodyEncryption(payload, true));
}
export function CategoryListDeatils(payload) {
    return AxiosClient.post('/common/category-list', bodyEncryption(payload, true));
}
// get-subcategory-by-category
export function SubCategoryList(payload) {
    return AxiosClient.post('/common/get-subcategory-by-category', bodyEncryption(payload, true));
}

export function SubCategoryListdeatils(payload) {
    return AxiosClient.post('/common/get-subcategory-list', bodyEncryption(payload, true));
}
export function AddBrand(payload) {
    return AxiosClient.post('/common/add-brand', bodyEncryption(payload, true));
}
// get-brands
export function BrandList(payload) {
    return AxiosClient.post('/common/get-brands', bodyEncryption(payload, true));
}

// get-brands-list for forms
export function FetchBrandList(payload) {
    return AxiosClient.post('/common/brands-list', bodyEncryption(payload, true));
}

export function createAdvertisement(payload){
    return AxiosClient.post('/watch/createAdvertisement', bodyEncryption(payload, true));
}

export function createCoupon(payload) {
    return AxiosClient.post('/coupons/create-coupon', bodyEncryption(payload, true));
}

export function createStripeCheckoutSession(payload){
    return AxiosClient.post('/common/create-stripe-checkout-session', bodyEncryption(payload, true));
}
// SIGNIN ------------


export function login(payload){
    return AxiosClient.post('/auth/signin', bodyEncryption(payload, true));
}


export function myCoupons(payload) {
    return AxiosClient.post('/coupons/mycoupons', bodyEncryption(payload, true));
}
// --------------------------Category -----------------------------------
