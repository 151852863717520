// SubCategory

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AddCategory } from '../../utils/api.service';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/common.service';
import { CategoryListDeatils,AddSubCategory } from '../../utils/api.service';
import { useSelector } from 'react-redux';

function SubCategory({ show, SubcategorysetShowModal }) {
  const { register,setValue, handleSubmit, formState: { errors } ,reset} = useForm();

  const handleClose = () => {
    SubcategorysetShowModal(false);
    // setValue('name','')
    // setValue('category_id','')
    reset()
  } 


  const [CategoryListt ,setCategoryList]=useState()

  useEffect(()=>{

    CategoryListDeatils({}).then(function (response) {
                
        if (response.code === '1') {
            setCategoryList(response?.data)
        
        } else {
            TOAST_ERROR(response.message);
        }

    })

  },[])

  const darkMode = useSelector((state) => state.theme.themeValue);


  const buttonStyle = {
    backgroundColor: darkMode === 'theme-dark' ? 'white' : 'black',
    color: darkMode === 'theme-dark' ? 'black' : 'white',
    borderColor: darkMode === 'theme-dark' ? 'white' : 'black',
  };

  const onSubmit = (data) => {
    
    AddSubCategory(data).then(function (response) {
                
        if (response.code === '1') {
            TOAST_SUCCESS(response.message)
            setValue('name','')
            setValue('category_id','')
         

            handleClose();
        } else {
            TOAST_ERROR(response.message);
        }

    })
    handleClose();
  };

  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New SubCategory</Modal.Title>
        </Modal.Header>
        
   
        <form onSubmit={handleSubmit(onSubmit)}>
  <Modal.Body>
    {/* Category Name Dropdown */}
    <div className="form-group mt-3">
      <label htmlFor="category_id">Category Name</label>
      <select
        id="category_id"
        className={`form-control ${errors.category_id ? 'is-invalid' : ''}`}
        {...register('category_id', { required: 'Category ID is required' })}
      >
        <option value="">Select a category</option>
        {CategoryListt && CategoryListt.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      {errors.category_id && (
        <div className="invalid-feedback">
          {errors.category_id.message}
        </div>
      )}
    </div>

    {/* Subcategory Name Field */}
    <div className="form-group mt-3">
      <label htmlFor="subcategory_name">Subcategory Name</label>
      <input
        id="subcategory_name"
        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
        {...register('name', { 
          required: 'Subcategory Name is required', 
          pattern: {
            value: /^[^\d\p{Emoji}]*$/u, // Disallow numbers and emojis
            message: 'Please enter valid sub category name'
          }
        })}
        placeholder="Enter subcategory name" 
      />
      {errors.name && (
        <div className="invalid-feedback">
          {errors.name.message}
        </div>
      )}
    </div>
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button style={buttonStyle} type="submit">
      Submit
    </Button>
  </Modal.Footer>
</form>


      </Modal>
    </>
  );
}

export default SubCategory;
