
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup , OAuthProvider} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCg2TitC5_xyoig5fErJH13TC-cPYwqQcc",
  authDomain: "onestens.firebaseapp.com",
  projectId: "onestens",
  storageBucket: "onestens.appspot.com",
  messagingSenderId: "600239619222",
  appId: "1:600239619222:web:e577732dd1619b187016b7",
  measurementId: "G-V3KVFVNYF2",

};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();


const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

export { auth, googleProvider, signInWithPopup,appleProvider };
