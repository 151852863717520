import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/watchSlice';
import { openModal as openReCaptchaModal, closeModal as closeReCaptchaModal } from '../../store/slices/modalreCaptchaSlice'; // Import reCAPTCHA actions
import blink from '../../utils/blink.service';
import { getAdvertisement, updateWatchReps, adsUrlHit, likeUnlikeAd } from '../../utils/api.service';
import Loader from '../loader/Loader';
import { loginUser } from '../../store/slices/authUserSlice';
import { storeAuthData, timeToSeconds, secondsToHMS, secondsToMMSS } from '../../utils/common.service';
import { Link } from 'react-router-dom';
import AdsenseComponent from '../GoogleAds/AdsenseComponent';
import { SWAL_ALERT, SWAL_ALERT_OK, TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';
import CommentAdModal from './CommentAdModal';
import { openModal as openCommentModal, closeModal as closeCommentModal } from '../../store/slices/commentAdSlice';
import ReCaptchaModal from './ReCaptchaModal';
import Cookies from 'js-cookie';
import { bodyDecryption } from "../../utils/common.service";
const WatchModal = () => {

    const [adTypes, setAdTypes] = useState(['custom','google']);
    const [googleAdSlots, setGoogleAdSlots] = useState(['1537401150', '6211143336', '5604584778']);
    const [googleSlot, setGoogleSlot] = useState('');
    const [adType, setAdType] = useState('');
    const [isLiked, setIsLiked] = useState();
    const [showLike, setShowLike] = useState(false);
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
    const [isRecaptchaModalOpen, setRecaptchaModalOpen] = useState(false);


    const getRandomSlot = () => {
        const randomIndex = Math.floor(Math.random() * googleAdSlots.length);
        setGoogleSlot(googleAdSlots[randomIndex]);
        return googleAdSlots[randomIndex];
    };

    const getRandomAdType = () => {
        const randomIndex = Math.floor(Math.random() * adTypes.length);
        setAdType(adTypes[randomIndex]);
        return adTypes[randomIndex];
    };

    const loadAdvertisement = () => {
        if (getRandomAdType() === 'google') {
            // 
            setShowLike(false);
            getRandomSlot();
        }
        else {
            // 
            fetchCustomAdvertisement();
        }
    }

    const [isLoading, setIsLoading] = useState(false);
    const [showSkip, setShowSkip] = useState(false);
    const [skipTimer, setSkipTimer] = useState(0);
    const [recaptchaTimer, setRecaptchaTimer] = useState(0);
    const [isrecaptchaten, setIsrecaptchaten] = useState(false);
    const skipRef = useRef();
    // const [isLiked, setIsLiked] = useState();
    const initialModalState = useSelector((state) => state.modalWatch.isModalOpen);
    // Local state to manage modal visibility
    const [isModalOpen, setIsModalOpen] = useState(initialModalState);
    // Sync local state with Redux state changes
    useEffect(() => {
        setIsModalOpen(initialModalState);
    }, [initialModalState]);
    // const [isModalOpen, setisModalOpen] = useState(ModalOpen);

    const loginUserData = useSelector((state) => state.authUser.loginUserData);
    const dispatch = useDispatch();

    const handleCloseModal = () => {

        blink.stopPrediction();

        // setStart(false)
        // setRestart(!restart)
        // setRestart(true)

        // Cancel the animation frame
        // cancelAnimationFrame(raf);

        // Clean up resources


        // Clear the video stream if it exists
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(track => track.stop());
            // videoRef.current.srcObject = null;
        }

        dispatch(closeModal());
        // navigate('dashboard');
        // handleNavigateToDashboard();
        // setTimeout(() => {
        // }, 3000);
    };

    const handleOpenCommentModal = async (AdId) => {
        setStart(false);
        setIsModalOpen(false);
        blink.stopPrediction();
        clearInterval(timerTick.current);
        await dispatch(openCommentModal({ AdData: data, AdId: AdId }));
    };

    const handleCloseCommentModal = () => {
        setStart(true);
        setIsModalOpen(true);

        dispatch(closeCommentModal());
    };



    const [eyeIcon, setEyeIcon] = useState(process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/green-eye-slash.svg");
    const [data, setData] = useState([]);
    const [timer, setTimer] = useState(0);
    const [watchTimer, setWatchTimer] = useState(0);
    const [playTimer, setPlayTimer] = useState(0);
    const [initialised, setInitialised] = useState(false);
    const [start, setStart] = useState(false);
    const [restart, setRestart] = useState(false);
    const timerTick = useRef();
    const playTimerTick = useRef();
    const everyMinuteTimerTick = useRef();
    const animationRef = useRef();
    const videoRef = useRef(null);
    const recaptchaTimerRef = useRef();

    const init = async () => {

        // setIsLoading(false);
        // setInitialised(true);
        // handleViewTimer(null);

        if (videoRef.current !== null) {
            await blink.loadModel();
            await blink.setUpCamera(videoRef.current);
            setIsLoading(false);
            setInitialised(true);

            const predict = async () => {
                let result = await blink.getBlinkPrediction(restart);
                if (restart) {
                    setRestart(false)
                }
                if (result !== undefined && result !== null) {
                    handleViewTimer(result);
                    animationRef.current = requestAnimationFrame(predict);
                }
                else {
                    setEyeIcon(process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/green-eye-slash.svg");
                    
                    if (isModalOpen) {
                        setTimeout(() => {
                            setRecaptchaModalOpen(true);
                            SWAL_ALERT_OK('Eye detection hiccup! Kindly retry for a seamless interaction', '', 'warning', true, null, '')
                                .then(() => {
                                    handleCloseModal();
                                });
                        }, 5000); // Delay in milliseconds (e.g., 3000 ms = 3 seconds)
                    }

                }
            };
            predict();
        }
    };

    const handleViewTimer = (result) => {

        // 

        if (result === null) {
            setStart(false);
            setEyeIcon(process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/green-eye-slash.svg");
        }
        else {
            if (document.visibilityState === 'visible' && (result.left === false || result.right === false)) {
                setStart(true);
                setEyeIcon(process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/green-eye.svg");
            } else {
                setStart(false);
                setEyeIcon(process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/green-eye-slash.svg");
            }
        }

    };

    const fetchCustomAdvertisement = () => {
        setData([]);
        setIsLiked(0);
        let payload = {}
        getAdvertisement(payload).then(function (response) {
            // 
            if (response.code === '1') {
                setShowLike(true);
                // 
                setIsLiked(response.data[0]?.is_like)
                // 
                setData(response.data);
            }
        }).catch(function (error) {
            
        });
    }
    const handleLikeUnlikeAd = (ad_id) => {
        let payload = {
            ad_id: ad_id,
            is_like: isLiked === 0 ? 1 : 0,
        };
        likeUnlikeAd(payload).then(function (response) {
            if (response.code === '1') {
                setIsLiked(prevState => prevState === 0 ? 1 : 0)
                TOAST_SUCCESS(response.message);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }

    const handleUpdateWatchReps = async (watchingTime) => {
        let timeInSeconds = timeToSeconds(loginUserData.total_watching_time);
        // 
        // if (watchingTime + timeInSeconds >= 600) {

        //     await handleCheckrepsUpdate(watchingTime);
        // }
        // else {

        let payload = {
            addSecondsToTime: watchingTime
        }
        // 
        await updateWatchReps(payload).then(function (response) {
            
            if (response.code === '1') {
                let authgetUserData = Cookies.get('onestens-auth-user-data');
                authgetUserData = JSON.parse(bodyDecryption(authgetUserData));
                delete response.data.token;
                response.data.token = authgetUserData.token;
                const authUserData = storeAuthData(response.data)
                dispatch(loginUser(authUserData));
                setTimer(0);
            }
        }).catch(function (error) {
            
        });
        // }

    }

    const handleCheckrepsUpdate = async (watchingTime) => {
        // 
        if (!isRecaptchaVerified) {
            let currentCount = parseInt(localStorage.getItem('reps_count')) ?? 0;
            currentCount = parseInt(currentCount) + 1;

            let updatedCount = parseInt(localStorage.getItem('reps_count')) ?? 0;
            if (updatedCount >= 2) {
                handleCloseModal();
                SWAL_ALERT('To continue, please download the application', '<div style="margin-bottom: 10px;">To continue, please download the application available in <div class="image-container" style="display: inline-block; margin: 10px;"><a href="#" class="app_store"><img src="' + process.env.REACT_APP_PUBLIC_URL + 'assets/images/store-apple@2x.jpg" style="width: 150px; display: block;" /></a></div><div class="image-container" style="display: inline-block;"><a href="#" class="play_store"><img src="' + process.env.REACT_APP_PUBLIC_URL + 'assets/images/store-play@2x.jpg" style="width: 150px; display: block;" /></a></div></div>', 'warning', true, null, '');
            }
            setStart(false);
            // 
            setEyeIcon(process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/green-eye-slash.svg");
            dispatch(openReCaptchaModal()); // Open reCAPTCHA modal
            setRecaptchaModalOpen(true);
        }
        else {
            return;


        }
    }

    const handleAdsUrlHit = async (ad_id) => {
        let payload = {
            ad_id: ad_id
        }

        await adsUrlHit(payload).then(function (response) {
            if (response.code === '1') {
                // 
            }
        }).catch(function (error) {
            
        });
    }

    const updateTimer = () => {
        if (!isRecaptchaModalOpen) {
            const timeInSeconds = timeToSeconds(loginUserData?.total_watching_time);
            setWatchTimer(timeInSeconds);
        }
    };

    const handleSkipButton = () => {
        setSkipTimer(0)
        setShowSkip(false);
        clearInterval(skipRef.current);
        loadAdvertisement();
    };

    useEffect(() => {
        if (isModalOpen) {
            setIsLoading(true);
            setRecaptchaModalOpen(false);
            setTimer(0)
            setPlayTimer(0)
            setStart(false)
            setRestart(true)
            setEyeIcon(process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/green-eye-slash.svg");
            init();
        }

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current)
            }
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (isModalOpen && start) {
            timerTick.current = setInterval(() => {
                setTimer((timer) => timer + 1);
            }, 1000);
        } else {
            clearInterval(timerTick.current);
        }

        return () => {
            clearInterval(timerTick.current);
        }
    }, [start]);

    useEffect(() => {
        if (isModalOpen && data[0]?.ad_type_id === 'video') {
            skipRef.current = setInterval(() => {
                setSkipTimer((prevTimer) => {
                    if (prevTimer === 10) {
                        setShowSkip(true);
                    }
                    return prevTimer + 1;
                });
            }, 1000);
        } else {
            clearInterval(skipRef.current);
        }

        return () => {
            clearInterval(skipRef.current);
        }
    }, [data]);

    useEffect(() => {
        let watchingTime = timer;
        if (isModalOpen) {
            updateTimer();
        }

        if (isModalOpen && initialised) {

            playTimerTick.current = setInterval(async () => {

                setPlayTimer((prevPlayTimer) => {

                    if (prevPlayTimer % 10 === 0 && data[0]?.ad_type_id !== 'video') {
                        loadAdvertisement();
                    }
                    // 
                    // 
                    let timeInSeconds = timeToSeconds(loginUserData.total_watching_time);

                    if (prevPlayTimer % 15 === 0 && prevPlayTimer > 0) {
                        // 
                        if ((timeInSeconds + watchingTime) >= 600) {
                            handleCheckrepsUpdate(watchingTime);
                        }
                        else {
                            // 
                            handleUpdateWatchReps(watchingTime);
                        }
                    }

                    return prevPlayTimer + 1;
                })


                let timeInSeconds = await timeToSeconds(loginUserData.total_watching_time);
                // 
                if (timeInSeconds >= 600) {

                    // handleUpdateWatchReps(timeInSeconds)


                    await handleCheckrepsUpdate(watchingTime);
                }
                else {
                    timeInSeconds = timeInSeconds + 1;
                    let userData = { ...loginUserData };
                    userData.total_watching_time = secondsToHMS(timeInSeconds);
                    dispatch(loginUser(userData));
                }

            }, 1000);
        }

        return () => {
            clearInterval(playTimerTick.current);
            clearInterval(everyMinuteTimerTick.current);
        }
    }, [isModalOpen, initialised, timer, data]);

    useEffect(() => {
        let recaptchaInterval;
        if (isModalOpen && isrecaptchaten === false) {
            recaptchaInterval = setInterval(() => {
                setRecaptchaTimer((prevTimer) => {
                    if (prevTimer === 600) {
                        setIsrecaptchaten(true);
                        return prevTimer;
                    } else {
                        return prevTimer + 1;
                    }
                });
            }, 1000);
        } else {
            setRecaptchaTimer(0);
        }

        return () => {
            clearInterval(recaptchaInterval);
        };
    }, [isModalOpen, initialised, isrecaptchaten]);
    useEffect(() => {
        
        

        if (recaptchaTimer === 600) {
            setStart(false);
            setIsrecaptchaten(true);
            // 
            setEyeIcon(process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/green-eye-slash.svg");
            dispatch(openReCaptchaModal()); // Open reCAPTCHA modal
            setRecaptchaModalOpen(true);
            setRecaptchaTimer(0)
        }
    }, [recaptchaTimer])

    const handleReCaptchaComplete = async () => {
        let watchingTime = timer;
        setRecaptchaModalOpen(false);
        setStart(true);
        if (isrecaptchaten === false) {
            let currentCount = parseInt(localStorage.getItem('reps_count')) ?? 0;
            currentCount = parseInt(currentCount) + 1;
            localStorage.setItem('reps_count', currentCount);
            let updated_count = parseInt(localStorage.getItem('reps_count')) ?? 0;
            if (updated_count >= 2) {
                setTimeout(() => {

                    handleCloseModal();
                    SWAL_ALERT('To continue, please download the application', '<div style="margin-bottom: 10px;">To continue, please download the application available in <div class="image-container" style="display: inline-block; margin: 10px;"><a href="#" class="app_store"><img src="' + process.env.REACT_APP_PUBLIC_URL + 'assets/images/store-apple@2x.jpg" style="width: 150px; display: block;" /></a></div><div class="image-container" style="display: inline-block;"><a href="#" class="play_store"><img src="' + process.env.REACT_APP_PUBLIC_URL + 'assets/images/store-play@2x.jpg" style="width: 150px; display: block;" /></a></div></div>', 'warning', true, null, '');
                }, 3000)
            }
        }
        else {
            setIsrecaptchaten(false);
        }


        dispatch(closeReCaptchaModal()); // Close reCAPTCHA modal
        setIsRecaptchaVerified(true);
        handleUpdateWatchReps(watchingTime);

        setTimeout(() => {
            setIsRecaptchaVerified(false);
        }, 1000000);
    };

    return (
        <>
            <Modal show={isModalOpen} size="md">
                <Modal.Body className='p-0 position-relative'>
                    <button type="button" className="custom_btn" onClick={handleCloseModal}>X</button>
                    <div className="row">

                        <div className="col-md-12 mb-3 mt-3">

                            <div className='ads-header mb-2'>
                                <Link href="#" className="timer me-2"><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/timer.svg"} className="logo_1" /><span>{secondsToMMSS(watchTimer)}</span></Link>
                                <Link href="#" className="view_ad me-2">{loginUserData?.total_reps}</Link>
                                <img src={eyeIcon} className="ads-header-img" alt="" />
                            </div>

                           
                            <div className="slider_rutorial text-center ads-container">
                            <div className='modal-buttons_1'>
                                {showLike && (
                                    <>
                                        <div className="heart">
                                            <Link onClick={() => handleLikeUnlikeAd(data[0]?.id)}>
                                                {isLiked === 1 ? (
                                                    <img
                                                        src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/heart-red-rounded.svg`}
                                                        alt="Liked"
                                                        className=""
                                                    />
                                                ) : (
                                                    <img
                                                        src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/heart-black.svg`}
                                                        alt="Not Liked"
                                                        className="logo_1"
                                                    />
                                                )}
                                            </Link>
                                        </div>
                                        <div className="logo_comment_watch position-relative">
                                            <Link to="#" onClick={() => handleOpenCommentModal(data[0]?.id)}>
                                                <img
                                                    src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/comment-old.svg`}
                                                    alt="Comment"
                                                    style={{ filter: 'invert(1)' }}
                                                />
                                            </Link>
                                        </div>
                                    </>
                                )}

                                {showSkip ? <Link href="#" onClick={handleSkipButton} className="add_skip  position-relative ms-auto"><span>Skip</span></Link> : ''}
                            </div>



                                {/* {(data[0]?.ad_url !== '') ? <Link to={data[0]?.ad_url} onClick={() => handleAdsUrlHit(data[0]?.id)} target='_blank'>
                                    <div className="view_video view_video_80vh">
                                        {(data.length > 0) ? ((data[0] !== undefined && data[0]?.ad_type_id === 'image') ? <img src={data[0]?.image_video} alt="" /> :
                                            <video autoPlay preload="metadata" id="banner" >
                                                <source src={data[0]?.image_video} />
                                            </video>) : <img className='please-wait-img' style={{ width: '180px' }} src={process.env.REACT_APP_PUBLIC_URL + (localStorage.getItem('theme') === 'theme-dark' ? "assets/images/please-wait-dark.svg" : "assets/images/please-wait.svg")} alt="" />}
                                    </div>
                                </Link> : <div className="view_video view_video_80vh">
                                    {(data.length > 0) ? ((data[0] !== undefined && data[0]?.ad_type_id === 'image') ? <img src={data[0]?.image_video} alt="" /> :
                                        <video autoPlay preload="metadata" id="banner" >
                                            <source src={data[0]?.image_video} />
                                        </video>) : <img className='please-wait-img' style={{ width: '180px' }} src={process.env.REACT_APP_PUBLIC_URL + (localStorage.getItem('theme') === 'theme-dark' ? "assets/images/please-wait-dark.svg" : "assets/images/please-wait.svg")} alt="" />}
                                </div>} */}



                                {(adType === 'google') ? <div className="view_video view_video_80vh">
                                    <AdsenseComponent adClient={process.env.REACT_APP_GOOGLE_AD_CLIENT} adSlot={googleSlot} adFormat="auto" />
                                </div> : ((data[0]?.ad_url !== '') ? <a href={/^https?:\/\//.test(data[0]?.ad_url) ? data[0]?.ad_url : `https://${data[0]?.ad_url}`} onClick={() => handleAdsUrlHit(data[0]?.id)} target='_blank' rel="noopener noreferrer">

                                    <div className="view_video view_video_80vh">
                                        {(data.length > 0) ? ((data[0] !== undefined && data[0]?.ad_type_id === 'image') ? <img src={data[0]?.image_video} alt="" /> :
                                            <video autoPlay preload="metadata" id="banner" >
                                                <source src={data[0]?.image_video} />
                                            </video>) : <img className='please-wait-img' style={{ width: '180px' }} src={process.env.REACT_APP_PUBLIC_URL + (localStorage.getItem('theme') === 'theme-dark' ? "assets/images/please-wait-dark.svg" : "assets/images/please-wait.svg")} alt="" />}
                                    </div>
                                </a> : <div className="view_video view_video_80vh">
                                    {(data.length > 0) ? ((data[0] !== undefined && data[0]?.ad_type_id === 'image') ? <img src={data[0]?.image_video} alt="" /> :
                                        <video autoPlay preload="metadata" id="banner" >
                                            <source src={data[0]?.image_video} />
                                        </video>) : <img className='please-wait-img' style={{ width: '180px' }} src={process.env.REACT_APP_PUBLIC_URL + (localStorage.getItem('theme') === 'theme-dark' ? "assets/images/please-wait-dark.svg" : "assets/images/please-wait.svg")} alt="" />}
                                </div>)}

                            </div>
                        </div>

                        <video className="d-none" id="video" ref={videoRef} width={'100px'} height={'100px'}></video>

                    </div>
                </Modal.Body>
            </Modal>
            <ReCaptchaModal onComplete={handleReCaptchaComplete} /> {/* Include reCAPTCHA modal */}
            <Loader isLoading={isLoading} />
            <CommentAdModal handleClose={handleCloseCommentModal} />

        </>
    );
};

export default WatchModal;
