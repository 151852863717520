import { createSlice } from "@reduxjs/toolkit";

const ModalClaimSlice = createSlice({
    name: 'modalClaim',
    initialState: {
        isModalOpen: false,
        props: null,
    },
    reducers: {
        openModal: (state, props) => {
            state.isModalOpen = true;
            state.props = props;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = ModalClaimSlice.actions;
export default ModalClaimSlice.reducer;