import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { couponPoints, couponDetails, mycouponDetails, likeUnlikeCoupon, revealCouponCode } from "../../utils/api.service";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../layouts/Layout";
import { useActiveTab } from "../../components/common/ActiveTabContext";
import { openModal } from "../../store/slices/commentCouponSlice";
import { openModal as openReportModal } from "../../store/slices/reportCouponSlice";
import CommentCouponModal from "../../components/modals/CommentCouponModal";
import ReportCouponModal from "../../components/modals/ReportCouponModal";
import { closeModal } from '../../store/slices/reportCouponSlice';
import Linkify from 'react-linkify';

import './CouponDetails.css';
const CouponDetails = ({ slug }) => {
    const { id } = useParams();
    const [points, setPoints] = useState(0);
    const [data, setData] = useState([]);
    const { activeTab, setActiveTab } = useActiveTab();
    const [couponId, setCouponId] = useState(0);
    const [isReported, setIsReported] = useState(0);

    const [isLiked, setIsLiked] = useState();
    const dispatch = useDispatch();

    const handleOpenCommentModal = () => {
        dispatch(openModal({ couponData: data, couponId: couponId }));
    };
    const handleOpenReportModal = () => {
        dispatch(openReportModal({ couponData: data, couponId: couponId }));
    };
    const handleCloseReportModal = (is_submitted = 0) => {
        if (is_submitted === 1) {
            setIsReported(1);
        }
        dispatch(closeModal());
    };

    const loginUserData = useSelector((state) => state.authUser.loginUserData);
    const inputRef = useRef(null);

    const fetchCouponDetails = () => {
        let payload = {
            coupon_id: id,
        };
        if (slug === "coupon-details") {
            couponDetails(payload).then(function (response) {
                if (response.code === '1') {
                    setData(response.data)
                    setCouponId(response.data.id);
                    setIsLiked(response.data.is_like)
                    setIsReported(response.data.is_reported)
                } else if (response.code === '2') {
                    // TOAST_ERROR(response.message);
                } else if (response.code !== '0') {
                    // TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }
        else {
            mycouponDetails(payload).then(function (response) {
                if (response.code === '1') {
                    setData(response.data)
                    setCouponId(response.data.id);

                    setIsLiked(response.data.is_like)

                } else if (response.code === '2') {
                    TOAST_ERROR(response.message);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }
    }

    const getCouponPoints = () => {
        let payload = {};
        couponPoints(payload)
            .then(function (response) {
                if (response.code === '1') {
                    setPoints(response.data.total_points)
                } else if (response.code !== '0') {
                    // TOAST_ERROR(response.message);
                }
            })
            .catch(function (error) {
                
            });

    };
    const handleCopyClick = () => {
        if (data.coupon_code !== undefined && data.coupon_code !== null && data.coupon_code !== '') {
            inputRef.current.value = data.coupon_code; // Set the value dynamically
            copyCode();
        }
        else {
            let payload = {
                coupon_id: data.id,
            };
            revealCouponCode(payload).then(function (response) {
                if (response.code === '1') {
                    couponPoints({}).then(function (response1) {
                        if (response1.code === '1') {
                            data.coupon_code = response.data.coupon_code
                            setPoints(response1.data.total_points)
                            inputRef.current.value = response.data.coupon_code; // Set the value dynamically
                            copyCode();
                        }

                    }).catch(function (error) {
                        
                    });


                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }
    }
    
    const handleClaimClick = () => {
        const openURLInNewTab = (url) => {
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'http://' + url; // Ensure the URL starts with http or https
            }
            window.open(url, '_blank');
        };
        if (data.coupon_code !== undefined && data.coupon_code !== null && data.coupon_code !== '') {
            inputRef.current.value = data.coupon_code; // Set the value dynamically
            copyCode();
            openURLInNewTab(data.websitelink); // Use the helper function to open the URL
        }
        else {
            let payload = {
                coupon_id: data.id,

            };
            revealCouponCode(payload).then(function (response) {
                if (response.code === '1') {
                    couponPoints({}).then(function (response1) {

                        if (response1.code === '1') {
                            setPoints(response1.data.total_points)
                            inputRef.current.value = response.data.coupon_code; // Set the value dynamically
                            copyCode();
                            openURLInNewTab(data.websitelink); // Use the helper function to open the URL
                        }

                    }).catch(function (error) {
                        
                    });


                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }

    }
    const copyCode = () => {
        inputRef.current.select();
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        TOAST_SUCCESS('Coupon Code Copied!');
    }
    useEffect(() => {
        getCouponPoints()
        fetchCouponDetails()
        setActiveTab("coupons");

        return () => {
            setActiveTab('');
        };
    }, [])





    const handleLikeUnlikeCoupon = (coupon_id) => {

        let payload = {
            coupon_id: coupon_id,
            is_like: isLiked === 0 ? 1 : 0,
            is_dislike: isLiked === 0 ? 0 : 1,
        };
        likeUnlikeCoupon(payload).then(function (response) {

            if (response.code === '1') {
                setIsLiked(prevState => prevState === 0 ? 1 : 0)
                TOAST_SUCCESS(response.message);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }

        }).catch(function (error) {
            
        });
    }

    const handleLike = (coupon_id) => {
        handleLikeUnlikeCoupon(coupon_id);
    };

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | Coupon Details`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            <section className="winnig_section">
                <div className="container">
                    <div className="row mt-3">
                        {/* {(activeTab === "coupons") && (
                            <div className="col-md-12 d-flex ms-auto justify-content-end mb-3">
                                <Link to="#" className="zero_span coupon_points"><span>{points}</span></Link>
                            </div>
                        )} */}
                        {(data != null) ?
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                <div className="package_list">
                                    <div className="images_main detail_page_auto">
                                        <img src={data.brand_image} />
                                        {/* {data.brand_logo != undefined && data.brand_logo != null && data.brand_logo != '' && (<div className="logo_img">
                                            <img src={data.brand_logo} />
                                        </div>)} */}
                                        <div className="logo_comment">
                                            <Link to="#"
                                                onClick={handleOpenCommentModal}
                                            >
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/comment.svg"} />
                                            </Link>
                                            {data.coupon_code !== undefined && data.coupon_code !== null && data.coupon_code !== '' && isReported !== 1 && (<Link to="#" onClick={handleOpenReportModal}>
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/report.svg"} />
                                            </Link>)}

                                        </div>
                                    </div>
                                    <div className="package_desc bg-white detail_page">
                                        <div className="detail_header">
                                            <h4>{data.brand_name}</h4>
                                            <label>
                                                {loginUserData !== null && loginUserData.id === data.user_id ? (
                                                    <div>
                                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/like.png"} alt="Like" />
                                                        {data.like_count}
                                                    </div>
                                                ) : (
                                                    <div className="heart">
                                                        <Link onClick={() => handleLike(data.id)}>
                                                            {isLiked === 1 ? (
                                                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/like.png"} alt="Liked" className="" />
                                                            ) : (
                                                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/unlike.png"} alt="Like" className="" />
                                                            )}
                                                        </Link>
                                                    </div>
                                                )}

                                            </label>
                                        </div>
                                        <div className="w_detail detail_page pt-2 mt-5">
                                            <a
                                                className="collapse_title"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapsedetails"
                                                aria-expanded="false"
                                                aria-controls="collapsedetails"
                                            >
                                                Details
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/caret-down.svg"} />
                                            </a>
                                            <div className="collapse show " id="collapsedetails">
                                                <div className="card card-body border-0 p-0 datail_page_desc mt-2">
                                                    <p>
                                                        <Linkify>{data.description}</Linkify>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="w_detail detail_page pt-2 mt-5">
                                            <a
                                                className="collapse_title"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseExample"
                                                aria-expanded="false"
                                                aria-controls="collapseExample"
                                            >
                                                Additional Details
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/caret-down.svg"} />
                                            </a>
                                            <div className="collapse show " id="collapseExample">
                                                <div className="card card-body border-0 p-0 datail_page_desc mt-2">
                                                  
                                                    <p>
                                                        <Linkify>{data.tutorial}</Linkify>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        {loginUserData != null && loginUserData.id === data.user_id && (
                                            <div>
                                                <div className="coupon_status">
                                                    <h5>Coupon Status:</h5>
                                                    {data.is_complete === 1 ? (
                                                        <span className="status_btn" style={{ backgroundColor: "#D7DBFF" }}>Complete</span>
                                                    ) : data.is_approve === 0 ? (
                                                        <span className="status_btn" style={{ backgroundColor: "#5cf8fb" }}>Validating</span>
                                                    ) : data.is_approve === 2 ? (
                                                        <span className="status_btn" style={{ backgroundColor: "#FFA7A7" }}>Rejected</span>
                                                    ) : data.is_active === 1 ? (
                                                        <span className="status_btn btn_active" style={{ backgroundColor: "#EEFFE9" }}>Active</span>
                                                    ) : data.is_active === 2 ?
                                                        (<span className="status_btn" style={{ backgroundColor: "#FFA7A7" }}>Hold</span>) : ('')}
                                                    {/* Uncomment below lines if needed */}
                                                    {/* <span className="status_btn btn_pending">Pending</span>
                                                     <span className="status_btn btn_complete">Complete</span> */}
                                                </div>

                                                <div className="coupon_listing">
                                                    <ul>
                                                        <li>
                                                            <label>Start Date</label>
                                                            <span>{new Date(data.start_date).toLocaleDateString('en-GB')}</span>
                                                        </li>
                                                        <li>
                                                            <label>Target</label>
                                                            <span>{data.total_click}</span>
                                                        </li>
                                                        <li>
                                                            <label>Achieved</label>
                                                            <span>{data.current_click_count}</span>
                                                        </li>
                                                        <li>
                                                            <label>Remaining</label>
                                                            <span>{data.total_remaining}</span>
                                                        </li>
                                                        <li>
                                                            <label>Total comments </label>
                                                            <span>{data.total_comments}</span>
                                                        </li>
                                                        <li>
                                                            <label>Total reports </label>
                                                            <span>{data.total_reports}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}


                                        {loginUserData != null && loginUserData.id !== data.user_id && (
                                            <div className="bottom_btn">
                                                <input ref={inputRef}
                                                    type="text" readOnly
                                                    className="copyto-code"
                                                    style={{ cursor: 'pointer' }}
                                                    defaultValue="Click here to copy code"
                                                    onClick={handleCopyClick}
                                                />
                                                <Link to="#" className="btn btn-theme" onClick={handleClaimClick}>
                                                    Claim
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            : <div className="col-12 mt-10 text-center">
                                <strong style={{ fontSize: '20px' }}>No Data Found</strong>
                            </div>}
                    </div>
                </div>
            </section>

            <CommentCouponModal />
            <ReportCouponModal handleCloseReportModal={handleCloseReportModal} />

        </Layout>
    )
}

export default CouponDetails;