import React, { useState, useEffect } from 'react';
import { getCities } from '../../utils/api.service';
import { Link } from 'react-router-dom';
import Select from 'react-select';

const StateCityRow = ({ states, rowId, addField, deleteField, stateCityIds, setStateCityIds, useFormObject }) => {

    const [stateList, setStateList] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCities, setSelectedCities] = useState([]);

    const generateStateOptions = () => {
        let options = stateList.map((item) => ({ value: item.id, label: item.name }))
        if (rowId === 1 && options.length > 0) {
            const newState = { value: 0, label: 'All States' };
            options.unshift(newState);
        }
        return options;
    };

    const generateCityOptions = () => {
        let options = cities.map((item) => ({ value: item.id, label: item.city_name }))
        if (options.length > 0 || selectedState === 0) {
            const newCity = { value: 0, label: 'All Cities' };
            options.unshift(newCity);
        }
        return options;
    }

    const stateChangeHandler = (selectedOption) => {

        let selectedState = selectedOption !== null ? selectedOption.value : '';
        setSelectedCities([]);

        if (selectedState !== undefined && selectedState !== '') {

            useFormObject.clearErrors(`states_cities_ids[${rowId}].state`);
            
            setSelectedState((prevState) => {
                if (stateCityIds.some((item) => item.state === prevState)) {
                    setStateCityIds((prevIds) => prevIds.filter((item) => item.state !== prevState));
                }
                setStateCityIds((prevIds) => [...prevIds, { state: selectedState, city: [] }]);
                return selectedState;
            });

            if (selectedState === 0) {
                setCities([]);
                deleteField();
            }
            else {
                let payload = {
                    state_id: selectedState
                };
                getCities(payload).then(function (response) {
                    if (response.code === '1') {
                        setCities(response.data)
                    } else if (response.code !== '0') {
                        setCities([])
                    }
                }).catch(function (error) {
                    
                });
            }

        }
        else {
            useFormObject.setError(`states_cities_ids[${rowId}].state`, { type: "manual", message: "Please select state" });
            
            setSelectedState((prevState) => {
                if (stateCityIds.some((item) => item.state === prevState)) {
                    setStateCityIds((prevIds) => prevIds.filter((item) => item.state !== prevState));
                }
                return null;
            });
            setSelectedState(null);
            setCities([])
        }
    }



    const cityChangeHandler = (selectedOption) => {

        if(selectedOption.length > 0) {
            useFormObject.clearErrors(`states_cities_ids[${rowId}].city`);
        }
        else{
            useFormObject.setError(`states_cities_ids[${rowId}].city`, { type: "manual", message: "Please select city" });
        }

        const containsIdZero = selectedOption.some(item => item.value === 0);
        if (containsIdZero) {
            setSelectedCities(selectedOption.filter(item => item.value === 0));
            const cityValuesArray = [0];
            setStateCityIds((prevIds) => 
                prevIds.map((item) => {
                    if (item.state === selectedState) {
                        return { ...item, city: cityValuesArray };
                    }
                    return item;
                })
            );
        } else {
            setSelectedCities(selectedOption);
            const cityValuesArray = selectedOption.map((cityData) => cityData.value);
            setStateCityIds((prevIds) => 
                prevIds.map((item) => {
                    if (item.state === selectedState) {
                        return { ...item, city: cityValuesArray };
                    }
                    return item;
                })
            );
        }
    }

    const isOptionDisabled = (option) => {
        return stateCityIds.some((item) => item.state === option.value)
    };

    const isCityDisabled = (option) => {
        return selectedCities.some(item => item.value === 0);
    }

    useEffect(() => {
        setStateList(states);
    }, [states])

    return (
        <>
            <div className={selectedState === 0 ? 'col-md-6 col-12 mb-3 d-flex' : 'col-md-5 col-12 mb-3 d-flex'}>
                <div className="form-group fixed_icon w-100">
                    <div className="mobile_div_1">
                        <Select
                            className="form-react-select"
                            options={generateStateOptions()}
                            onChange={stateChangeHandler}
                            placeholder="Select State"
                            isOptionDisabled={isOptionDisabled}
                            isClearable
                        />
                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/location.svg"} className="front_icon" alt='' />
                    </div>
                    <label className="error">{useFormObject.errors.states_cities_ids && useFormObject.errors.states_cities_ids[rowId]?.state?.message}</label>
                </div>
            </div>

            <div className={selectedState === 0 ? 'col-md-6 col-12 mb-3 d-flex' : 'col-md-5 col-10 mb-3 d-flex'}>
                <div className="form-group fixed_icon w-100">
                    <div className="mobile_div_1">
                        <Select
                            className="form-react-select"
                            isMulti
                            options={generateCityOptions()}
                            value={selectedCities}
                            placeholder="Select City"
                            onChange={cityChangeHandler}
                            isOptionDisabled={isCityDisabled}
                            isClearable
                        />
                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/location.svg"} className="front_icon" alt='' />
                    </div>
                    <label className="error">{useFormObject.errors.states_cities_ids && useFormObject.errors.states_cities_ids[rowId]?.city?.message}</label>
                </div>
            </div>

            {selectedState !== 0 ? (rowId === 1 ? <div className="col-md-2 col-2 mb-3" style={{ alignItems: 'end', display: 'flex' }}>
                <Link to={'#'} onClick={addField} className='btn btn-theme mt-auto' style={{ width: '60px' }}>+</Link>
            </div> : <div className="col-md-2 col-2 mb-3" style={{ alignItems: 'end', display: 'flex' }}>
                <Link to={'#'} onClick={() => deleteField(rowId, selectedState, selectedCities)} className='btn btn-danger mt-auto' style={{ width: '60px', borderRadius: '10px' }}>X</Link>
            </div>) : ''}
        </>
    );
};

export default StateCityRow;
