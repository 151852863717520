import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { manageAdsList, myCoupons } from "../../utils/api.service";
import { TOAST_ERROR } from "../../utils/common.service";
import AdsModal from "../../components/modals/AdsModal";
import { openModal } from "../../store/slices/adsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";
import { openModal as openCreateAdsModal } from "../../store/slices/createAdsSlice";
import { openModal as openCreateCouponModal } from "../../store/slices/createCouponSlice";
import CreateAdsModal from "../../components/modals/CreateAdsModal";
import CreateCouponModal from "../../components/modals/CreateCouponModal";
import AdsApprovalModal from "../../components/modals/AdsApprovalModal";

const ManageAds = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOpenModal = (event, payload) => {
        event.preventDefault();
        dispatch(openModal({ params: payload, updateStatus: handleStatusUpdate }));
    };

    const handleOpenCreateAdsModal = () => {
        dispatch(openCreateAdsModal());
    };
    const handleOpenCreateCouponModal = () => {
        dispatch(openCreateCouponModal());
    };

    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [couponPage, setCouponPage] = useState(1);
    const [myCouponData, setMyCouponData] = useState([]);

    const [loadMore, setLoadMore] = useState(true);
    const [loadCouponMore, setLoadCouponMore] = useState(true);

    const [activeTab, setActiveTab] = useState('');
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }
    const handleStatusUpdate = (itemId, is_active,is_like) => {
        const updatedData = data.map(item =>
            item.id === itemId ? { ...item, is_active: is_active,is_like:is_like } : item
        );
        setData(updatedData);
    };

    const fetchManageAdsList = () => {

        if (loadMore) {

            let payload = {
                page: page
            };
            manageAdsList(payload).then(function (response) {

                if (response.code === '1') {
                    const newData = response.data.result;
                    setData((prevData) => [...prevData, ...newData]);
                } else if (response.code === '2') {
                    setLoadMore(false);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }

    }

    const fetchMyCouponList = () => {

        if (loadCouponMore) {

            let payload = {
                page: couponPage
            };
            myCoupons(payload).then(function (response) {
                if (response.code === '1') {
                    const newData = response.data.result;
                    
                    if (couponPage === 1) {
                        setMyCouponData(newData);
                    }
                    else {
                        setMyCouponData((prevData) => [...prevData, ...newData]);
                    }
                } else if (response.code === '2') {
                    setLoadCouponMore(false);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }

    }

    useEffect(() => {
        fetchManageAdsList()
    }, [page])

    useEffect(() => {
        fetchMyCouponList()
    }, [couponPage])
    const loginUserData = useSelector((state) => state.authUser.loginUserData);

    const handleRedirect = (item) => {
        const url = `/my-coupon-details/${item.id}`;
        navigate(url);
    }

    const handleScroll = () => {
        if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
            
            if (activeTab === 'manage_add') {
                setPage((prevPage) => prevPage + 1);
            }
            else {
                setCouponPage((prevPage) => prevPage + 1);
            }
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | Manage Advertisement`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            <section className="winnig_section">
                <div className="container">
                    <div className="col-md-12">
                        <ul className="nav nav-pills mb-3 nav-underline nav-justified" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" onClick={() => handleTabChange("manage_add")}
                                    aria-selected="true">Manage Ads</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link " id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" onClick={() => handleTabChange("my_coupons")} aria-selected="false">My Coupons</button>
                            </li>

                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                                <div className="row  mb-5">
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <div className="d-flex align-items-start flex-column">
                                            <h4 className="mb-2">Manage Advertisement</h4>
                                            <div className="ad_name d-flex justify-content-between w-100">
                                                <Link onClick={handleOpenCreateAdsModal} className="btn btn-more btn-sm">
                                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/plus_gray.svg"} className="me-1 logo_1" />
                                                    Add more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    {(data.length > 0) ? data.map((item, index) => (
                                        <div key={index} className="col-md-4 col-sm-4 col-12 mb-3">
                                            <Link className="watch_video" >
                                                {item.ad_type_id === 'image' ? <img src={item.image_video} alt="" onClick={(event) => handleOpenModal(event, item)} /> :
                                                    <video muted width={'100%'} autoPlay  controls onClick={(event) => handleOpenModal(event, item)} >
                                                        <source src={item.image_video} />
                                                    </video>
                                                }
                                                        {loginUserData != null && loginUserData.id === item.user_id && (
                                                            <label className="status_btn" style={{
                                                                backgroundColor:
                                                                        item.is_approve === 0 ? "#5cf8fb" :
                                                                            item.is_approve === 2 ? "#FFA7A7" :
                                                                                item.is_active === 1 ? "#EEFFE9" :
                                                                                    item.is_active === 0 ? "#FFA7A7" : 
                                                                                      item.is_active === 2 ? "#FFA7A7" : ""
                                                            }}>
                                                                {
                                                                    item.is_approve === 0 ? "Validating" :
                                                                        item.is_approve === 2 ? "Rejected" :
                                                                            item.is_active === 1 ? "Active" :
                                                                                item.is_active === 0 ? "Inactive" : item.is_active === 2 ? "Hold": ""}
                                                            </label>
                                                        )}
                                            </Link>
                                           
                                        </div>
                                    )) : <div className="col-12 mt-10 text-center">
                                        <strong style={{ fontSize: '20px' }}>No Data Found</strong>
                                    </div>}

                                </div>
                            </div>
                            <div className="tab-pane fade " id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">

                                <div className="col-md-12 col-sm-12 col-12 mb-5">
                                    <div className="d-flex align-items-start flex-column">
                                        <h4 className="mb-2">My Coupons</h4>
                                        <div className="ad_name d-flex justify-content-between w-100">
                                            <Link onClick={handleOpenCreateCouponModal} className="btn btn-more btn-sm">
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/plus_gray.svg"} className="me-1 logo_1" />
                                                Add more
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    {(myCouponData.length > 0) ? myCouponData.map((item, index) => (
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                                            <div className="package_list" style={{ cursor: 'pointer' }} onClick={() => handleRedirect(item)}>
                                                <div className="images_main">
                                                    <img src={item.brand_image} alt={item.brand_name} />
                                                    {loginUserData != null && loginUserData.id === item.user_id && (
                                                            <label className="status_btn" style={{
                                                                backgroundColor:
                                                                    item.is_complete === 1 ? "#D7DBFF" :
                                                                        item.is_approve === 0 ? "#5cf8fb" :
                                                                            item.is_approve === 2 ? "#FFA7A7" :
                                                                                item.is_active === 1 ? "#EEFFE9" :
                                                                                    item.is_active === 2 ? "#FFA7A7" : ""
                                                            }}>
                                                                {item.is_complete === 1 ? "Complete" :
                                                                    item.is_approve === 0 ? "Validating" :
                                                                        item.is_approve === 2 ? "Rejected" :
                                                                            item.is_active === 1 ? "Active" :
                                                                                item.is_active === 2 ? "Hold" : ""}
                                                            </label>
                                                        )}

                                                </div>
                                                <div className={`package_desc ${index % 2 === 0 ? 'bg-1' : 'bg-2'}`} style={{ padding: '30px!important' }}>

                                                    <h4 style={{textTransform:'None'}}>
                                                        {item.brand_name.length > 20 ? `${item.brand_name.slice(0, 20)}...` : item.brand_name}
                                                    </h4>
                                                    <div className="offer_div">
                                                    <span>{item.discount.length > 10 ? `${item.discount.slice(0, 10)}...` : item.discount}</span>


                                                    </div>
                                                    <div className="offer_div2" >
                                                        {/* {loginUserData != null && loginUserData.id === item.user_id && (
                                                            <label className="status_btn" style={{
                                                                backgroundColor:
                                                                    item.is_complete === 1 ? "#D7DBFF" :
                                                                        item.is_approve === 0 ? "#5CF8FB7C" :
                                                                            item.is_approve === 2 ? "#FFA7A7" :
                                                                                item.is_active === 1 ? "#EEFFE9" :
                                                                                    item.is_active === 2 ? "#FFA7A7" : ""
                                                            }}>
                                                                {item.is_complete === 1 ? "Complete" :
                                                                    item.is_approve === 0 ? "Validating" :
                                                                        item.is_approve === 2 ? "Rejected" :
                                                                            item.is_active === 1 ? "Active" :
                                                                                item.is_active === 2 ? "Hold" : ""}
                                                            </label>
                                                        )} */}
                                                    </div>
                                                    <p>{item.onelinedescription.length > 50 ? `${item.onelinedescription.slice(0, 50)}...` : item.onelinedescription}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )) : <div className="col-12 mt-10 text-center">
                                        <strong style={{ fontSize: '20px' }}>No Data Found</strong>
                                    </div>}

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AdsModal />
            <CreateAdsModal />
            <CreateCouponModal />
            <AdsApprovalModal />
        </Layout>
    )
}

export default ManageAds;