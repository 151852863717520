import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeModal } from "../../store/slices/modalSignupSlice";
import { openModal as openLoginModal } from "../../store/slices/modalLoginSlice";
import { openModal as openOTPModal } from "../../store/slices/modalOTPSlice";
import { closeModal as closeOTPModal } from "../../store/slices/modalOTPSlice";
import { useForm, Controller } from "react-hook-form";
import { loginUser } from '../../store/slices/authUserSlice';

import {
  getStates,
  getCities,
  checkUnique,
  sendOTP,
  getPages,
  login,
  signUp,
} from "../../utils/api.service";
import {
  validateFile,
  TOAST_SUCCESS,
  TOAST_ERROR,
  storeAuthData
} from "../../utils/common.service";
import moment from "moment";
import { uploadBase64Image } from "../../utils/aws.service";
import {
  openModal as openImageCropper,
  closeModal as closeImageCropper,
} from "../../store/slices/imageCropperSlice";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/custom.css";
import countriesData from "../../assets/country_dial_info.json";
import { Link, useNavigate } from "react-router-dom";
// import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";

import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";

import { auth, googleProvider ,appleProvider} from "./FirebaseConfig"; 

const SignupModal = ({socialobj}) => {
 

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm();
  const extractCode = (input) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?onestens\.com\/?([A-Z0-9]+)$/i;
    const match = input.match(regex);
    return match ? match[1] : input;
  };

  const [provider, setProvider] = useState("");

  const [profile, setProfile] = useState(null);
const SocialData = localStorage.getItem('SocialProfileData')
const parsedSocialData = JSON.parse(SocialData);

const [SocialLogin, SetSocialLogin] = useState(false);

  const profile_data = useSelector((state) =>
    state.modalSignup.payload != null
      ? state.modalSignup.payload.profile_data
      : ''
  );

  const userAgent = navigator.userAgent;
  const oscpu = navigator.oscpu;

  const isModalOpen = useSelector((state) => state.modalSignup.isModalOpen);
  const dispatch = useDispatch();
  useEffect(() => {
    
    SetSocialLogin(localStorage.getItem('SocialLogin') == "true")

  }, [isModalOpen]); 

  const navigate =useNavigate()
   
  const handleCloseModalSignup = () => {
    dispatch(closeModal());
    setValue('email', "");
    setValue('first_name', "");
    setValue('last_name', "");
    
  };

  const handleOpenModalLogin = () => {
    dispatch(openLoginModal());
    handleCloseModalSignup();
  };

  const handleOpenModalOTP = (payloadSignup) => {
    dispatch(
      openOTPModal({
        params: payloadSignup,
        otpFor: "mobile",
        action: "signup",
      })
      
    );
  };


  // Image Cropper Starts
  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(
    process.env.REACT_APP_PUBLIC_URL + "assets/images/default.png"
  );

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      const croppedImageBase64 = croppedCanvas.toDataURL();

      setCroppedImage(croppedImageBase64);
      handleCloseImageCropper();

      setValue("profileImage", croppedImageBase64);
    }
  };

  const isCropperOpen = useSelector((state) => state.imageCropper.isModalOpen);

  const handleOpenImageCropper = () => {
    dispatch(openImageCropper());
  };

  const handleCloseImageCropper = () => {
    dispatch(closeImageCropper());
  };
  // Image Cropper Ends

  const [selectedFile, setSelectedFile] = useState(
    process.env.REACT_APP_PUBLIC_URL + "assets/images/default.png"
  );
  const [fileExtension, setFileExtension] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      
      const user = result.user;
      const { providerData, email } = user;
      
      let user_name = providerData[0].displayName;
      let first_name = "";
      let last_name = "";
    
      if (user_name) {
        const nameParts = user_name.split(" ");
        first_name = nameParts[0]; 
        last_name = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";  
      }
      
      let user_email = email ? email : providerData[0].email;
      let user_profile_picture = providerData[0].photoURL;
      const socialobj = {

        first_name: first_name,
        last_name:last_name,
        login_type: "G",
        device_token: "0",
        device_type: "W",
        social_id: user.uid,  
        email: user_email,
        profile_picture: user_profile_picture,
        is_18_above: 1,
      };
      
      SetSocialLogin(true)

      setProfile(socialobj)
      localStorage.setItem('SocialProfileData', JSON.stringify(socialobj));
      login(socialobj).then(function (response) {
      if (response.code === "1") {
 
        setTimeout(() => {
            TOAST_SUCCESS(response.message);
          }, 100);
        handleCloseModalSignup();
        const authUserData = storeAuthData(response.data)
        dispatch(loginUser(authUserData));
        localStorage.setItem('reps_count', 0);
        navigate('/dashboard')
      } else if (response.code === "11") {
        // TOAST_ERROR(response.message);
      } else {
        TOAST_ERROR(response.message);
      }
    });
   
    } catch (error) {
      
    }
  };


  useEffect(() => {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      if (window.FB) {
        window.FB.init({
          appId: process.env.REACT_APP_FB_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: "v13.0",
        });
      } else {
        
      }
    };
  }, []);


  const stateChangeHandler = (e) => {
    if (e.target.value != undefined && e.target.value != "") {
      clearErrors("state");
      const selectedDataId =
        e.target.options[e.target.selectedIndex].getAttribute("data-id");

      let payload = {
        state_id: selectedDataId,
      };
      getCities(payload)
        .then(function (response) {
          if (response.code === "1") {
            setCities(response.data);
            setValue("city", "");
          } else if (response.code !== "0") {
            TOAST_ERROR(response.message);
          }
        })
        .catch(function (error) {
          
        });
    }
  };

  const handleFileInput = (e) => {
    let file = e.target.files[0];
    let imageSize = file.size / 1024;

    if (!validateFile(file.name, "image")) {
      setError("profileImage", {
        type: "manual",
        message: "Image must be jpg, jpeg, png type",
      });
    } else if (imageSize > 3072) {
      setError("profileImage", {
        type: "manual",
        message: "Please select a profile image smaller than 3MB",
      });
    } else {
      if (file) {
        let parts = file.name.split(".");
        let extension = parts[parts.length - 1];
        setFileExtension(extension);

        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target.result;
          setSelectedFile(base64String);
        };
        reader.readAsDataURL(file);
        clearErrors("profileImage");
        handleOpenImageCropper();
      }
    }
  };

  
  const onSubmit = async (data) => {
    data.social_id = (parsedSocialData && parsedSocialData.social_id) ? parsedSocialData.social_id : '';
    data.device_type = "W";


    if (isValid) {
      let file_name = "";

      // if (data.profileImage !== null) {
      //   file_name =
      //     Math.floor(Math.random() * 999999) +
      //     "_" +
      //     moment().format("X") +
      //     "." +
      //     fileExtension;

      //     let filePath = 
      //     process.env.REACT_APP_AWS_FOLDER_NAME + "/user/" + (fileExtension === null ? 'default.png' : file_name);
        
     
      //   uploadBase64Image(data.profileImage, filePath);
      // }

      let filePath = process.env.REACT_APP_AWS_FOLDER_NAME + "/user/" + (fileExtension === null ? 'default.png' : file_name);

      if (data.profileImage !== null) {

        if (fileExtension !== null) {

          file_name =
            Math.floor(Math.random() * 999999) +
            "_" +
            moment().format("X") +
            "." +
            fileExtension;
        } else {
          file_name = '';
        }

        let filePath = process.env.REACT_APP_AWS_FOLDER_NAME + "/user/" + file_name;

        uploadBase64Image(data.profileImage, filePath);
      }


      let payloadCheckUnique = {
        country_code: data.country_code,
        mobile_number: data.mobile_number,
        email: data.email,
      };

      checkUnique(payloadCheckUnique)
        .then(function (response) {
          if (response.code === "1") {
            let payloadSendOTP = {
              country_code: data.country_code,
              mobile_number: data.mobile_number,
              type: "signup",
            };

            if ( SocialLogin === "true" || SocialLogin === true || SocialLogin === "1" ) {

              data.login_type = parsedSocialData.login_type;
              data.social_id = parsedSocialData.social_id;
              data.date_of_birth = data.date_of_birth
              ? moment(data.date_of_birth).format("DD/MM/YYYY")
              : "";  
              
              signUp(data).then(function (response) {
                                
                if (response.code === "1") {
                  setTimeout(() => {
                    TOAST_SUCCESS(response.message);
                  }, 100);
                  localStorage.removeItem('SocialLogin')
                  localStorage.removeItem('SocialProfileData');
                  const authUserData = storeAuthData(response.data)
                  navigate('/dashboard')
                  handleCloseModalSignup();
                } else {
                  TOAST_ERROR(response.message);
                }
              });
            } else {
              sendOTP(payloadSendOTP)
                .then(function (response) {
                  
                  if (response.code === "1") {
                    const code = extractCode(data.referralCode);
                    let payloadSignup = {
                      first_name: data.first_name,
                      last_name: data.last_name,
                      user_name: data.user_name,
                      country_code: data.country_code,
                      mobile_number: data.mobile_number,
                      gender: data.gender,
                      email: data.email,
                      date_of_birth: data.date_of_birth
                        ? moment(data.date_of_birth).format("DD/MM/YYYY")
                        : "",
                      state: data.state,
                      city: data.city,
                      password: data.password,
                      login_type:'S', // here i define simple login type S
                      device_type: "W",
                      device_name: userAgent,
                      device_model: oscpu,
                      device_token: "0",
                      profile_image: file_name,
                      referral_code: code,
                    };
                    handleCloseModalSignup();
                    localStorage.removeItem('SocialProfileData');
                    handleOpenModalOTP(payloadSignup);

                    TOAST_SUCCESS(response.message);
                  } else if (response.code !== "0") {
                    TOAST_ERROR(response.message);
                  }else{
                    TOAST_ERROR(response.message);
                  }
                })
                .catch(function (error) {
                  
                });
            }
          } else if (response.code !== "0") {
            TOAST_ERROR(response.message);
          }else{
            TOAST_ERROR(response.message);
          }
        })
        .catch(function (error) {
          
        });
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      reset();
      setSelectedFile(
        process.env.REACT_APP_PUBLIC_URL + "assets/images/default.png"
      );
      let payload = {};
      getStates(payload)
        .then(function (response) {
          if (response.code === "1") {
            setStates(response.data);
          } else if (response.code !== "0") {
            TOAST_ERROR(response.message);
          }
        })
        .catch(function (error) {
          
        });
    }
  }, [isModalOpen]);

  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear() - 0,
      today.getMonth(),
      today.getDate()
    );

    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const [privacyPolicyContent, setPrivacyPolicyContent] = useState("");
  const [temsConditionsContent, setTermsConditionsContent] = useState("");

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        getPages({})
          .then(function (response) {
            if (response.code === "1") {
              response.data.map((item, index) => {
                if (item.alies == "privacy-policy") {
                  setPrivacyPolicyContent(item.content);
                } else if (item.alies == "terms-conditions") {
                  setTermsConditionsContent(item.content);
                }
              });
            }
          })
          .catch(function (error) {
            
          });
      } catch (error) {
        
      }
    };

    fetchPrivacyPolicy();
  }, []);



  const handleFacebookLoginFailure = async (error) => {
    TOAST_ERROR("Facebook Login Failed");
  };


  const handleFacebookLogin = async (user) => {
    try {
      TOAST_SUCCESS('facebook success')
    } catch (error) {
      
    }
  };


  return (
    <>
      <Modal show={isModalOpen}>
        <Modal.Body>
          <button
            type="button"
            className="custom_btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseModalSignup}
          >
            X
          </button>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="slider_rutorial text-center">
                  <Link to="#" className="mb-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "assets/images/logo.png"
                      }
                      alt=""
                      className="logo w-200-login logo_1"
                    />{" "}
                  </Link>

                  <h1>Sign Up</h1>
                  <h6>Let’s Get Started</h6>
                </div>
              </div>

              <div className="row text-center">
                {/* <LoginSocialGoogle
                  className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6"
                  > */}
                 { localStorage.getItem("SocialLogin") != "true" && <div>
                    <a
                      className="login_with inter font_17"
                      style={{ cursor: "pointer" }}
                      onClick={handleGoogleSignIn}
                    >
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/google.svg`}
                        className="me-2"
                        alt="google"
                        style={{ width: "200px", height: "50px" ,marginLeft:"10%"}}
                      />
                    </a>
                  </div>}
                {/* </LoginSocialGoogle> */}

                {/* <LoginSocialFacebook
                  appId={process.env.REACT_APP_FB_APP_ID}
                  fieldsProfile="id,name,email,birthday,gender,location,friends,relationship_status"
                  version="v.0"
                  scope="public_profile,email,user_friends,user_about_me"
                  onResolve={({ provider, data }) => {
                    setProvider(provider);
                    setProfile(data); 
                  }}
                  onReject={(err) => {
                  }}
                  className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6"
                >
                  <div>
                    <a className="login_with inter font_17">
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/facebook.png`}
                        className="me-2"
                        alt="facebook"
                      />
                      
                    </a>
                  </div>
                </LoginSocialFacebook> */}
                
                {/* <LoginSocialFacebook
                      appId={process.env.REACT_APP_FB_APP_ID}
                      scope="public_profile,email"
                      onResolve={(response) => {
                        const user = response?.data;
                        handleFacebookLogin(user);
                      }}
                      onReject={handleFacebookLoginFailure}
                    >  <div>
                    <a className="login_with inter font_17" style={{ cursor: "pointer" }}>
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/facebook.png`}
                        className="me-2"
                        alt="facebook"
                      />
                    </a>
                  </div>
                    </LoginSocialFacebook> */}
              </div>

              <div className="col-md-12 mb-3 mt-5 ">
                <div className="file_upload">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileInput}
                  />
                  <Controller
                    name="profileImage"
                    control={control}
                    defaultValue={profile?.picture || ""}
                    render={({ field }) => <></>}
                  />
                  <img
                    src={croppedImage}
                    className="uploaded_img"
                    style={{ borderRadius: "50%" }}
                  />
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/edit.svg"
                    }
                    className="edit_img"
                  />
                </div>
                <label className="error">{errors.profileImage?.message}</label>
              </div>
              <input
                type="hidden"
                name="social_id"
                value={
                  profile_data != null && profile_data.sub != ""
                    ? profile_data.sub
                    : ""
                }
              />
              <div className="col-md-6 col-sm-6 col-12 mb-3">
                <div className="form-group fixed_icon w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    defaultValue={parsedSocialData?.first_name || profile?.first_name || ""}
                    {...register("first_name", {
                      required: "Please enter first name",
                      pattern: {
                        value: /^[a-zA-Z]*$/,
                        message:
                          "Please enter only letters (A-Z, a-z) with no special characters or numbers",
                      },
                      maxLength: {
                        value: 32,
                        message: "First name must not exceed 32 characters",
                      },
                      minLength: {
                        value: 2,
                        message: "First name must be atleast 2 characters long",
                      },
                    })}
                  />
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/name.svg"
                    }
                    className="front_icon logo_1"
                  />
                </div>
                <label className="error">{errors.first_name?.message}</label>
              </div>
              <div className="col-md-6 col-sm-6 col-12 mb-3">
                <div className="form-group fixed_icon w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    defaultValue={parsedSocialData?.last_name || profile?.last_name || ""}
                    {...register("last_name", {
                      required: "Please enter last name",
                      pattern: {
                        value: /^[a-zA-Z]*$/,
                        message:
                          "Please enter only letters (A-Z, a-z) with no special characters or numbers",
                      },
                      maxLength: {
                        value: 32,
                        message: "Last name must not exceed 32 characters",
                      },
                      minLength: {
                        value: 2,
                        message: "Last name must be atleast 2 characters long",
                      },
                    })}
                    autoComplete="off"
                  />
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/name.svg"
                    }
                    className="front_icon logo_1"
                  />
                </div>
                <label className="error">{errors.last_name?.message}</label>
              </div>
              <div className="col-md-12 mb-3 ">
                <div className="form-group fixed_icon w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    autoComplete="off"
                    {...register("user_name", {
                      required: "Please enter username",
                      pattern: {
                        value: /^[a-zA-Z0-9]*$/,
                        message:
                          "Please enter only letters & numbers with no special characters",
                      },
                      maxLength: {
                        value: 20,
                        message: "Username must not exceed 20 characters",
                      },
                      minLength: {
                        value: 2,
                        message: "Username must be at least 2 characters long",
                      },
                    })}
                  />

                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/name.svg"
                    }
                    className="front_icon logo_1"
                  />
                </div>
                <label className="error">{errors.user_name?.message}</label>
              </div>
              <div className="col-md-12 mb-3 d-flex">
                <div className="form-group fixed_icon w-100">
                  <div className="mobile_div_1">
                    <select
                      className="form-select"
                      defaultValue={"+91"}
                      {...register("country_code", {
                        required: "Please select country code",
                      })}
                      style={{ paddingRight: "30px" }}
                    >
                      <option value="">Country Code</option>
                      {countriesData.map((item, index) => (
                        <option key={index} value={item.dial_code}>
                          {item.dial_code + " " + item.name.substring(0, 15)}
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Mobile Number"
                      {...register("mobile_number", {
                        required: "Please enter mobile number",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Please enter only numbers",
                        },
                        minLength: {
                          value: 8,
                          message:
                            "Please enter minimum 8 & maximum 10 digits for mobile number",
                        },
                        maxLength: {
                          value: 10,
                          message:
                            "Please enter minimum 8 & maximum 10 digits for mobile number",
                        },
                      })}
                      autoComplete="off"
                      style={{ paddingLeft: "15px" }}
                    />
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "assets/images/icon/mobile.svg"
                      }
                      className="front_icon logo_1"
                    />
                  </div>
                  <label className="error">
                    {errors.country_code != undefined
                      ? errors.country_code?.message
                      : errors.mobile_number?.message}
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3 d-flex">
                <div className="form-group fixed_icon w-100">
                  <div className="mobile_div_1">
                    <select
                      className="form-select"
                      {...register("gender", {
                        required: "Please select gender",
                      })}
                      autoComplete="off"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>

                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "assets/images/icon/gender.svg"
                      }
                      className="front_icon logo_1"
                    />
                  </div>
                  <label className="error">{errors.gender?.message}</label>
                </div>
              </div>
              <div className="col-md-12 mb-3 d-flex">
                <div className="form-group fixed_icon w-100">
                  <div className="mobile_div_1">
                    <select
                      className="form-select"
                      {...register("state", {
                        required: "Please select state",
                      })}
                      onChange={stateChangeHandler}
                      autoComplete="off"
                    >
                      <option value="">Select State</option>
                      {states.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item.name}
                            data-id={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>

                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "assets/images/icon/location.svg"
                      }
                      className="front_icon logo_1"
                    />
                  </div>
                  <label className="error">{errors.state?.message}</label>
                </div>
              </div>
              <div className="col-md-12 mb-3 d-flex">
                <div className="form-group fixed_icon w-100">
                  <div className="mobile_div_1">
                    <select
                      className="form-select"
                      {...register("city", { required: "Please select city" })}
                      autoComplete="off"
                    >
                      <option value="">Select City</option>
                      {cities.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item.city_name}
                            data-id={item.id}
                          >
                            {item.city_name}
                          </option>
                        );
                      })}
                    </select>

                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "assets/images/icon/location.svg"
                      }
                      className="front_icon logo_1"
                    />
                  </div>
                  <label className="error">{errors.city?.message}</label>
                </div>
              </div>
              <div className="col-md-12 mb-3 ">
                <div className="form-group fixed_icon w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    defaultValue={parsedSocialData?.email ||profile?.email || ""}
                    {...register("email", {
                      required: "Please enter email address",
                      pattern: {
                        value:
                          /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
                        message: "Please enter a valid email address",
                      },
                    })}
                    readOnly = {parsedSocialData?.login_type == 'G' || parsedSocialData?.login_type == 'F' ||  profile?.login_type == 'G' || profile?.login_type == 'F'}

                  />
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/sms.svg"
                    }
                    className="front_icon logo_1"
                  />
                </div>
                <label className="error">{errors.email?.message}</label>
              </div>
              <div className="col-md-12 mb-3 ">
                <div className="form-group fixed_icon w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Referral Key"
                    {...register("referralCode", { required: false })}
                    autoComplete="off"
                  />
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/referral-code.svg"
                    }
                    className="front_icon logo_1"
                  />
                </div>
                <label className="error">{errors.referralCode?.message}</label>
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-group fixed_icon back_icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    {...register("password", {
                      required: "Please enter password",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                    })}
                    autoComplete="new-password"
                  />
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/lock.svg"
                    }
                    className="front_icon logo_1"
                  />
                  {showPassword ? (
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "assets/images/icon/eye.svg"
                      }
                      className="end_icon logo_1"
                      onClick={handleTogglePassword}
                    />
                  ) : (
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "assets/images/icon/eye-slash.svg"
                      }
                      className="end_icon logo_1"
                      onClick={handleTogglePassword}
                    />
                  )}
                </div>
                <label className="error">{errors.password?.message}</label>
              </div>
              <div className="col-md-12 mb-3 ">
                <div className="form-group fixed_icon w-100">
                  <DatePicker
                    customInput={
                      <input
                        className="form-control"
                        {...register("date_of_birth", {
                          required: "Please select date of birth",
                        })}
                      />
                    }
                    wrapperClassName="custom-datepicker-wrapper"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select date of birth"
                    selected={getValues("date_of_birth")}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    onChange={(date) =>
                      setValue("date_of_birth", date, { shouldValidate: true })
                    }
                    maxDate={getMaxDate()}
                    autoComplete="off"
                  />
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "assets/images/icon/calendar.svg"
                    }
                    className="front_icon logo_1"
                  />
                </div>
                <label className="error">{errors.date_of_birth?.message}</label>
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    {...register("terms", {
                      required: "Please agree with terms & conditions",
                    })}
                  />
                  <label
                    className="form-check-label modal-text"
                    htmlFor="flexCheckDefault"
                  >
                    I agree with{" "}
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="fw-bold"
                    >
                      Terms and Conditions{" "}
                    </a>
                  </label>
                </div>
                <label className="error">{errors.terms?.message}</label>
              </div>
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "auto",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid transparent",
                  borderRadius: "10px",
                  padding: "10px 17px",
                  width: "93%",
                  margin: "0 auto",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: temsConditionsContent }}
                />
              </div>

              <div className="col-md-12 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault2"
                    {...register("privacy", {
                      required: "Please agree with  privacy policy",
                    })}
                  />
                  <label
                    className="form-check-label modal-text"
                    htmlFor="flexCheckDefault2"
                  >
                    I agree with{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="fw-bold"
                    >
                      Privacy Policy.{" "}
                    </a>
                  </label>
                </div>
                <label className="error">{errors.privacy?.message}</label>
              </div>
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "auto",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid transparent",
                  borderRadius: "10px",
                  padding: "10px 17px",
                  width: "93%",
                  margin: "0 auto",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: privacyPolicyContent }}
                />
              </div>

              <div className="col-md-12 mb-5 mt-3">
                <button type="submit" className="btn btn-theme mt-auto w-100">
                  Sign Up
                </button>
              </div>

              <div className="col-md-12 text-center mt-5 mb-3">
                <span className="text-theme font-16 modal-text">
                  Already have an account ?{" "}
                  <a className="fw-bold" onClick={handleOpenModalLogin}>
                    Log In
                  </a>
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={isCropperOpen}>
        <Modal.Body>
          <button
            type="button"
            className="custom_btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseImageCropper}
          >
            X
          </button>

          <Cropper
            ref={cropperRef}
            src={selectedFile}
            style={{ height: 400, width: "100%" }}
            aspectRatio={1 / 1}
            guides={true}
            dragMode="none"
          />

          <div className="col-md-12 mb-5 mt-5">
            <button
              onClick={handleCrop}
              className="btn btn-theme mt-auto w-100"
            >
              Crop Image
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignupModal;
