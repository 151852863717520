import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AddBrand, CategoryListDeatils, SubCategoryList } from '../../utils/api.service';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/common.service';
import { useSelector } from 'react-redux';

function Brand({ show, setBrandShow }) {
  
  const { register, setValue,handleSubmit, formState: { errors },reset } = useForm();
  const [categoryList, setCategoryList] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');


  const handleClose = () => {
    setBrandShow(false);
    
    reset()
  } 

  useEffect(() => {
    CategoryListDeatils({}).then(response => {
      if (response.code === '1') {
        setCategoryList(response?.data);
       
      } else {
        TOAST_ERROR(response?.message);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      SubCategoryList({ category_id: selectedCategory }).then(response => {
        if (response.code === '1') {
          setFilteredSubcategories(response?.data);
        } else {
          TOAST_ERROR(response.message);
        }
      });
    } else {
      setFilteredSubcategories([]);
    }
  }, [selectedCategory]);


  const darkMode = useSelector((state) => state.theme.themeValue);

  const buttonStyle = {
    backgroundColor: darkMode === 'theme-dark' ? 'white' : 'black',
    color: darkMode === 'theme-dark' ? 'black' : 'white',
    borderColor: darkMode === 'theme-dark' ? 'white' : 'black',
  };

  const onSubmit = (data) => {
   
    AddBrand(data).then(response => {
      if (response.code === '1') {
        TOAST_SUCCESS(response?.message);
        setValue('category_id','')
        setValue('sub_category_id','')
        setValue('name','')
        handleClose();

      } else {
        TOAST_ERROR(response?.message);
      }
    });
    handleClose();
    //   const handleClose = () => {
    //   // Reset specific form fields when modal is closed
    //   setValue('category_id', '');
    //   setValue('sub_category_id', '');
    //   setValue('name', '');
  
    // };
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Brand</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
    <Modal.Body>
    <div className="form-group mt-3">
      <label htmlFor="category_id">Category Name</label>
      <select
        id="category_id"
        className={`form-control ${errors.category_id ? 'is-invalid' : ''}`}
        {...register('category_id', { required: 'Category ID is required' })}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="">Select a category</option>
        {categoryList.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      {errors.category_id && (
        <div className="invalid-feedback">
          {errors.category_id.message}
        </div>
      )}
    </div>

    {/* Subcategory Name Dropdown */}
    <div className="form-group mt-3">
      <label htmlFor="subcategory_id">Subcategory Name</label>
      <select
        id="subcategory_id"
        className={`form-control ${errors.sub_category_id ? 'is-invalid' : ''}`}
        {...register('sub_category_id', { required: 'Subcategory ID is required' })}
      >
        <option value="">Select a subcategory</option>
        {filteredSubcategories.map((subcategory) => (
          <option key={subcategory.id} value={subcategory.id}>
            {subcategory.name}
          </option>
        ))}
      </select>
      {errors.sub_category_id && (
        <div className="invalid-feedback">
          {errors.sub_category_id.message}
        </div>
      )}
    </div>

    {/* Brand Name Field */}
    <div className="form-group mt-3">
      <label htmlFor="brand_name">Brand Name</label>
      <input
        id="brand_name"
        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
        {...register('name', { 
          required: 'Brand Name is required', 
          pattern: {
            value: /^[^\d\p{Emoji}]*$/u, // Disallow numbers and emojis
            message: 'Brand Name cannot contain numbers or emojis'
          } 
        })}
        placeholder="Enter brand name"
      />
      {errors.name && (
        <div className="invalid-feedback">
          {errors.name.message}
        </div>
      )}
    </div>
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button style={buttonStyle}  type="submit">
      Submit
    </Button>
  </Modal.Footer>
</form>

    </Modal>
  );
}

export default Brand;
