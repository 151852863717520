import AWS from 'aws-sdk'

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_DEFAULT_REGION;


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

// export const uploadFile = (file, filePath) => {

//     const params = {
//         ACL: 'public-read',
//         Body: file,
//         Bucket: S3_BUCKET,
//         Key: filePath
//     };

//     myBucket.putObject(params).on('httpUploadProgress', (evt) => {
//         // setProgress(Math.round((evt.loaded / evt.total) * 100))
//     }).send((err) => {
//         // if (err) 
//     })
// }

export const uploadFile = async (file, filePath) => {
    const params = {
        ACL: 'public-read',
        Body: file,
        Bucket: S3_BUCKET,
        Key: filePath
    };

    const uploadPromise = myBucket.putObject(params).promise();

    try {
        await uploadPromise;
        
    } catch (err) {
        
        throw err; // Rethrow the error to handle it in the calling function
    }
};

// Function to upload base64 image to S3 bucket
export const uploadBase64Image = async (base64Data, filePath) => {
    const base64Image = base64Data.split(';base64,').pop();

    const binaryData = new Uint8Array(atob(base64Image).split('').map(char => char.charCodeAt(0)));

    const params = {
        ACL: 'public-read',
        Body: binaryData,
        Bucket: S3_BUCKET,
        Key: filePath,
        ContentEncoding: 'base64',
    };

    try {
        const result = await myBucket.upload(params).promise();
        // 
    } catch (error) {
        // 
    }
};