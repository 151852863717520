import { createSlice } from "@reduxjs/toolkit";

const ModalSignupSlice = createSlice({
    name: 'modalSignup',
    initialState: {
        isModalOpen: false,
    },
    reducers: {
        openModal: (state,action) => {
            
            state.isModalOpen = true;
            state.payload = action.payload;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = ModalSignupSlice.actions;
export default ModalSignupSlice.reducer;