import React, { useEffect, useState } from 'react';

const AdsenseComponent = ({ adClient, adSlot, adFormat }) => {

    const [adLoaded, setAdLoaded] = useState(false);

    useEffect(() => {
        if (window.adsbygoogle && !window.adsbygoogle.loaded) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            setAdLoaded(true);
        }
        // else{
        //     setAdLoaded(false);
        // }
    }, []);

    return (
        <>
            {adLoaded ? <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client={adClient}
                data-ad-slot={adSlot}
                data-ad-format={adFormat}
                data-full-width-responsive="true"></ins>
                : <img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/Google_Ads_CARD.jpg'} alt='' />}
        </>
    );
};

export default AdsenseComponent;