import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import '@tensorflow/tfjs-backend-webgl';

let model, video, event, blinkRate;
const VIDEO_SIZE = 500;
let blinkCount = 0;
let tempBlinkRate = 0;
let rendering = true;
let rateInterval;
const EAR_THRESHOLD = 0.27;

function initBlinkRateCalculator() {
  rateInterval = setInterval(() => {
    blinkRate = tempBlinkRate * 6;
    tempBlinkRate = 0;
  }, 10000);
}

const loadModel = async () => {
  try {
    model = await faceLandmarksDetection.load(
      faceLandmarksDetection.SupportedPackages.mediapipeFacemesh,
      { maxFaces: 1 }
    );
    // 
  } catch (error) {
    
  }
};

const setUpCamera = async (videoElement) => {
  video = videoElement;

  if (video !== null) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user',
          width: { ideal: VIDEO_SIZE },
          height: { ideal: VIDEO_SIZE },
        },
      });

      video.srcObject = stream;
      video.setAttribute('autoplay', true);
      video.setAttribute('muted', true);
      video.setAttribute('playsinline', true); // This is important for iOS Safari

      await new Promise((resolve, reject) => {
        video.onloadedmetadata = () => {
          video.play().then(resolve).catch(reject);
        };
        video.oncanplay = () => {
          if (video.videoWidth > 0 && video.videoHeight > 0) {
            resolve();
          } else {
            reject(new Error("Video dimensions are not valid"));
          }
        };
      });

      initBlinkRateCalculator();
      // checkVideoFeed();

      return video;
    } catch (error) {
      
      alert('Error accessing camera: ' + error.message);
    }
  }
};

const checkVideoFeed = () => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = VIDEO_SIZE;
  canvas.height = VIDEO_SIZE;
  context.drawImage(video, 0, 0, VIDEO_SIZE, VIDEO_SIZE);
  document.body.appendChild(canvas); // Append the canvas to the body to visually inspect the video feed
  

};

function stopPrediction() {
  rendering = false;
  clearInterval(rateInterval);
}

function restartPrediction() {
  rendering = true;
  initBlinkRateCalculator();
}

function updateBlinkRate() {
  tempBlinkRate++;
}

function getEucledianDistance(x1, y1, x2, y2) {
  return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
}

function getEAR(upper, lower) {
  return (
    (getEucledianDistance(upper[5][0], upper[5][1], lower[4][0], lower[4][1]) +
      getEucledianDistance(
        upper[3][0],
        upper[3][1],
        lower[2][0],
        lower[2][1]
      )) /
    (2 *
      getEucledianDistance(upper[0][0], upper[0][1], upper[8][0], upper[8][1]))
  );
}

function isVoluntaryBlink(blinkDetected) {
  if (blinkDetected) {
    blinkCount++;
    if (blinkCount > 4) {
      blinkCount = 0;
      return true;
    }
  } else {
    blinkCount = 0;
  }
  return false;
}

async function renderPrediction(restart) {
  if (restart && video !== null) {
    rendering = true;
  }

  if (rendering && video !== null && video.videoWidth > 0 && video.videoHeight > 0) {
    try {
      // 
      const predictions = await model.estimateFaces({
        input: video,
        returnTensors: false,
        flipHorizontal: false,
        predictIrises: true,
      });
      // 

      if (predictions.length > 0) {
        // 
        predictions.forEach((prediction) => {
          let lowerRight = prediction.annotations.rightEyeUpper0;
          let upperRight = prediction.annotations.rightEyeLower0;
          const rightEAR = getEAR(upperRight, lowerRight);

          let lowerLeft = prediction.annotations.leftEyeUpper0;
          let upperLeft = prediction.annotations.leftEyeLower0;
          const leftEAR = getEAR(upperLeft, lowerLeft);

          let blinked = leftEAR <= EAR_THRESHOLD && rightEAR <= EAR_THRESHOLD;
          if (blinked) {
            updateBlinkRate();
          }
          event = {
            left: leftEAR <= EAR_THRESHOLD,
            right: rightEAR <= EAR_THRESHOLD,
            wink: leftEAR <= EAR_THRESHOLD || rightEAR <= EAR_THRESHOLD,
            blink: blinked,
            longBlink: isVoluntaryBlink(blinked),
            rate: blinkRate,
          };
        });
      } else {
        
        event = null;
      }
    } catch (error) {
      
      event = null;
    }
  }
  else{
    
    // event = null;
    await delay(500); // Wait for 500 milliseconds before retrying
    return await renderPrediction(restart);
  }
  return event;
}
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const blink = {
  loadModel: loadModel,
  setUpCamera: setUpCamera,
  stopPrediction: stopPrediction,
  getBlinkPrediction: renderPrediction,
  restartPrediction: restartPrediction,
};

export default blink;
