import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/adsSlice';
import { activateDeactivateAd, likeUnlikeAd } from '../../utils/api.service';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';
import { Link } from 'react-router-dom';

const AdsModal = () => {

    const isModalOpen = useSelector((state) => state.modalAds.isModalOpen);
    const params = useSelector((state) => state.modalAds.props != null ? state.modalAds.props.payload.params : null);
    const updateStatus = useSelector((state) => state.modalAds.props != null ? state.modalAds.props.payload.updateStatus : null);
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);
    const [isLiked, setIsLiked] = useState();

    const handleCloseModal = () => {
        dispatch(closeModal());
    };

    const handleActivateDeactivateAd = (ad_id, is_active) => {
        let payload = {
            ad_id: ad_id,
            is_active: is_active
        };
        activateDeactivateAd(payload).then(function (response) {
            if (response.code === '1') {
                setIsActive(is_active === 1 ? true : false);
                updateStatus(ad_id, is_active,isLiked);
                TOAST_SUCCESS(response.message);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }

    const handleLikeUnlikeAd = (ad_id) => {
        let payload = {
            ad_id: ad_id,
            is_like: isLiked === 0 ? 1 : 0,
        };
        likeUnlikeAd(payload).then(function (response) {
            if (response.code === '1') {
                setIsLiked(prevState => prevState === 0 ? 1 : 0)
                updateStatus(ad_id,isActive, payload.is_like);
                TOAST_SUCCESS(response.message);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }
    useEffect(() => {
        if (isModalOpen) {
            
            setIsActive(params?.is_active ? true : false)
            setIsLiked(params?.is_like)


        }
    }, [isModalOpen, params])

    return (

        <Modal show={isModalOpen} size="md">
            <Modal.Body className='p-0 position-relative'>
                <button type="button" className="custom_btn" onClick={handleCloseModal}>X</button>

                <div className="view_video view_video_90vh">
                    {(params !== null && params?.ad_type_id === 'image') ? <img src={params?.image_video} alt="" /> :
                        <video autoPlay id="banner" controls>
                            <source src={params?.image_video} />
                        </video>}
                    <div className="view_btn_modal">
                        <Link to={`/impression-analytics/${params?.id}`} onClick={handleCloseModal} className="btn_impression">
                            View Impression
                        </Link>

                        {isActive ? <a onClick={() => handleActivateDeactivateAd(params?.id, 0)} className="btn_impression">
                            Deactivate
                        </a> : <a onClick={() => handleActivateDeactivateAd(params?.id, 1)} className="btn_impression">
                            Activate
                        </a>}

                        <div className="heart">
                            <Link onClick={() => handleLikeUnlikeAd(params?.id)}>
                                {isLiked === 1 ? (
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/like.png"} alt="Liked" className="" style={{width:'30px',height:'auto'}}/>
                                ) : (
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/unlike.png"} alt="Like" className="" style={{width:'30px',height:'auto'}}/>
                                )}
                            </Link>
                        </div>

                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default AdsModal;
