import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/modalTutorialSlice';
import { openModal } from '../../store/slices/modalLoginSlice';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

const TutorialModal = () => {

    const isModalOpen = useSelector((state) => state.modalTutorial.isModalOpen);
    const dispatch = useDispatch();

    const handleCloseModalTutorial = () => {
        dispatch(closeModal());
        handleOpenModalLogin();
    };

    const handleOpenModalLogin = () => {
        dispatch(openModal());
    };

    const options = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            600: {
                items: 1,
                nav: false
            },
            1000: {
                items: 1,
                nav: false,
                loop: true,
                margin: 20
            }
        }
    };

    const handleNextClick = () => {
        const owl = window.jQuery('.owl-carousel'); // Assuming jQuery is available
        owl.trigger('next.owl.carousel');
    };

    useEffect(() => {
        // 
    }, [])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" onClick={handleCloseModalTutorial}>X</button>

                <div className="row">
                    <div className="col-md-12">

                        <OwlCarousel className='owl-carousel owl-theme owl-tutorial' {...options}>
                            <div className="item">
                                <div className="slider_rutorial text-center">
                                    <Link className="mb-1 "><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/logo.png"} alt="" className="logo w-200-login logo_1" /></Link>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/slider_1.svg"} className="w-300" />
                                    <h1>watch ads and win <br />KUDOS AS MONEY!</h1>
                                    <h6>"Watch ads and earn money! Get rewarded for your time and attention."</h6>
                                    <Link className="btn btn-theme" onClick={handleNextClick}>Next</Link>
                                    <Link className="text-theme mt-3" onClick={handleCloseModalTutorial}>Skip</Link>
                                </div>
                            </div>
                            <div className="item">
                                <div className="slider_rutorial text-center">
                                    <Link className="mb-1 "><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/logo.png"} alt="" className="logo w-200-login logo_1" /></Link>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/service-1.svg"} className="w-128px" />

                                    <h6 className="mb-4">Click watch icon to watch ads and enroll into program, Watch 10 minutes to earn a rep, Earn multiple reps to get higher chance of winning.</h6>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/service-2.svg"} className="w-128px" />

                                    <h6>Click watch icon to watch ads and enroll into program, Watch 10 minutes to earn a rep, Earn multiple reps to get higher chance of winning.</h6>
                                    <Link className="btn btn-theme mt-auto" onClick={handleNextClick}>Next</Link>
                                    <Link className="text-theme mt-3" onClick={handleCloseModalTutorial}>Skip</Link>
                                </div>
                            </div>
                            <div className="item">
                                <div className="slider_rutorial text-center">
                                    <Link className="mb-1 "><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/logo.png"} alt="" className="logo w-200-login logo_1" /></Link>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/service-3.svg"} className="w-128px" />

                                    <h6 className="mb-4">Click winner icon to check out winners and winners can comment in it.</h6>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/service-4.svg"} className="w-128px" />

                                    <h6>Click winning icon to know your personal winning details.</h6>
                                    <Link className="btn btn-theme mt-auto" onClick={handleNextClick}>Next</Link>
                                    <Link className="text-theme mt-3" onClick={handleCloseModalTutorial}>Skip</Link>
                                </div>
                            </div>
                            <div className="item">
                                <div className="slider_rutorial text-center">
                                    <Link className="mb-1 "><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/logo.png"} alt="" className="logo w-200-login logo_1" /></Link>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/service-6.svg"} className="w-128px" />

                                    <h6 className="mb-4">Click share icon to share the platform with your friends and earn free reps.</h6>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/service-5.svg"} className="w-128px" />

                                    <h6>Insight , earn atleast one reps everyday to get higher chance of winning.</h6>
                                    <Link className="btn btn-theme mt-auto" onClick={handleCloseModalTutorial}>Login</Link>
                                    <Link className="text-theme mt-3" onClick={handleCloseModalTutorial}>Don't show again</Link>
                                </div>
                            </div>
                        </OwlCarousel>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TutorialModal;
