  import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/referFriendSlice';
import { Link } from 'react-router-dom';
import { TOAST_SUCCESS } from '../../utils/common.service';

const ReferFriendModal = () => {

    const isModalOpen = useSelector((state) => state.modalReferFriend.isModalOpen);
    const loginUserData = useSelector((state) => state.authUser.loginUserData);
    const dispatch = useDispatch();

    const handleCloseReferFriendModal = () => {
        dispatch(closeModal());
    };

    const [facebookShareUrl, setFacebookShareUrl] = useState('');
    const [twitterShareUrl, setTwitterShareUrl] = useState('');
    const [whatsappShareUrl, setWhatsappShareUrl] = useState('');

    const inputRef = useRef(null);

    const handleCopyClick = () => {
        inputRef.current.select();
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        TOAST_SUCCESS('Text Copied!');
    };

    useEffect(() => {
        // const textToShare = `It's a win-win! Sign up on Onestens and we'll both earn one Rep and may get exciting kudos like iPhone and coupons. My Referral Key : ${loginUserData?.my_referral_code}`;

        const textToShare = `Hey ɓuddy !! 
I came across this thing https://onestens.com/       
It's an ads streaming platform and the fun part is for every point we get discount for our shopping via COUPONS upto 99% so we may get exciting things like IPHONE by spending just 10 Minutes Weekly and don't forget to use my Referral Key to get a free point.
Referral Key :
https://onestens.com/${loginUserData?.my_referral_code}  


Copy above link and pest it into the referral code section `;

        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(textToShare)}`;
        // const urlToShare = 'https://www.facebook.com/sharer/sharer.php';
        // const facebookUrl = `https://www.facebook.com/dialog/share?app_id=3200601940195329&display=popup&quote=${encodeURIComponent(textToShare)}&href=${encodeURIComponent(urlToShare)}`;
        setFacebookShareUrl(facebookUrl);

        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(textToShare)}`;
        setTwitterShareUrl(tweetUrl);

        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(textToShare)}`;
        setWhatsappShareUrl(whatsappUrl);
    }, [loginUserData])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" onClick={handleCloseReferFriendModal}>X</button>

                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="slider_rutorial text-center">
                            <h1>Refer</h1>
                            <h6>Refer your friend and Earn Reps</h6>
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="slider_rutorial text-center">
                            <Link to="#" className="mb-3 ">
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/refer_friend.svg"} alt="" className="" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="slider_rutorial text-center">
                            <h6>
                                Save ten minutes of your time by sharing the platform with your circle and get one Rep for every successful sign up !!
                            </h6>
                        </div>
                    </div>
                    <div className="col-md-12 mb-3 d-flex">
                        <div className="form-group fixed_icon w-100 refer_friend_new">
                            <label>Your referral key</label>
                            <input ref={inputRef} readOnly type="text" className="form-control" value={loginUserData?.my_referral_code} placeholder={loginUserData?.my_referral_code} />
                            <button onClick={handleCopyClick}>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/copy.svg"} alt="" className="logo_1" />
                                Copy <br /> key
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 text-center mb-3">
                        <span className="text-theme font-16">Share your refferal key via</span>
                    </div>
                    <div className="col-md-12 text-center my-5">
                        <div className="d-flex justify-content-evenly">
                            <Link to={facebookShareUrl} target='_blank' rel='noopener noreferrer'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/facebook.png"} alt="" />
                            </Link>
                            <Link to={twitterShareUrl} target='_blank' rel='noopener noreferrer'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/twitter.png"} alt="" />
                            </Link>
                            <Link to={whatsappShareUrl} target='_blank' rel='noopener noreferrer'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/whatsapp.png"} alt="" />
                            </Link>
                        </div>
                    </div>
                    {/* <div className="col-md-12 mb-5">
                        <Link onClick={handleCopyClick} className="btn btn-theme mt-auto w-100">
                            Share
                        </Link>
                    </div> */}
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default ReferFriendModal;
