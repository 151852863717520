import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/adsApprovalSlice';
import { Link } from 'react-router-dom';

const AdsApprovalModal = () => {

    const isModalOpen = useSelector((state) => state.modalAdsApproval.isModalOpen);
    const modalPayload = useSelector((state) => state.modalAdsApproval.payload);
    const dispatch = useDispatch();

    const handleCloseAdsApprovalModal = () => {
        if (modalPayload !== null && modalPayload.redirectUrl !== undefined) {
            window.location.href = modalPayload.redirectUrl;
        }
        else {
            dispatch(closeModal());
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            //
        }
    }, [isModalOpen])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseAdsApprovalModal}>X</button>

                <div className="row">
                    <div className="col-md-12 mb-4 text-center">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/amico.svg"} alt='' />
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="slider_rutorial text-center">
                            <h1>Admin Approve</h1>
                            <h6>Your {modalPayload !== null && modalPayload.name !== undefined && modalPayload.name === 'Coupon-add' ? 'coupon' : 'advertisement'} has been submitted successfully. Please wait for admin approval. </h6>
                        </div>
                    </div>
                    <div className="col-md-12 mb-5 text-center">
                        <Link onClick={handleCloseAdsApprovalModal} className="btn btn-theme">
                            Done
                        </Link>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AdsApprovalModal;
