import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/resetPasswordSlice';
import { useForm } from 'react-hook-form';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';
import { resetPassword } from '../../utils/api.service';
import { openModal as openLoginModal } from '../../store/slices/modalLoginSlice';

const ResetPasswordModal = () => {

    const isModalOpen = useSelector((state) => state.modalResetPassword.isModalOpen);
    const params = useSelector((state) => state.modalResetPassword.props != null ? state.modalResetPassword.props.payload.params : null);
    const dispatch = useDispatch();

    const handleCloseResetModal = () => {
        dispatch(closeModal());
    };

    const handleOpenModalLogin = () => {
        dispatch(openLoginModal());
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        reset,
        control,
        setError,
        clearErrors,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {

        
        let payloadResetPassword = {...params};
        payloadResetPassword.password = data.password;

        resetPassword(payloadResetPassword).then(function (response) {
            if (response.code === '1') {

                handleCloseResetModal()
                handleOpenModalLogin()

                TOAST_SUCCESS(response.message);

            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    };

    // Validate confirm password field
    const validateConfirmPassword = (value) => {
        const password = watch('password', ''); // Get the value of the password field
        return value === password || 'Your new password & confirm new password does not match';
    };


    useEffect(() => {
        if (isModalOpen) {
            reset();
        }
    }, [isModalOpen])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseResetModal}>X</button>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="slider_rutorial text-center">
                                <a href="#" className="mb-3">
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/logo.png"} alt="" className="logo w-200-login logo_1" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4 text-center">
                            <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/reset.svg"} alt="" />
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="slider_rutorial text-center">
                                <h1>Set New Password</h1>
                                <h6>Your new password must be  different from  your previous password.</h6>
                            </div>
                        </div>

                        <div className="col-md-12 mb-3">
                            <div className="form-group fixed_icon back_icon">
                                <input type={showPassword ? 'text' : 'password'} className="form-control" placeholder="New Password" {...register("password", { required: "Please enter new password", minLength: { value: 6, message: 'New password must be at least 6 characters'} })} autoComplete="new-password" />
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/lock.svg"} className="front_icon logo_1" />
                                {showPassword
                                    ? <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/eye.svg"} className="end_icon logo_1" onClick={handleTogglePassword} />
                                    : <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/eye-slash.svg"} className="end_icon logo_1" onClick={handleTogglePassword} />}
                            </div>
                            <label className="error">{errors.password?.message}</label>
                        </div>

                        <div className="col-md-12 mb-3">
                            <div className="form-group fixed_icon back_icon">
                                <input type={showConfirmPassword ? 'text' : 'password'} className="form-control" placeholder="Confirm New Password" {...register("confirmPassword", {
                                    required: "Please enter confirm new password", 
                                    minLength: { value: 6, message: 'Confirm new password must be at least 6 characters'},
                                    validate: validateConfirmPassword
                                })} autoComplete="new-password" />
                                <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/lock.svg"} className="front_icon logo_1" />
                                {showConfirmPassword
                                    ? <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/eye.svg"} className="end_icon logo_1" onClick={handleToggleConfirmPassword} />
                                    : <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/eye-slash.svg"} className="end_icon logo_1" onClick={handleToggleConfirmPassword} />}
                            </div>
                            <label className="error">{errors.confirmPassword?.message}</label>
                        </div>

                        <div className="col-md-12 mb-5">
                            <button type="submit" className="btn btn-theme mt-auto w-100">Update</button>
                        </div>

                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ResetPasswordModal;
