import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { openModal as openSignupModal } from "../store/slices/modalSignupSlice";
import { openModal as openLoginModal } from "../store/slices/modalLoginSlice";
import { Link as LinkScroll } from "react-scroll";

const Header = () => {

    const dispatch = useDispatch();

    const handleOpenModalSignup = () => {
        dispatch(openSignupModal());
    };

    const handleOpenModalLogin = () => {
        dispatch(openLoginModal());
    };

    const handleToggleTheme = (e) => {
        if (localStorage.getItem('theme') === 'theme-dark') {
            localStorage.setItem('theme', 'theme-light');
            document.documentElement.className = 'theme-light';
            setImageInvert({});
            setAnchorColor({});
        } else {
            localStorage.setItem('theme', 'theme-dark');
            document.documentElement.className = 'theme-dark';
            setImageInvert({ filter: 'invert(1)' });
            setAnchorColor({ color: '#fff' });
        }
    }

    const pathName = window.location.pathname;
    const [isHomeLink, setIsHomeLink] = useState(false);
    const [imageInvert, setImageInvert] = useState({});
    const [anchorColor, setAnchorColor] = useState({});
    const [isSticky, setIsSticky] = useState(false);

    const handleHomeSection = (sectionId) => {
        localStorage.setItem('homeSection', sectionId);
    }

    useEffect(() => {
        if (pathName === '' || pathName === '/') {
            setIsHomeLink(false)
        }
        else if (pathName === '/contact-us' || pathName === '/privacy-policy' || pathName === '/faqs' || pathName === '/about-us' || pathName === '/terms-and-conditions') {
            setIsHomeLink(true)
           
            if (localStorage.getItem('theme') === 'theme-dark') {
                setImageInvert({ filter: 'invert(1)' });
                setAnchorColor({ color: '#fff' });
            }
        }
        else {
            setIsHomeLink(true)
            setImageInvert({})
            setAnchorColor({})
        }

        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pathName])

    return (
        <>
            <div className="header-one header-two header-three header-sticky">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6 col-sm-7">
                            <div className="header-one__logo">
                                {isHomeLink ? <Link to={'/'}><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/logo.png"} alt="" className="logo" style={ imageInvert } /></Link>
                                    : <LinkScroll to="home_section" smooth={true} duration={500} className="nav-link"><img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/logo.png"} alt="" className="logo" style={isSticky ? {} : imageInvert} /></LinkScroll>}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-5">
                            <div className="header-one__menu">
                                <div className="collapse show navbar-collapse sub-menu-bar main-nav__main-navigation stellarnav">
                                {(!isHomeLink) ?
                                    <ul className="main-nav__navigation-box ">
                                        <li className="nav-item dropdown">
                                            <LinkScroll to="home_section" smooth={true} duration={500} className="nav-link" style={isSticky ? {} : anchorColor}>Home</LinkScroll>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <LinkScroll to="services" smooth={true} duration={500} className="nav-link" style={isSticky ? {} : anchorColor}>Services</LinkScroll>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <LinkScroll to="how_it_works" smooth={true} duration={500} className="nav-link" style={isSticky ? {} : anchorColor}>How its Works</LinkScroll>
                                        </li>
                                        <li className="nav-item">
                                            <LinkScroll to="download_app" smooth={true} duration={500} className="nav-link" style={isSticky ? {} : anchorColor}>Download App</LinkScroll>
                                        </li>
                                    </ul>
                                      :<ul className="main-nav__navigation-box ">
                                      <li className="nav-item dropdown">
                                          <Link to="/" onClick={() => handleHomeSection('')} className="nav-link" style={isSticky ? {} : anchorColor}>Home</Link>
                                      </li>
                                      <li className="nav-item dropdown">
                                          <Link to="/" onClick={() => handleHomeSection('#services')} className="nav-link" style={isSticky ? {} : anchorColor}>Services</Link>
                                      </li>
                                      <li className="nav-item dropdown">
                                          <Link to="/" onClick={() => handleHomeSection('#how_it_works')} className="nav-link" style={isSticky ? {} : anchorColor}>How its Works</Link>
                                      </li>
                                      <li className="nav-item">
                                          <Link to="/" onClick={() => handleHomeSection('#download_app')} className="nav-link" style={isSticky ? {} : anchorColor}>Download App</Link>
                                      </li>
                                  </ul>}
                                      
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-7 d-lg-block d-none">
                            <div className="header-one__btn">
                                <ul className="list">
                                    <li className="item">
                                        <a onClick={handleOpenModalLogin} style={isSticky ? {} : anchorColor}>Log in</a><span className="me-2 ms-2">|</span><a onClick={handleOpenModalSignup} style={isSticky ? {} : anchorColor}>Register</a>
                                    </li>
                                    <li className="item">
                                        <div className="color_icore">
                                            {/* <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/color-pic.svg"} /> */}
                                            <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/color-picker.png"} />

                                            <label id="switch" className="switch hide_pic">
                                                <input type="checkbox" checked onChange={handleToggleTheme} id="slider" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;