import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/modalreCaptchaSlice';
import ReCAPTCHA from 'react-google-recaptcha';
import { TOAST_ERROR, SWAL_ALERT } from "../../utils/common.service";

const ReCaptchaModal = ({ onComplete }) => {
    const isModalOpen = useSelector((state) => state.ReCaptcha.isModalOpen);
    const dispatch = useDispatch();
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    useEffect(() => {
        if (isModalOpen) {
            setRecaptchaToken(null);
        }
    }, [isModalOpen]);

    const onRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        if (token) {
            onComplete(); // Call onComplete prop when reCAPTCHA is completed
        }
    };

    return (
        <Modal show={isModalOpen} size="md" centered>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onRecaptchaChange}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ReCaptchaModal;
