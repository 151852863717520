import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/claimSlice';
import { claimGift, getCities, getStates, getProfileDetail } from '../../utils/api.service';
import { useForm } from 'react-hook-form';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../utils/common.service';

const ClaimDetailModal = () => {

    const isModalOpen = useSelector((state) => state.modalClaim.isModalOpen);
    const params = useSelector((state) => state.modalClaim.props != null ? state.modalClaim.props.payload : { gift_type: null, campaign_id: null, gift_id: null });
    const dispatch = useDispatch();


    const handleCloseClaimDetailModal = () => {
        dispatch(closeModal());
    };

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const stateChangeHandler = (e) => {

        if (e.target.value != undefined && e.target.value != '') {
            clearErrors("state");
            const selectedDataId = e.target.options[e.target.selectedIndex].getAttribute('data-id');

            let payload = {
                state_id: selectedDataId
            };
            getCities(payload).then(function (response) {
                if (response.code === '1') {
                    setCities(response.data)
                    setValue("city", "");
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        }
    }

    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        reset,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {

        let payload = {
            gift_type: params.gift_type,
            campaign_id: params.campaign_id,
            gift_id: params.gift_id
        }

        if (params.gift_type === 'online gift') {
            payload.bank_name = data.bankName;
            payload.account_number = data.accountNumber;
            payload.holder_name = data.accountHolderName;
            payload.ifsc_code = data.ifscCode;
            payload.aadhaar_number = data.aadhaarNumber;
            payload.pan_number = data.panNumber;
        }
        else if (params.gift_type === 'physical gift') {
            payload.full_name = data.fullName;
            payload.country_code = data.countryCode;
            payload.mobile_no = data.mobileNumber;
            payload.email = data.email;
            payload.house_no = data.houseNumber;
            payload.area = data.area;
            payload.landmark = data.landmark;
            payload.pincode = data.pincode;
            payload.state = data.state;
            payload.city = data.city;
            payload.address_type = data.addressType;
        }
        else {
            payload.email = data.email;
        }

        claimGift(payload).then(function (response) {
            if (response.code === '1') {
                TOAST_SUCCESS(response.message);
                handleCloseClaimDetailModal();
                params.handleIsClaimedLabel();
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    };

    const fetchCities = (state_id, city = null) => {

        let payload = {
            state_id: state_id
        };
        getCities(payload).then(function (response) {
            if (response.code === '1') {
                setCities(response.data)
                setTimeout(() => {
                    if (city !== undefined && city !== null) {
                        setValue("city", city);
                    } else {
                        setValue("city", "");
                    }
                }, 500);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }

    const fetchProfileDetail = () => {
        let payload = {};
        getProfileDetail(payload).then(function (response) {
            if (response.code === '1') {

                if (params.gift_type === 'online gift') {
                    setValue("bankName", response.data?.bank_name);
                    setValue("accountNumber", response.data?.account_number);
                    setValue("accountHolderName", response.data?.holder_name);
                    setValue("ifscCode", response.data?.ifsc_code);
                    setValue("aadhaarNumber", response.data?.aadhaar_number);
                    setValue("panNumber", response.data?.pan_number);
                }
                else if (params.gift_type === 'physical gift') {
                    setValue("fullName", response.data?.fullname);
                    setValue("mobileNumber", response.data?.mobile_no);
                    setValue("email", response.data?.email_a);
                    setValue("houseNumber", response.data?.house_no);
                    setValue("area", response.data?.area);
                    setValue("landmark", response.data?.landmark);
                    setValue("pincode", response.data?.pincode);
                    setValue("state", response.data?.claimed_state);
                    setValue("addressType", response.data?.address_type);

                    const filteredStates = states.filter(obj => obj.name === response.data?.claimed_state);
                    const state_id = filteredStates[0] != undefined ? filteredStates[0].id : 0;
                    
                    fetchCities(state_id, response.data?.claimed_city);
                }
                else {
                    setValue("email", response.data?.claimed_email);
                }

            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }

    useEffect(() => {
        if (isModalOpen) {
            reset();
            let payload = {};
            getStates(payload).then(function (response) {
                if (response.code === '1') {
                    setStates(response.data);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        }
    }, [isModalOpen])

    useEffect(() => {
        if (isModalOpen) {
                        
            fetchProfileDetail()
        }
    }, [isModalOpen,states])

    return (

        <Modal show={isModalOpen}>
            <Modal.Body>
                <button type="button" className="custom_btn" onClick={handleCloseClaimDetailModal}>X</button>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">

                        {/* for vouchers gifts */}
                        {(params.gift_type !== null && params.gift_type === 'vouchers') && <><div className="col-md-12 mb-3 mt-3">
                            <div className="slider_rutorial text-center">
                                <h1>Add Email Address</h1>
                            </div>
                        </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("email", { required: "Please enter email address", pattern: { value: /^\S+@\S+$/i, message: "Please enter a valid email address" } })} autoComplete="off" placeholder="Email Address" />
                                </div>
                                <label className="error">{errors.email?.message}</label>
                            </div></>}

                        {/* for online gifts */}
                        {(params.gift_type !== null && params.gift_type === 'online gift') && <><div className="col-md-12 mb-3 mt-3">
                            <div className="slider_rutorial text-center">
                                <h1>Add Bank Details</h1>
                            </div>
                        </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("bankName", { required: "Please enter bank name", pattern: { value: /^[a-zA-Z0-9 ]*$/, message: "Please enter only letters & numbers with no special characters" }, maxLength: { value: 60, message: "Bank name must not exceed 60 characters" } })} autoComplete="off" placeholder="Bank Name" />
                                </div>
                                <label className="error">{errors.bankName?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("accountNumber", { required: "Please enter account number", pattern: { value: /^[0-9]*$/, message: "Please enter only numbers" }, minLength: { value: 8, message: "Account number must be 8-30 digits long" }, maxLength: { value: 30, message: "Account number must be 8-30 digits long" } })} autoComplete="off" placeholder="Account Number" />
                                </div>
                                <label className="error">{errors.accountNumber?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("accountHolderName", { required: "Please enter account holder name", pattern: { value: /^[a-zA-Z0-9 ]*$/, message: "Please enter only letters & numbers with no special characters" } })} autoComplete="off" placeholder="Account Holder Name" />
                                </div>
                                <label className="error">{errors.accountHolderName?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("ifscCode", { required: "Please enter IFSC code", pattern: { value: /^[a-zA-Z0-9]*$/, message: "Please enter only letters & numbers with no special characters & space" }, maxLength: { value: 25, message: "IFSC code must not exceed 25 characters" } })} autoComplete="off" placeholder="IFSC Code" />
                                </div>
                                <label className="error">{errors.ifscCode?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("aadhaarNumber", { required: "Please enter aadhaar number", pattern: { value: /^[0-9]*$/, message: "Please enter only numbers" }, minLength: { value: 12, message: "Aadhaar number must be 12 digits long" }, maxLength: { value: 12, message: "Aadhaar number must be 12 digits long" } })} autoComplete="off" placeholder="Aadhaar Number" />
                                </div>
                                <label className="error">{errors.aadhaarNumber?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("panNumber", { required: "Please enter PAN number", pattern: { value: /^[a-zA-Z0-9]*$/, message: "Please enter only letters & numbers with no special characters & space" }, maxLength: { value: 15, message: "PAN number must not exceed 15 characters" } })} autoComplete="off" placeholder="PAN Number" />
                                </div>
                                <label className="error">{errors.panNumber?.message}</label>
                            </div></>}

                   
                        {(params.gift_type !== null && params.gift_type === 'physical gift') && <><div className="col-md-12 mb-3 mt-3">
                            <div className="slider_rutorial text-center">
                                <h1>Add Address</h1>
                            </div>
                        </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("fullName", { required: "Please enter full name", pattern: { value: /^[a-zA-Z\s]*$/, message: "Please enter only letters with no special characters & spaces" }, maxLength: { value: 30, message: "Full name must not exceed 30 characters" } })} autoComplete="off" placeholder="Full Name" />
                                </div>
                                <label className="error">{errors.fullName?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("email", { required: "Please enter email address", pattern: { value: /^\S+@\S+$/i, message: "Please enter a valid email address" } })} autoComplete="off" placeholder="Email Address" />
                                </div>
                                <label className="error">{errors.email?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group w-100">
                                    <div className="mobile_div_1">
                                        <select className="form-select" {...register("countryCode", { required: "Please select country code" })} style={{ paddingLeft: '0.75rem' }}>
                                            <option value={'+91'}>+91</option>
                                        </select>
                                        <input type="text" className="form-control" placeholder="Mobile No" {...register("mobileNumber", {
                                            required: "Please enter mobile number", pattern: { value: /^[0-9]*$/, message: "Please enter only numbers" },
                                            minLength: { value: 8, message: "Please enter minimum 8 & maximum 10 digits for mobile number" }, maxLength: { value: 10, message: "Please enter minimum 8 & maximum 10 digits for mobile number" }
                                        })} autoComplete="off" />
                                    </div>
                                </div>
                                <label className="error">{errors.mobileNumber?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("houseNumber", { required: "Please enter house number", pattern: { value: /^[0-9a-zA-Z-.\/\s]*$/, message: "Please enter only letters & numbers" }, maxLength: { value: 30, message: "House number must not exceed 30 characters" } })} autoComplete="off" placeholder="House Number" />
                                </div>
                                <label className="error">{errors.houseNumber?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("area", { required: "Please enter area", pattern: { value: /^[a-zA-Z0-9\s]*$/, message: "Please enter only letters & numbers" } })} autoComplete="off" placeholder="Area" />
                                </div>
                                <label className="error">{errors.area?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("landmark", { required: "Please enter landmark", pattern: { value: /^[a-zA-Z0-9\s]*$/, message: "Please enter only letters & numbers" } })} autoComplete="off" placeholder="Landmark" />
                                </div>
                                <label className="error">{errors.landmark?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("pincode", { required: "Please enter pincode", pattern: { value: /^[0-9]*$/, message: "Please enter only numbers" }, maxLength: { value: 8, message: "Please enter maximum 8 digits for pincode" } })} autoComplete="off" placeholder="Pincode" />
                                </div>
                                <label className="error">{errors.pincode?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group w-100">
                                    <select className="form-control" {...register("addressType", { required: "Please select address type" })} autoComplete="off">
                                        <option value="">Address Type</option>
                                        <option value="Home">Home</option>
                                        <option value="Office">Office</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <label className="error">{errors.addressType?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group w-100">
                                    <select className="form-control" {...register("state", { required: "Please select state" })} onChange={stateChangeHandler} autoComplete="off">
                                        <option value="">State</option>
                                        {states.map((item, index) => {
                                            return (
                                                <option key={index} value={item.name} data-id={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="error">{errors.state?.message}</label>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group w-100">
                                    <select className="form-control" {...register("city", { required: "Please select city" })} autoComplete="off">
                                        <option value="">City</option>
                                        {cities.map((item, index) => {
                                            return (
                                                <option key={index} value={item.city_name} data-id={item.id}>{item.city_name}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="error">{errors.city?.message}</label>
                                </div>
                            </div></>}

                        <div className="col-md-12 mb-2">
                            <button type="submit" className="btn btn-theme mt-auto w-100">Submit</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ClaimDetailModal;
