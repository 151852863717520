import { createSlice } from "@reduxjs/toolkit";

const ModalEditProfileSlice = createSlice({
    name: 'modalEditProfile',
    initialState: {
        isModalOpen: false,
    },
    reducers: {
        openModal: (state) => {
            state.isModalOpen = true;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = ModalEditProfileSlice.actions;
export default ModalEditProfileSlice.reducer;