import { createSlice } from "@reduxjs/toolkit";

const ModalContactSlice = createSlice({
    name: 'modalContact',
    initialState: {
        isModalOpen: false,
    },
    reducers: {
        openModal: (state) => {
            state.isModalOpen = true;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = ModalContactSlice.actions;
export default ModalContactSlice.reducer;