import React from 'react';
import moment from "moment";

const ListingItem = ({ item }) => {

    return (
        <div className="col-md-6 col-sm-12 col-12 mb-3">
            <div className="winning_main notification-new">
                <div className="winner_img">
                    <img src={item.profile_image} alt="" />
                </div>
                <div className="win_detail">
                    <h5>{item.title}</h5>
                    <p>{item.message}</p>
                    <p>{moment.unix(item.insert_datetime).format('h:mm A')}</p>
                </div>
            </div>
        </div>
    );
};

export default ListingItem;