import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getWinningList } from "../../utils/api.service";
import { TOAST_ERROR } from "../../utils/common.service";
import moment from "moment";
import ClaimDetailModal from "../../components/modals/ClaimDetailModal";
import CommentModal from "../../components/modals/CommentModal";
import ListingItem from "./ListingItem";
import Layout from "../../layouts/Layout";

const Winning = () => {

    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    
    const [loadMore, setLoadMore] = useState(true);

    const fetchGetWinningList = () => {

        if (loadMore) {

            let payload = {
                page: page
            };
            getWinningList(payload).then(function (response) {

                if (response.code === '1') {
                    const newData = response.data.result;
                    setData((prevData) => [...prevData, ...newData]);
                } else if (response.code === '2') {
                    setLoadMore(false);
                    TOAST_ERROR(response.message);
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }

            }).catch(function (error) {
                
            });
        }

    }

    useEffect(() => {
        fetchGetWinningList()
    }, [page])

    const handleScroll = () => {
        if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | Winning`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            <section className="winnig_section">
                <div className="container">

                    {(data.length > 0) ? data.map((item, index) => (
                        <div key={index} className="row mt-3">
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <h4 className="title_head">
                                    <span>
                                        {(moment(item.created_at).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) ? 'Today'
                                            : (moment(item.created_at).format('YYYY-MM-DD') == moment().subtract(1, 'days').format('YYYY-MM-DD')) ? 'Yesterday'
                                                : moment(item.created_at).format('DD MMM YYYY')}
                                    </span>
                                </h4>
                            </div>
                            
                            <ListingItem item={item} />
                        </div>
                    )) : <div className="col-12 mt-10 text-center">
                        <strong style={{ fontSize: '20px' }}>No Data Found</strong>
                    </div>}

                </div>
            </section>

            <CommentModal/>
            <ClaimDetailModal />
        </Layout>
    )
}

export default Winning;