import { createSlice } from "@reduxjs/toolkit";

const ThemeSlice = createSlice({
  name: "theme_slice",
  initialState: {
    themeValue: localStorage.getItem("theme"),
    
  },
  reducers: {
    setThemeValue: (state, props) => {
    
      state.themeValue = props.payload;
    },
  },
});

export const { setThemeValue } = ThemeSlice.actions;
export default ThemeSlice.reducer;
