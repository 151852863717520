import { createSlice } from "@reduxjs/toolkit";

const commentAdSlice = createSlice({
    name: 'modalcommentad',
    initialState: {
        isModalOpen: false,
        props: null,
    },
    reducers: {
        openModal: (state, props) => {
            state.isModalOpen = true;
            state.props = props;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = commentAdSlice.actions;
export default commentAdSlice.reducer;